import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationsService, GrantsService } from '../../../shared/services';
import { IApplication } from '../../../shared/types/application.interface';
import { lastValueFrom } from 'rxjs';
import { IGrant } from '../../grants/types/IGrants';
import { notifyWrapper } from '../../../shared/globals/notify-wrapper';
import { APPLICATION_STATUS } from '../../../shared/enums/ApplicationStatus.enum';
import { ApplicationRejectBody } from '../../../shared/types/application-reject.interface';
import { ConfirmationPopupService } from '../../../shared/components/confirmation-popup/confirmation-popup.service';
import { IApplicationCriteria } from '../../../shared/types/IApplicationWithCriteria';
import { debounce } from 'lodash';
import { GrantApplicationScoringService } from '../../../shared/services/eval/scoring.service';

@Component({
  selector: 'app-evaluate',
  templateUrl: './evaluate.component.html',
  styleUrls: ['./evaluate.component.scss'],
})
export class EvaluateComponent implements OnInit {
  applicationId!: number;
  application?: IApplication;
  scoringCritera?: IApplicationCriteria[];
  grant?: IGrant;

  APPLICATION_STATUS = APPLICATION_STATUS;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _applicationService: ApplicationsService,
    private readonly _grantService: GrantsService,
    private readonly _confirmationService: ConfirmationPopupService,
    private readonly _scoreService: GrantApplicationScoringService,
    private readonly _router: Router
  ) {}

  async ngOnInit() {
    this.applicationId = Number(
      this._activatedRoute.snapshot.paramMap.get('applicationId')
    );

    const res = await lastValueFrom(
      this._scoreService.getScoringCriteriaForApplication(this.applicationId)
    );

    this.application = res.application;
    this.grant = res.grant;
    this.scoringCritera = res.critieraCombined;
  }

  setScoredValue = debounce((e) => this._setScoredValue(e), 300);

  private async _setScoredValue(e: {
    scoreCriteriaId: number;
    property: string;
    value: string;
  }) {
    let newObject: any = {};
    newObject[e.property] = e.value;
    try {
      await lastValueFrom(
        this._scoreService.setScoredValue(
          e.scoreCriteriaId,
          this.applicationId,
          newObject
        )
      );
    } catch (error) {
      notifyWrapper('Error updating!', 'error');
    }
  }

  async submitEval() {
    try {
      await lastValueFrom(
        this._scoreService.submitEval(this.grant?.id ?? 0, this.applicationId)
      );
      notifyWrapper('Successfully submitted evaluation!', 'success');
      this._router.navigate(['/admin/evaluating']);
    } catch (e: any) {
      notifyWrapper(`${e}`, 'error');
    }
  }
}
