

<dx-tab-panel
  [(selectedIndex)]="selectedTabIndex"
  [showNavButtons]="true"
  (onSelectionChanged)="tabSelectionChanged()"
  [tabsPosition]="newTabPanel ? 'left' : 'top'">
  <dxi-item *ngFor="let s of this.sections" [title]="s.heading">
    <grant-question-form
      [formData]="application.formData"
      [readOnly]="true"
      [questions]="s.questions">
    </grant-question-form>
  </dxi-item>
  <dxi-item title="Attachments">
    <div
      style="display: flex; align-items: center; flex-direction: column;">
      <div *ngFor="let f of application.files"
        style="display: flex; width: 300px; justify-content: space-between; align-items: center; align-content: center;">
        <div>
          <p
            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 250px;">
            {{f.fileName}} <b>{{f.tagName ? '(' + f.tagName + ')' : ''}}</b>
          </p>
        </div>
        <dx-button icon="download"
          (onClick)="onFileClick(f)"></dx-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item title="Tab Config">
    <div class="dx-field">
      <div class="dx-field-label">New Tab Panel</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="newTabPanel"
          (onValueChanged)="setLocalStorage()"></dx-switch>
      </div>
    </div>
  </dxi-item>
</dx-tab-panel>
