import { Component, Input, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { IApplication } from 'src/app/shared/types/application.interface';
import { Router } from '@angular/router';
import 'devextreme/integration/jquery';
import { GrantGroupService } from '../../../../shared/services/eval/group.service';
import { IGrantGroup } from '../../../../shared/types/IGrantGroup.interface';
import { GrantsService } from '../../../../shared/services';
import { IGrantCheck } from '../../../../shared/types/IGrantCheck';
import { GrantChecksService } from '../../../../shared/services/eval/checks.service';
import { IGrantScoringCriteria } from '../../../../shared/types/IGrantScoringCriteria.interface';
import { GrantApplicationScoringService } from '../../../../shared/services/eval/scoring.service';

@Component({
  selector: 'app-grant-eval-criteria',
  templateUrl: './eval-criteria.component.html',
  styleUrl: './eval-criteria.component.scss',
})
export class GrantEvalCriteriaComponent implements OnInit {
  @Input({ required: true })
  grantId!: number;

  grantGroup?: CustomStore<IGrantScoringCriteria>;
  dataSource!: DataSource<IGrantScoringCriteria>;

  constructor(
    private readonly _scoringService: GrantApplicationScoringService,
    private readonly _router: Router
  ) {}

  async ngOnInit() {
    this.grantGroup = new CustomStore<IGrantScoringCriteria>({
      key: 'id',
      load: async () =>
        await lastValueFrom(this._scoringService.getCriterias(this.grantId)),
      byKey: async (id) =>
        await lastValueFrom(this._scoringService.getCriteria(this.grantId, id)),
      insert: async (value) =>
        await lastValueFrom(
          this._scoringService.createCriteria(this.grantId, value)
        ),
      update: async (id, value) =>
        await lastValueFrom(
          this._scoringService.updateCriteria(this.grantId, id, value)
        ),
      remove: async (id) => {
        await lastValueFrom(
          this._scoringService.deleteCriteria(this.grantId, id)
        );
      },
    });

    this.dataSource = new DataSource<IGrantScoringCriteria, number>({
      store: this.grantGroup,
    });
  }
}
