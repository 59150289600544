<form class="forgot-username-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

    <dxi-item itemType="group" *ngIf="!submitted">
      <dxi-item dataField="email" editorType="dxTextBox"
        [editorOptions]="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }">
        <dxi-validation-rule type="required"
          message="Email is required"></dxi-validation-rule>
        <dxi-validation-rule type="email"
          message="Email is invalid"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>

      <dxi-item itemType="button">
        <dxo-button-options width="100%" type="default"
          [useSubmitBehavior]="true"
          [template]="'resetPasswordTemplate'"
          [elementAttr]="{ class: 'submit-button' }">
        </dxo-button-options>
      </dxi-item>
    </dxi-item>

    <dxi-item *ngIf="submitted">
      <div>
        Success! You should recieve an email shortly with your
        username(s)!
      </div>
    </dxi-item>

    <dxi-item>
      <div class="login-link link">
        Return to
        <a routerLink="/auth/login">Sign In</a>
      </div>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'resetPasswordTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="loading; else notLoading">
            <dx-load-indicator width="24px" height="24px"
              [visible]="true"></dx-load-indicator>
          </ng-container>
          <ng-template #notLoading>Submit</ng-template>
        </span>
      </div>>
    </ng-container>
  </dx-form>
</form>

<ng-template #success></ng-template>
