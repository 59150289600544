import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
} from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { ReimbursementsService } from '../../shared/services/reimbursements.service';
import { Router } from '@angular/router';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { ReimbursementInterface } from '../../shared/services/application/app-reimbursement.service';
import { getREType } from '../../shared/utils/getREType';
@Component({
  selector: 'app-reimbursments',
  standalone: true,
  imports: [DxFormModule, DxDataGridModule, DxButtonModule, DxCheckBoxModule],
  templateUrl: './reimbursments.component.html',
  styleUrl: './reimbursments.component.scss',
})
export class ReimbursmentsComponent implements OnInit, OnDestroy {
  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options) {
        options.excelCell.font = { name: 'Arial', size: 12 };
        options.excelCell.alignment = { horizontal: 'left' };
      },
    }).then(function () {
      const now = new Date();
      workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Reimbursements-${now.toLocaleDateString()}-${now.toLocaleTimeString()}.xlsx`
        );
      });
    });
  }

  showAll = false;
  reimbursementsStore = new CustomStore({
    key: 'id',
    load: async () =>
      await lastValueFrom(
        this.reimbursementService.getAllReimbursements(this.showAll)
      ),
  });

  @ViewChild(DxDataGridComponent)
  dataGrid?: DxDataGridComponent;

  tableRefreshInterval?: NodeJS.Timeout;
  constructor(
    private router: Router,
    private reimbursementService: ReimbursementsService
  ) {}
  ngOnDestroy(): void {
    clearInterval(this.tableRefreshInterval);
  }
  ngOnInit(): void {
    this.tableRefreshInterval = setInterval(() => {
      if (this.dataGrid) {
        this.dataGrid.instance.option('loadPanel.enabled', false);
        this.dataGrid.loadPanel.enabled = false;
        this.dataGrid.instance.refresh(false);
        this.dataGrid.loadPanel.enabled = true;
        this.dataGrid.instance.option('loadPanel.enabled', true);
      }
    }, 20000);
  }

  onViewClick = async (e: any) => {
    if (e.row && e.row.data && e.row.data.id) {
      await this.router.navigate(['admin/reimbursements', e.row.data.id]);
    }
  };

  getREType = getREType;
}
