<form (submit)="onSubmit($event)" *ngIf="!errorMessage">
  <dx-form [formData]="formData" [disabled]="loading">
    <dxi-item dataField="username" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', disabled: true }">
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>
    <dxi-item dataField="password" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }">
      <dxi-validation-rule type="required"
        message="Password is required"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="confirmedPassword" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' }">
      <dxi-validation-rule type="required"
        message="Password is required"></dxi-validation-rule>
      <dxi-validation-rule type="custom" message="Passwords do not match"
        [validationCallback]="confirmPassword">
      </dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true"
        [template]="'changePasswordTemplate'">
      </dxo-button-options>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'changePasswordTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="loading; else notLoading">
            <dx-load-indicator width="24px" height="24px"
              [visible]="true"></dx-load-indicator>
          </ng-container>
          <ng-template #notLoading>Continue</ng-template>
        </span>
      </div>
    </ng-container>

  </dx-form>
</form>

<div *ngIf="errorMessage">
  <h4 style="color: red">{{errorMessage}}</h4>
</div>

<div class="link">
  Return to
  <a routerLink="/auth/login">Sign In</a>
</div>
