import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { DxFormModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { AuthService } from '../../services';
import { lastValueFrom } from 'rxjs';
import { notifyWrapper } from '../../globals/notify-wrapper';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-forgot-username-form',
  styleUrl: 'forgot-username-form.component.scss',
  templateUrl: 'forgot-username-form.component.html',
})
export class ForgotUsernameComponent {
  constructor(private readonly authService: AuthService) {}

  loading: boolean = false;
  formData: { email?: string } = {};
  submitted = false;
  onSubmit = async (e: any) => {
    e.preventDefault();
    this.loading = true;
    if (this.formData.email) {
      try {
        const res = await lastValueFrom(
          this.authService.forgotUsername(this.formData.email)
        );
        this.submitted = true;
      } catch (e: any) {
        notifyWrapper(`${e.error.message}`, 'error');
      } finally {
        this.loading = false;
      }
    }
  };
}

@NgModule({
  imports: [DxFormModule, CommonModule, DxLoadIndicatorModule, RouterModule],
  declarations: [ForgotUsernameComponent],
  exports: [ForgotUsernameComponent],
})
export class ForgotUsernameModule {}
