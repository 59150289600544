import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PlaygroundService } from '../playground.service';
import { dxTabsItem } from 'devextreme/ui/tabs';
import { Subscription } from 'rxjs';
import { ISection } from '../../grants/types/IGrants';

@Component({
  selector: 'application-section-tabs',
  templateUrl: 'section-tabs.component.html',
})
export class ApplicationSectionTabs implements OnInit, OnDestroy {
  formData!: any;
  sections: ISection[] = [];
  selectedIndex: number = 0;
  subscriptions = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private pgService: PlaygroundService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnInit(): void {
    this.subscriptions.add(
      this.pgService.sections.subscribe((s) => {
        this.sections = s;
        this.buildTabs();
      })
    );

    this.subscriptions.add(
      this.pgService.formData.subscribe((fd) => {
        this.formData = fd;
        this.buildTabs();
      })
    );

    this.subscriptions.add(
      this.pgService.selectedSectionId.subscribe((s) => {
        const index = this.tabs.findIndex((t) => t.id == s);
        if (index >= 0) {
          this.selectedIndex = index;
          this.cdr.detectChanges();
        }
      })
    );
  }
  buildTabs = () => {
    this.tabs = this.sections.map((s) => {
      const isComplete = this.pgService.isSectionCompleted(s.id);
      const icon = isComplete ? 'check' : 'errorcircle';
      return {
        id: s.id,
        template: `
        <div class="dx-item-content dx-tab-content">
        <div class="dx-tab-text">
        <i class="dx-icon dx-icon-${icon}" style="color: ${
          isComplete ? 'green' : '#cc4a00'
        }"></i>
        <span class="dx-tab-text-span">
        ${s.heading}
        <span class="dx-tab-text-span-pseudo">
        ${s.heading}
        </span>
        </span>
        </div>
        </div>
        `,
      };
    });

    if (this.tabs.length > 0) {
      this.tabs.push({
        text: 'Review',
        id: 9999,
        icon: 'arrowback',
        disabled: !this.pgService.areAllSectionsComplete(),
      });
    }
  };

  onTabClick(e: any) {
    if (e.name == 'selectedIndex') {
      this.pgService.setSelectedSectionId(this.tabs[e.value].id);
    }
  }

  tabs: any[] = [];
}
