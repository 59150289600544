import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReimbursementsService } from '../../../shared/services/reimbursements.service';
import { lastValueFrom } from 'rxjs';
import { IFile } from '../../../shared/types/IFile';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFileUploaderModule,
  DxFormModule,
} from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { AppReimbursementsService } from '../../../shared/services/application/app-reimbursement.service';
import {
  BeforeSendEvent,
  FilesUploadedEvent,
} from 'devextreme/ui/file_uploader';
import { AuthService } from '../../../shared/services';
import { notifyWrapper } from '../../../shared/globals/notify-wrapper';
import { FileTagEnum } from '../../../shared/enums/FileTag.enum';

@Component({
  selector: 'app-reimbursement-files',
  standalone: true,
  imports: [
    CommonModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxFormModule,
  ],
  templateUrl: './invoice-files.component.html',
  styleUrl: './invoice-files.component.scss',
})
export class ReimbursementFilesComponent implements OnInit {
  @ViewChild(DxDataGridComponent)
  dataGrid?: DxDataGridComponent;

  fileTags: { name: string; description?: string }[] = [];

  formData: any = {};
  onFilesUploaded($event: FilesUploadedEvent) {
    this.dataGrid?.instance.refresh();
  }
  async onBeforeSend($event: BeforeSendEvent) {
    $event.request.setRequestHeader(
      'Authorization',
      'Bearer ' + this.authService.getAccessToken()
    );
  }
  @Input({ required: true })
  reimbursementId!: number;

  @Input()
  invoiceId?: number;

  @Input()
  applicationId?: number;

  @Input()
  awardId?: number;

  loading = false;

  store?: CustomStore<IFile>;

  uploadURL = '';

  constructor(
    private appReimbursementService: AppReimbursementsService,
    private reimbursementService: ReimbursementsService,
    private authService: AuthService
  ) {}
  async ngOnInit() {
    if (this.applicationId && this.awardId) {
      const appId = this.applicationId;
      const awardId = this.awardId;
      this.fileTags = await lastValueFrom(
        this.appReimbursementService.getFileTags(
          this.applicationId,
          this.awardId
        )
      );

      if (this.invoiceId) {
        this.uploadURL = this.appReimbursementService.getInvoiceFileUploadURL(
          appId,
          awardId,
          this.invoiceId
        );

        const invoiceId = this.invoiceId;
        this.store = new CustomStore({
          key: 'hashedName',
          load: async () =>
            await lastValueFrom(
              this.appReimbursementService.getInvoiceFiles(
                appId,
                awardId,
                invoiceId
              )
            ),
          remove: async (key) => {
            await lastValueFrom(
              this.appReimbursementService.deleteFile(
                appId,
                awardId,
                this.reimbursementId,
                key
              )
            );
          },
          update: async (key, values) => {
            if (values.tagName) {
              return await lastValueFrom(
                this.appReimbursementService.updateFileTag(
                  appId,
                  awardId,
                  this.reimbursementId,
                  key,
                  values.tagName
                )
              );
            }
            return;
          },
        });
      } else {
        this.uploadURL =
          this.appReimbursementService.getReimbursementFileUploadURL(
            appId,
            awardId,
            this.reimbursementId
          );

        this.store = new CustomStore({
          key: 'hashedName',
          load: async () =>
            await lastValueFrom(
              this.appReimbursementService.getReimbursementFiles(
                appId,
                awardId,
                this.reimbursementId
              )
            ),
          remove: async (key) => {
            await lastValueFrom(
              this.appReimbursementService.deleteFile(
                appId,
                awardId,
                this.reimbursementId,
                key
              )
            );
          },
        });
      }
    } else {
      this.store = new CustomStore({
        key: 'hashedName',
        load: async () => {
          if (this.invoiceId) {
            return await lastValueFrom(
              this.reimbursementService.getInvoiceFiles(this.invoiceId)
            );
          } else {
            return await lastValueFrom(
              this.reimbursementService.getReimbursementFiles(
                this.reimbursementId
              )
            );
          }
        },
      });
    }
  }

  @Input()
  canEdit = false;

  onClickDownload(obj: any) {
    if (obj.row && obj.row.data && obj.row.data.presignedURL) {
      window.open(obj.row.data.presignedURL, '_blank')?.focus();
    }
  }

  async onFormSubmit(e: SubmitEvent) {
    e.preventDefault();

    if (!this.applicationId || !this.awardId) return;

    console.log(this.formData);

    try {
      if (this.invoiceId) {
        const res = await lastValueFrom(
          this.appReimbursementService.uploadInvoiceFiles(
            this.applicationId,
            this.awardId,
            this.invoiceId,
            this.formData
          )
        );
      } else {
        const res = await lastValueFrom(
          this.appReimbursementService.uploadReimbursementFiles(
            this.applicationId,
            this.awardId,
            this.reimbursementId,
            this.formData
          )
        );
      }
      this.formData = {};
      this.dataGrid?.instance.refresh();
    } catch (error: any) {
      console.log(error);
      let message = 'An unknown error occurred!';
      if (error.error && error.error.message) {
        message = error.error.message;
      }
      notifyWrapper(message, 'error');
    }
  }
}
