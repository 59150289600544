<h5 style='margin: 0px;'>Required:</h5>
<div *ngFor="let check of requiredChecks">
  <dx-check-box
    (onValueChanged)="onCheckChanged($event, check)"
    [text]="check.name"
    [value]="check.isChecked"></dx-check-box>
</div>
<span *ngIf="optionalChecks.length > 0">
  <h5 style='margin: 0px;'>Optional:</h5>
  <div *ngFor="let check of optionalChecks">
    <dx-check-box
      (onValueChanged)="onCheckChanged($event, check)"
      [text]="check.name"
      [value]="check.isChecked"></dx-check-box>
  </div>
</span>
