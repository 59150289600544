<dx-data-grid
  id="reimbursement-comments"
  [wordWrapEnabled]="true"
  [rowAlternationEnabled]="true"
  (onInitNewRow)="onInitRow($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onCellPrepared)="onCellPrepared($event)"
  [dataSource]="store"
  [columnAutoWidth]="true">
  <dxo-toolbar>
    <dxi-item location="before">
      <div class="data-grid-header">Comments</div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item name="revertButton"></dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" (onClick)="dataGrid?.instance?.refresh()">
        </dx-button>
      </div>
    </dxi-item>
  </dxo-toolbar>
  <dxo-editing
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true"></dxo-editing>
  <dxi-column
    [allowEditing]="false"
    alignment="center"
    width="200px"
    dataField="username"></dxi-column>
  <dxi-column
    alignment="left"
    caption="Comment"
    width="100%"
    [minWidth]="200"
    dataField="text"></dxi-column>
  <dxi-column
    *ngIf="!this.applicationId"
    alignment="center"
    caption="Internal"
    width="120px"
    dataType="boolean"
    dataField="internalOnly"></dxi-column>
  <dxi-column
    [visible]="false"
    [allowEditing]="false"
    alignment="center"
    caption="Created"
    dataField="created"
    dataType="datetime"
    format="MMM dd, yyyy h:mm a"></dxi-column>
  <dxi-column
    [allowEditing]="false"
    alignment="center"
    caption="Updated"
    dataField="updated"
    dataType="datetime"
    format="MMM dd, yyyy h:mm a"></dxi-column>

  <dxi-column
    dataField="buttons"
    [caption]="''"
    width="100px"
    type="buttons">
    <dxi-button
      name="save">
    </dxi-button>
    <dxi-button name="edit"></dxi-button>
    <dxi-button name="delete"></dxi-button>
    <dxi-button name="cancel"></dxi-button>
    <dxi-button name="revert"></dxi-button>
  </dxi-column>
</dx-data-grid>
