import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import { ApplicationReadHistoryComponent } from './application-read-history.component';

@NgModule({
  imports: [CommonModule, DxDataGridModule],
  exports: [ApplicationReadHistoryComponent],
  declarations: [ApplicationReadHistoryComponent],
})
export class ApplicationReadHistoryModule {}
