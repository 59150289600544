<dx-popup
  height="100%"
  width="100%"
  style="z-index: 1000"
  (onHidden)="onHiding()"
  [visible]="visible"
  [showTitle]="true"
  *ngIf="award.advancedPaymentPercentage && balance"
  [title]="'Accept Terms of Advance Payment'"
>
  <div *dxTemplate="let data of 'content'" id="agreementText">
    <p>
      <b>Grant Award:</b> {{ award.amount | currency : "USD" }}<br />
      <b>Advance Payment:</b> {{ award.advancedPaymentPercentage * 100 }}% of
      the grant award, not to exceed
      {{ award.amount * award.advancedPaymentPercentage | currency : "USD" }}
      <br />
      <b>Remaining to request Advanced Pay:</b>
      {{ balance.availableToAdvance | currency : "USD" }}
    </p>
    <p>
      By typing "I Agree" below, the grant recipient acknowledges and agrees to
      the following terms regarding the advance payment of the Louisiana Center
      for Safe Schools Safety Grant:
    </p>

    <p>
      <b>1. Advance Payment Terms:</b><br />
      - The Recipient agrees to receive an advance payment of up to
      {{ award.advancedPaymentPercentage * 100 }}% of the total grant award, not
      to exceed
      {{ award.amount * award.advancedPaymentPercentage | currency : "USD" }}
      ({{ award.amount | currency : "USD" }} times
      {{ award.advancedPaymentPercentage * 100 }}%), from GOHSEP.
    </p>

    <p>
      <b>2. Acknowledgment of Responsibility:</b><br />
      - The Recipient agrees to fully comply with all documentation and
      reporting requirements outlined in the previously signed Cooperative
      Endeavor Agreement and Award Letter associated with this grant. <br />
      - The Recipient affirms that all expenditures of the advanced funds will
      align with the terms and conditions of the grant. <br />
      - Documentation supporting the use of funds, including invoices, receipts,
      and other records, must be maintained and provided as required for
      compliance reviews or audits.
    </p>

    <p>
      <b>3. Compliance with Documentation Requirements:</b><br />
      - The Recipient must submit the required documentation to GOHSEP for
      review and approval. Payments for any reimbursement or disbursement beyond
      {{ award.advancedPaymentPercentage * 100 }}% of the total grant award will
      not be made until such documentation is submitted, reviewed, and approved
      by GOHSEP. <br />
      - The Recipient acknowledges that failure to provide the required
      documentation may result in the suspension of future payments of the
      remaining {{ 100 - award.advancedPaymentPercentage * 100 }}% of the total
      grant award, the requirement to return disbursed funds, or other remedies
      as outlined in the Cooperative Endeavor Agreement.
    </p>

    <p>
      <b>4. Final Payment and Reimbursement:</b><br />
      - Upon the submission of satisfactory documentation, GOHSEP will reimburse
      the Recipient for any remaining balance up to the total grant award
      amount, less any amounts already advanced, subject to the terms outlined
      in this Certification Statement.
    </p>

    <p>
      <b>5. Certification:</b><br />
      - By agreeing to this statement, the recipient certifies that they have
      read, understood, and accepted these terms and will ensure adherence to
      all grant requirements.
    </p>

    <dx-text-box
      valueChangeEvent="input"
      (onInput)="onInput($event)"
      [(value)]="textBoxValue"
      placeholder="Type `I Agree` to continue!"
    ></dx-text-box>
    <dx-number-box
      label="Amount Requested"
      valueChangeEvent="input"
      [max]="balance.availableToAdvance"
      [min]="0"
      [(value)]="amount"
      [format]="{
        type: 'currency',
        precision: 2,
        currency: 'USD'
      }"
      placeholder="Amount"
    >
    </dx-number-box>
    <br />
    <dx-button
      [text]="'Submit'"
      type="success"
      (onClick)="_onAgree()"
      [disabled]="!canAgree"
      style="width: 100%"
    ></dx-button>
    <p
      *ngIf="balance.availableToAdvance == 0"
      style="color: red; padding: 0px; margin: 0px"
    >
      Exhaused Advance Payment Funds
    </p>
    <div style="padding-top: 10px">
      <dx-button
        (onClick)="onHiding()"
        [text]="'Cancel'"
        type="default"
        style="width: 100%"
      ></dx-button>
    </div>
  </div>
</dx-popup>
