import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DxFormModule,
  DxListModule,
  DxLoadIndicatorModule,
} from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { SchoolsService } from '../../../services/schools.service';
import DataSource from 'devextreme/data/data_source';
import { ACCOUNT_TYPE } from '../../../globals/enums/AccountType.enum';

@Component({
  selector: 'app-school-search-list',
  templateUrl: 'school-search-list.component.html',
  styleUrls: ['./school-search-list.component.scss'],
})
export class SchoolSearchListComponent implements AfterContentInit {
  store?: CustomStore;
  dataSource?: DataSource;

  @Input()
  type!: ACCOUNT_TYPE;

  ACCOUNT_TYPE = ACCOUNT_TYPE;

  constructor(
    private schoolsService: SchoolsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    if (!this.type) throw new Error('Type not passed!');

    if (this.type == ACCOUNT_TYPE.SCHOOL) {
      this.store = this.schoolsService.schoolStore;
    } else if (this.type == ACCOUNT_TYPE.DISTRICT) {
      this.store = this.schoolsService.districtStore;
    }
    this.dataSource = new DataSource({
      store: this.store,
      pageSize: 100,
    });
  }

  @Input()
  selection: any;

  @Output()
  selectionChanged: EventEmitter<any> = new EventEmitter();

  optionChange = (e: any) => {
    if (e.name == 'selectedItem') {
      this.selection = e.value;
      this.selectionChanged.emit(e.value);
    }
  };
}

@NgModule({
  imports: [CommonModule, DxLoadIndicatorModule, DxListModule],
  declarations: [SchoolSearchListComponent],
  exports: [SchoolSearchListComponent],
})
export class SchoolSearchListModule {}
