import { CommonModule } from '@angular/common';
import { Input, NgModule, OnInit } from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import { ApplicationStatusHistoryComponent } from './application-status-history.component';

@NgModule({
  imports: [CommonModule, DxDataGridModule],
  exports: [ApplicationStatusHistoryComponent],
  declarations: [ApplicationStatusHistoryComponent],
})
export class ApplicationStatusHistoryModule {}
