import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApplicationsService } from './application.service';
import { AppAwardsService } from './awards.service';
import { catchError, Subject } from 'rxjs';
import { handleHttpError } from '../../http.error-handler';
import { ReimbursementCommentInterface } from '../reimbursements.service';
import { IFile } from '../../types/IFile';

@Injectable({
  providedIn: 'root',
})
export class AppReimbursementsService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/reimbursements';

  // Subject to emit refresh events
  private refreshSubject = new Subject<void>();

  // Observable that components can subscribe to
  refresh$ = this.refreshSubject.asObservable();

  triggerRefresh() {
    this.refreshSubject.next();
  }

  constructor(
    private _httpClient: HttpClient,
    private awardService: AppAwardsService
  ) {}

  getBaseURL(applicationId: number = 0, awardId: number = 0) {
    return `${this.awardService.getBaseURL(applicationId)}/${awardId}${
      this.path
    }`;
  }

  getAllReimbursements(applicationId: number, awardId: number) {
    return this._httpClient
      .get<ReimbursementInterface[]>(
        `${this.getBaseURL(applicationId, awardId)}`
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface[]>));
  }

  getReimbursement(
    applicationId: number,
    awardId: number,
    reimbursementId: number
  ) {
    return this._httpClient
      .get<ReimbursementInterface>(
        `${this.getBaseURL(applicationId, awardId)}/${reimbursementId}`
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  updateReimbursement(
    applicationId: number,
    awardId: number,
    id: number,
    data: Partial<ReimbursementInterface>
  ) {
    return this._httpClient
      .patch<ReimbursementInterface>(
        `${this.getBaseURL(applicationId, awardId)}/${id}`,
        data
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  createReimbursement(
    applicationId: number,
    awardId: number,
    data: ReimbursementInterface
  ) {
    return this._httpClient
      .post<ReimbursementInterface>(
        `${this.getBaseURL(applicationId, awardId)}`,
        data
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  submitAdvReimbursement(applicationId: number, awardId: number, data: any) {
    return this._httpClient
      .post<any>(
        `${this.getBaseURL(applicationId, awardId)}/advancedPayment`,
        data
      )
      .pipe(catchError(handleHttpError<any>));
  }

  getAllInvocies(
    applicationId: number,
    awardId: number,
    reimbursementId: number
  ) {
    return this._httpClient
      .get<ReimbursementInvoice[]>(
        `${this.getBaseURL(applicationId, awardId)}/${reimbursementId}/invoices`
      )
      .pipe(catchError(handleHttpError<ReimbursementInvoice[]>));
  }

  createInvoice(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    data: ReimbursementInvoice
  ) {
    return this._httpClient
      .post<ReimbursementInvoice>(
        `${this.getBaseURL(
          applicationId,
          awardId
        )}/${reimbursementId}/invoices`,
        data
      )
      .pipe(catchError(handleHttpError<ReimbursementInvoice>));
  }

  removeInvoice(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    invoiceId: number
  ) {
    return this._httpClient
      .delete<ReimbursementInvoice>(
        `${this.getBaseURL(
          applicationId,
          awardId
        )}/${reimbursementId}/invoices/${invoiceId}`
      )
      .pipe(catchError(handleHttpError<ReimbursementInvoice>));
  }

  updateInvoice(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    invoiceId: number,
    data: Partial<ReimbursementInvoice>
  ) {
    return this._httpClient
      .patch<ReimbursementInvoice>(
        `${this.getBaseURL(
          applicationId,
          awardId
        )}/${reimbursementId}/invoices/${invoiceId}`,
        data
      )
      .pipe(catchError(handleHttpError<ReimbursementInvoice>));
  }

  getBalance(applicationId: number, awardId: number) {
    return this._httpClient.get<AwardBalance>(
      `${this.getBaseURL(applicationId, awardId)}/balance`
    );
  }

  submitReimbursement(
    applicationId: number,
    awardId: number,
    reimbursementId: number
  ) {
    return this._httpClient.post<ReimbursementInterface>(
      `${this.getBaseURL(applicationId, awardId)}/${reimbursementId}/submit`,
      {}
    );
  }

  getComments(applicationId: number, awardId: number, reimbursementId: number) {
    return this._httpClient
      .get<ReimbursementCommentInterface>(
        `${this.getBaseURL(applicationId, awardId)}/${reimbursementId}/comments`
      )
      .pipe(catchError(handleHttpError<ReimbursementCommentInterface>));
  }

  createComment(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    comment: Omit<ReimbursementCommentInterface, 'internalOnly'>
  ) {
    return this._httpClient
      .post<ReimbursementCommentInterface>(
        `${this.getBaseURL(
          applicationId,
          awardId
        )}/${reimbursementId}/comments`,
        comment
      )
      .pipe(catchError(handleHttpError<ReimbursementCommentInterface>));
  }

  editComment(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    commentId: number,
    comment: Omit<ReimbursementCommentInterface, 'internalOnly'>
  ) {
    return this._httpClient
      .patch<ReimbursementCommentInterface>(
        `${this.getBaseURL(
          applicationId,
          awardId
        )}/${reimbursementId}/comments/${commentId}`,
        comment
      )
      .pipe(catchError(handleHttpError<ReimbursementCommentInterface>));
  }

  deleteComment(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    commentId: number
  ) {
    return this._httpClient
      .delete<ReimbursementCommentInterface>(
        `${this.getBaseURL(
          applicationId,
          awardId
        )}/${reimbursementId}/comments/${commentId}`
      )
      .pipe(catchError(handleHttpError<ReimbursementCommentInterface>));
  }

  getReimbursementFiles(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    allAttachments = false
  ) {
    return this._httpClient.get<IFile[]>(
      `${this.getBaseURL(
        applicationId,
        awardId
      )}/files?reimbursementId=${reimbursementId}&allAttachments=${allAttachments}`
    );
  }

  getInvoiceFiles(applicationId: number, awardId: number, invoiceId: number) {
    return this._httpClient.get<IFile[]>(
      `${this.getBaseURL(applicationId, awardId)}/files?invoiceId=${invoiceId}`
    );
  }

  deleteFile(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    hashedName: string
  ) {
    return this._httpClient.delete(
      `${this.getBaseURL(
        applicationId,
        awardId
      )}/${reimbursementId}/files/${hashedName}`
    );
  }

  updateFileTag(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    hashedName: string,
    tag: string
  ) {
    return this._httpClient.post(
      `${this.getBaseURL(
        applicationId,
        awardId
      )}/${reimbursementId}/files/${hashedName}?tag=${tag}`,
      {}
    );
  }

  getReimbursementFileUploadURL(
    applicationId: number,
    awardId: number,
    reimbursementId: number
  ) {
    return `${this.getBaseURL(
      applicationId,
      awardId
    )}/files?reimbursementId=${reimbursementId}`;
  }

  getInvoiceFileUploadURL(
    applicationId: number,
    awardId: number,
    invoiceId: number
  ) {
    return `${this.getBaseURL(
      applicationId,
      awardId
    )}/files?invoiceId=${invoiceId}`;
  }

  uploadReimbursementFiles(
    applicationId: number,
    awardId: number,
    reimbursementId: number,
    data: { tag: string; file: File[] }
  ) {
    const formData: FormData = new FormData();
    for (let i = 0; i < data.file.length; i++) {
      formData.append(i.toString(), data.file[i], data.file[i].name);
    }
    formData.append('data', JSON.stringify(data));
    return this._httpClient.post<any>(
      `${this.getBaseURL(
        applicationId,
        awardId
      )}/files?reimbursementId=${reimbursementId}&tag=${data.tag}`,
      formData
    );
  }

  uploadInvoiceFiles(
    applicationId: number,
    awardId: number,
    invoiceId: number,
    data: { tag: string; file: File[] }
  ) {
    const formData: FormData = new FormData();
    for (let i = 0; i < data.file.length; i++) {
      formData.append(i.toString(), data.file[i], data.file[i].name);
    }
    formData.append('data', JSON.stringify(data));
    return this._httpClient.post<any>(
      `${this.getBaseURL(
        applicationId,
        awardId
      )}/files?invoiceId=${invoiceId}&tag=${data.tag}`,
      formData
    );
  }

  getFileTags(applicationId: number, awardId: number) {
    return this._httpClient.get<{ name: string; description: string }[]>(
      `${this.getBaseURL(applicationId, awardId)}/files/tags`
    );
  }

  getBudgetItems(
    applicationId: number,
    awardId: number,
    reimbursementId?: number
  ) {
    let url = `${this.getBaseURL(applicationId, awardId)}/budgetItems`;
    if (reimbursementId) url = `${url}?reimbursementId=${reimbursementId}`;
    return this._httpClient.get<AppBudget[]>(url);
  }
}

export interface ReimbursementInterface {
  status: number;
  statusString?: string;
  id: number;
  reason?: string;
  awardId: number;
  agencyName: string;
  vendorNumber: string;
  applicantFirstName: string;
  applicantLastName: string;
  advancedPayment: boolean;
  awardNumber?: string;
  checkNumber?: string;
  date: Date;
  vendorInvoices?: ReimbursementInvoice[];
  purchaseOrderNumber?: string;
}

export interface ReimbursementInvoice {
  id: number;
  reimbursmentId: number;
  vendorName: string;
  invoiceNumber: string;
  solutionArea: string;
  description: string;
  amountRequested: number;
  amountApproved: number;
}

export interface AwardBalance {
  pending: number;
  submitted: number;
  approved: number;
  available: number;
  awardAmount: number;
  availableToAdvance: number;
  advancePaid: number;
}

export interface AppBudget {
  id: number;
  applicationId: number;
  category: string;
  description: string;
  cost: number;
  approved: number;
  available: number;
  pendingApproval: number;
  pendingSubmission: number;
}
