import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-announcement-image',
  templateUrl: './announcement-image.component.html',
  styleUrl: './announcement-image.component.scss',
})
export class AnnouncementImageComponent {
  imageUrl: string = 'assets/grant-announcement-microphone.png';

  @Input() imageWidth!: number;
  @Input() imageHeight!: number;

  constructor() {}
}
