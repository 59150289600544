import { Component, Input } from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import {
  AppAwardInterface,
  AppAwardsService,
} from '../../services/application/awards.service';
import {
  ReimbursementInterface,
  AppReimbursementsService,
} from '../../services/application/app-reimbursement.service';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { ReimbursementsComponent } from './reimbursements/reimbursements.component';
import { IApplication } from '../../types/application.interface';

@Component({
  selector: 'app-application-awards',
  standalone: true,
  imports: [DxDataGridModule, ReimbursementsComponent],
  templateUrl: './application-awards.component.html',
  styleUrl: './application-awards.component.scss',
})
export class ApplicationAwardsComponent {
  @Input({ required: true }) application!: IApplication;

  awardStore = new CustomStore<AppAwardInterface>({
    load: async () =>
      await lastValueFrom(this.awardService.getAwards(this.application.id)),
  });

  constructor(private awardService: AppAwardsService) {}

  calculateSignedStatus(row: any) {
    let signed = true;
    for (const r of row.awardDocuments) {
      if (r.signedStatus != 'COMPLETED') {
        signed = false;
      }
    }
    return signed;
  }

  viewReimbursements(row: any) {
    console.log(row);
  }
}
