import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { GrantsService } from '../grants.service';
import { handleHttpError } from '../../http.error-handler';
import { environment } from '../../../../environments/environment';
import { IGrantScoringCriteria } from '../../types/IGrantScoringCriteria.interface';
import { IApplicationScored } from '../../types/IApplicationScored.interface';
import { IApplicationCriteria } from '../../types/IApplicationWithCriteria';
import { IApplication } from '../../types/application.interface';
import { IGrant } from '../../../pages/grants/types/IGrants';

@Injectable({
  providedIn: 'root',
})
export class GrantApplicationScoringService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/eval/scoring';

  getBaseURL(grantId: number = 0) {
    return this._grantService.getBaseURL() + `/${grantId}${this.path}`;
  }

  constructor(
    private _httpClient: HttpClient,
    private readonly _grantService: GrantsService
  ) {}

  getScoringCriteriaForApplication(applicationId: number) {
    return this._httpClient
      .get<ScoringCriteriaResponse>(`${this.getBaseURL(0)}/${applicationId}`)
      .pipe(catchError(handleHttpError<ScoringCriteriaResponse>));
  }

  setScoredValue(
    criteriaId: number,
    appId: number,
    scoreValue: Partial<IApplicationScored>
  ) {
    return this._httpClient
      .patch<IApplicationScored[]>(
        `${this.getBaseURL()}/${appId}/score/${criteriaId}`,
        scoreValue
      )
      .pipe(catchError(handleHttpError<IApplicationScored[]>));
  }

  getCriterias(grantId: number) {
    return this._httpClient
      .get<IGrantScoringCriteria[]>(`${this.getBaseURL(grantId)}/criteria`)
      .pipe(catchError(handleHttpError<IGrantScoringCriteria[]>));
  }

  getCriteria(grantId: number, criteriaId: number) {
    return this._httpClient
      .get<IGrantScoringCriteria>(
        `${this.getBaseURL(grantId)}/criteria/${criteriaId}`
      )
      .pipe(catchError(handleHttpError<IGrantScoringCriteria>));
  }

  createCriteria(grantId: number, obj: IGrantScoringCriteria) {
    return this._httpClient
      .post<IGrantScoringCriteria>(`${this.getBaseURL(grantId)}/criteria`, {
        ...obj,
        grantId,
      })
      .pipe(catchError(handleHttpError<IGrantScoringCriteria>));
  }

  updateCriteria(
    grantId: number,
    criteriaId: number,
    obj: Partial<IGrantScoringCriteria>
  ) {
    return this._httpClient
      .patch<IGrantScoringCriteria>(
        `${this.getBaseURL(grantId)}/criteria/${criteriaId}`,
        obj
      )
      .pipe(catchError(handleHttpError<IGrantScoringCriteria>));
  }

  deleteCriteria(grantId: number, checkId: number) {
    return this._httpClient
      .delete<IGrantScoringCriteria>(
        `${this.getBaseURL(grantId)}/criteria/${checkId}`
      )
      .pipe(catchError(handleHttpError<IGrantScoringCriteria>));
  }

  submitEval(grantId: number, applicationId: number) {
    return this._httpClient
      .post(`${this.getBaseURL(grantId)}/${applicationId}/submit`, {})
      .pipe(catchError(handleHttpError));
  }
}
type ScoringCriteriaResponse = {
  critieraCombined: IApplicationCriteria[];
  application: IApplication;
  grant: IGrant;
};
