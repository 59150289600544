<div *ngIf="canEdit">
  <p><b>Upload related files below:</b></p>
  <span *ngIf="invoiceId">
    <p>File types required for all invoices:</p>
    <ul>
      <li>Reciept/Invoice</li>
      <li>Proof of Payment</li>
    </ul>
  </span>
  <form (submit)="onFormSubmit($event)">
    <dx-form [colCount]="2" [(formData)]="formData">
      <dxi-item
        dataField="file"
        [editorOptions]="{
        uploadMode: 'useForm',
        selectButtonText: 'Select file',
        }"
        editorType="dxFileUploader"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="tag"
        editorType="dxSelectBox"
        [label]="{ text: 'File Type' }"
        [editorOptions]="{
          dataSource: fileTags,
          displayExpr: 'name',
          valueExpr: 'name'
        }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        [colSpan]="2"
        itemType="button"
        [buttonOptions]="{
          text: 'Upload',
          useSubmitBehavior: true,
          type: 'default'
        }"
      ></dxi-item>
    </dx-form>
  </form>
</div>
<dx-data-grid
  [rowAlternationEnabled]="true"
  [paging]="{ enabled: false }"
  [dataSource]="store ?? []"
>
  <dxo-toolbar>
    <dxi-item location="before">
      <div class="data-grid-header">Files</div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" (onClick)="dataGrid?.instance?.refresh()">
        </dx-button>
      </div>
    </dxi-item>
  </dxo-toolbar>
  <dxo-filter-row [visible]="!canEdit"></dxo-filter-row>
  <dxo-editing
    [allowUpdating]="canEdit"
    [allowDeleting]="canEdit"
    [mode]="'row'"
    [texts]="{
      confirmDeleteMessage: 'Are you sure you want to delete this file?'
    }"
  >
  </dxo-editing>
  <dxi-column
    [allowEditing]="false"
    alignment="center"
    dataField="fileName"
  ></dxi-column>
  <dxi-column
    [allowEditing]="false"
    alignment="center"
    type="datetime"
    format="MMM dd, yyyy h:mm a"
    dataField="created"
    dataType="datetime"
  ></dxi-column>
  <dxi-column [allowEditing]="true" alignment="center" dataField="tagName">
    <dxo-lookup
      [dataSource]="fileTags"
      *ngIf="canEdit"
      displayExpr="name"
      valueExpr="name"
    ></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="buttons" [caption]="''" width="100px" type="buttons">
    <dxi-button name="delete"></dxi-button>
    <dxi-button name="edit"></dxi-button>
    <dxi-button icon="download" [onClick]="onClickDownload"></dxi-button>
  </dxi-column>
</dx-data-grid>
