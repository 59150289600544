import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { notifyWrapper } from 'src/app/shared/globals/notify-wrapper';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(private _router: Router) {}

  async onApplyClick() {
    try {
      this._router.navigateByUrl(`grants`);
    } catch (e: any) {
      notifyWrapper(e.error.message ?? 'Error applying for grant!', 'error');
    }
  }
}
