import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DxPopupModule, DxTextBoxModule } from 'devextreme-angular';
import { IssueCheckPopupService } from './issue-check-popup.service';
import { Subscription } from 'rxjs';
import { notifyWrapper } from '../../../shared/globals/notify-wrapper';

@Component({
  standalone: true,
  imports: [DxPopupModule, CommonModule, DxTextBoxModule],
  selector: 'app-issue-check-popup',
  templateUrl: 'issue-check-popup.component.html',
})
export class IssueCheckPopupComponent implements OnInit, OnDestroy {
  title: string = 'Confirm';
  message: string = '';
  cancelBtnType: string = 'default';
  confirmBtnType: string = 'default';

  reason: string = '';

  show: boolean = false;

  constructor(private popupService: IssueCheckPopupService) {}

  subsciptions = new Subscription();
  ngOnInit(): void {
    this.subsciptions.add(
      this.popupService.popupState.subscribe((state) => {
        if (state.message) this.message = state.message;
        if (state.title) this.title = state.title;
        if (state.cancelType) this.cancelBtnType = state.cancelType;
        if (state.confirmType) this.confirmBtnType = state.confirmType;
        this.show = state.isVisible;
      })
    );
  }

  ngOnDestroy(): void {
    this.subsciptions.unsubscribe();
  }

  confirmAction = () => {
    if (!this.reason || this.reason == '') {
      notifyWrapper('Provide a check number to continue', 'error');
      return;
    }

    this.popupService.closePopup(true, this.reason);
  };
  denyAction = () => {
    this.popupService.closePopup(false);
  };

  onPopupClose() {
    this.popupService.closePopup(false);
  }
}
