import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lcss-logo',
  templateUrl: './lcss-logo.component.html',
  styleUrl: './lcss-logo.component.scss',
})
export class LcssLogoComponent {
  imageUrl: string = 'assets/lcss-logo.png';

  @Input() imageWidth!: number;
  @Input() imageHeight!: number;

  constructor() {}
}
