import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  BehaviorSubject,
  firstValueFrom,
  lastValueFrom,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import { Router } from '@angular/router';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { IUserRolePermission } from '../types/user-role-permission.interface';

import { AbilityService } from '@casl/angular';
import { AppAbility, buildAbility } from './app-user-abilities.service';

export interface ILoginResponse {
  access_token: string;
  refresh_token: string;
}

export interface IJWTToken extends JwtPayload {
  username?: string;
  email?: string;
  subject?: string;
  context?: { roleName?: string; permissions?: IUserRolePermission[] };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private injector: Injector
  ) {}
  isLoggedIn = new BehaviorSubject<boolean>(false);
  accessTokenStr: string = 'access_token';
  refreshTokenStr: string = 'refresh_token';
  baseUrl = environment.baseApiUrl + '/auth';

  accessTokenChanged: Subject<void> = new Subject();

  forgotUsername(email: string) {
    return this.httpClient.get(
      `${this.baseUrl}/forgotUsername?email=${email}`,
      { headers: { AUTH_ROUTE: '0' } }
    );
  }

  forgotPassword(username: string) {
    return this.httpClient.get(
      `${this.baseUrl}/forgotPassword?username=${username}`,
      { headers: { AUTH_ROUTE: '0' } }
    );
  }

  resetPassword(body: { token: string; password: string }) {
    return this.httpClient.post(`${this.baseUrl}/resetPassword`, body, {
      headers: { AUTH_ROUTE: '0' },
    });
  }

  isTokenBlacklisted(token: string) {
    return this.httpClient.get(`${this.baseUrl}/isBlacklisted/${token}`, {
      headers: { AUTH_ROUTE: '0' },
    });
  }

  /**
   * Calls the api to get an access and refresh token
   * @param username username of user
   * @param password PW of user
   * @returns
   */
  login(username: string, password: string, rememberMe: boolean) {
    const url = `${this.baseUrl}/login`;
    return (
      this.httpClient
        .post<ILoginResponse>(url, {
          username,
          password,
        })
        // Set the tokens from the response
        .pipe(
          tap(async (e) => {
            await this.setTokens(e, rememberMe);
            await this.updatePermissions();
          })
        )
    );
  }

  /**
   * Asks the server for a new access token with the given refresh token.
   * @param refreshToken
   * @returns
   */
  private requestNewAccessToken(
    refreshToken: string
  ): Observable<{ access_token: string }> {
    const url = `${this.baseUrl}/refresh`;
    return this.httpClient.post<{ access_token: string }>(
      url,
      {},
      {
        headers: { Authorization: `Bearer ${refreshToken}` },
      }
    );
  }

  /**
   * Refreshes an access token if currently logged in.
   */
  async refreshAccessToken() {
    const status = await this.loggedInStatus();
    if (!status.isLoggedIn) return;
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      const { access_token } = await lastValueFrom(
        this.requestNewAccessToken(refreshToken)
      );
      if (localStorage.getItem(this.accessTokenStr)) {
        localStorage.setItem(this.accessTokenStr, access_token);
      }
      if (sessionStorage.getItem(this.accessTokenStr)) {
        sessionStorage.setItem(this.accessTokenStr, access_token);
      }

      this.accessTokenChanged.next();
      await this.updatePermissions();
    }
  }

  /**
   *
   * @returns Returns a JWTToken object
   */
  getDecodedToken(): IJWTToken | undefined {
    const token = this.getAccessToken();
    if (token) {
      try {
        const decodedToken: IJWTToken = jwtDecode(token);
        return decodedToken;
      } catch (error) {
        console.error('Error decoding JWT token!' + error);
      }
    }
    return undefined;
  }

  /**
   * @returns The current logged in users email or undefined if
   */
  getUserEmail(): string | undefined {
    return this.getDecodedToken()?.email;
  }

  /**
   * @returns Users current username from JWT Token
   */
  getuserName(): string | undefined {
    return this.getDecodedToken()?.username;
  }

  /**
   * @returns The current logged in users id from JWT Token
   */
  getUserId(): number | undefined {
    const token = this.getDecodedToken();
    if (token && token.subject) {
      return +token.subject;
    }
    return undefined;
  }

  /**
   * Returns the user roles as defined in the access token
   */
  getUserPermissions() {
    const access_token = this.getDecodedToken();
    if (access_token?.context?.permissions) {
      return access_token.context.permissions;
    }
    return [];
  }

  /**
   * Logs the user out of the program
   */
  async logout(redirect: boolean = true) {
    this.isLoggedIn.next(false);
    this.clearAuthStorage();
    if (redirect) this.router.navigate(['/auth/login']);
    await this.updatePermissions();
  }

  /**
   * Clears the local and session storage
   */
  clearAuthStorage() {
    localStorage.removeItem(this.accessTokenStr);
    localStorage.removeItem(this.refreshTokenStr);
    sessionStorage.removeItem(this.accessTokenStr);
    sessionStorage.removeItem(this.refreshTokenStr);
  }

  async setTokens(res: ILoginResponse, remember: boolean) {
    this.clearAuthStorage();
    if (remember) {
      localStorage.setItem(this.accessTokenStr, res.access_token);
      localStorage.setItem(this.refreshTokenStr, res.refresh_token);
    } else {
      sessionStorage.setItem(this.accessTokenStr, res.access_token);
      sessionStorage.setItem(this.refreshTokenStr, res.refresh_token);
    }
  }

  getAccessToken() {
    return (
      localStorage.getItem(this.accessTokenStr) ||
      sessionStorage.getItem(this.accessTokenStr)
    );
  }

  getRefreshToken() {
    return (
      localStorage.getItem(this.refreshTokenStr) ||
      sessionStorage.getItem(this.refreshTokenStr)
    );
  }

  /**
   * Checks if a token is expired
   */
  private isTokenExpired(token: string): boolean {
    try {
      const decodedToken: JwtPayload = jwtDecode(token);
      if (!decodedToken.exp) return true;
      if (Date.now() <= decodedToken.exp * 1000) return false;
      return true;
    } catch (error) {
      console.log('Error decoding token!', error);
      return true;
    }
  }

  /**
   * Checks the current users logged in status.
   * Grabs a new access_token if users has
   * expired with the refresh token
   * Also updates the observable value for logged in status.
   * @returns
   */
  async loggedInStatus(): Promise<{ isLoggedIn: boolean; reason?: string }> {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();
    // If refresh token doesn't exist, we cant get new tokens.
    if (!refreshToken || this.isTokenExpired(refreshToken) || !accessToken) {
      this.isLoggedIn.next(false);
      return {
        isLoggedIn: false,
        reason: 'No refresh/access token present, or refresh token expired!',
      };
    }

    // If access token not expired, then user is logged in.
    if (!this.isTokenExpired(accessToken)) {
      this.isLoggedIn.next(true);
      return { isLoggedIn: true };
    }

    // Request a new token with refresh token:
    try {
      const { access_token } = await lastValueFrom(
        this.requestNewAccessToken(refreshToken)
      );

      // Update access token depending on where it is.
      if (localStorage.getItem(this.accessTokenStr)) {
        localStorage.setItem(this.accessTokenStr, access_token);
      } else {
        sessionStorage.setItem(this.accessTokenStr, access_token);
      }
      this.isLoggedIn.next(true);
      return { isLoggedIn: true };
    } catch (error: any) {
      console.log('err', error);
      if (!(error instanceof HttpErrorResponse)) {
        this.isLoggedIn.next(false);
        return { isLoggedIn: false, reason: error };
      }

      if (error.status == 401) {
        this.isLoggedIn.next(false);
        return {
          isLoggedIn: false,
          reason: 'Refresh token has expired or is invalid.',
        };
      } else {
        this.isLoggedIn.next(true);

        /* Assume server is down, or unhandled error.
         * User can be considered logged in so they
         * don't get redirected to login if the server ever goes down.
         */
        return {
          isLoggedIn: true,
        };
      }
    }
  }

  private async updatePermissions() {
    const appAbility = this.injector.get(AbilityService<AppAbility>);
    (await firstValueFrom(appAbility.ability$)).update(
      buildAbility(this.getUserPermissions())
    );
  }
}
