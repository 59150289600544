<dx-data-grid
  width="100%"
  [dataSource]="dataSource" style="padding: 5px"
  [allowColumnResizing]="true"
  [scrolling]="{mode: 'virtual'}">
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate class="data-grid-header">
        Evaluation Criteria
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>
  <dxo-editing
    mode="popup"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true">
  </dxo-editing>
  <dxi-column
    dataType="string"
    alignment="center"
    dataField="name">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column
    dataType="number"
    alignment="center"
    caption="Max Points"
    width="150px"
    [allowResizing]="true"
    dataField="maxScore">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column
    width="150px"
    dataType="number"
    alignment="center"
    dataField="sortOrder">
  </dxi-column>
  <dxi-column
    dataType="string"
    alignment="center"
    [allowSorting]="false"
    caption="Description"
    editCellTemplate="htmlEditor"
    cellTemplate="htmlViewer"
    dataField="description">
    <div
      *dxTemplate="let cellInfo of 'htmlViewer'"
      style="max-height: 200px; overflow-y: scroll;">
      <div [innerHTML]="cellInfo.value"></div>
    </div>
    <div *dxTemplate="let cellInfo of 'htmlEditor'">
      <dx-html-editor
        [value]="cellInfo.value"
        (onValueChanged)="cellInfo.setValue($event.value)">
        <dxo-toolbar>
          <dxi-item name="bold"></dxi-item>
          <dxi-item name="italic"></dxi-item>
          <dxi-item name="alignCenter"></dxi-item>
          <dxi-item name="undo"></dxi-item>
          <dxi-item name="redo"></dxi-item>
        </dxo-toolbar>
      </dx-html-editor>
    </div>
  </dxi-column>
</dx-data-grid>
