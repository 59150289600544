export enum ReimbursementStatus {
  'Voided' = -100,
  'Sent Back to Applicant' = -10,
  'Created' = 10,
  'Submitted - Pending Review' = 20,
  'Sent Back - Pending Review' = 25,
  'Reviewed - Pending Approval' = 30,
  'Sent Back - Pending Approval' = 35,
  'Approved - Pending Supervisor Review' = 40,
  'Supervisor Reviewed - Pending Funding Approval' = 45,
  'Funding Approved' = 50,
  'Invoiced' = 55,
  'Paid' = 60,
}
