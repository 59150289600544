import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { GrantsService } from '../grants.service';
import { handleHttpError } from '../../http.error-handler';
import { environment } from '../../../../environments/environment';
import { IGrantScoringCriteria } from '../../types/IGrantScoringCriteria.interface';
import { IApplicationScored } from '../../types/IApplicationScored.interface';
import { IApplicationCriteria } from '../../types/IApplicationWithCriteria';
import { IGrantGroup } from '../../types/IGrantGroup.interface';
import { IUser } from '../../types/IUser';
import { IGrantCheck } from '../../types/IGrantCheck';

@Injectable({
  providedIn: 'root',
})
export class GrantChecksService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/eval/checks';

  getBaseURL(grantId: number = 0) {
    return this._grantService.getBaseURL() + `/${grantId}${this.path}`;
  }

  constructor(
    private _httpClient: HttpClient,
    private readonly _grantService: GrantsService
  ) {}

  getChecks(grantId: number) {
    return this._httpClient
      .get<IGrantCheck[]>(`${this.getBaseURL(grantId)}/`)
      .pipe(catchError(handleHttpError<IGrantCheck[]>));
  }

  getCheck(grantId: number, checkId: number) {
    return this._httpClient
      .get<IGrantCheck>(`${this.getBaseURL(grantId)}/${checkId}`)
      .pipe(catchError(handleHttpError<IGrantCheck>));
  }

  createCheck(grantId: number, obj: IGrantCheck) {
    return this._httpClient
      .post<IGrantCheck>(`${this.getBaseURL(grantId)}`, { ...obj, grantId })
      .pipe(catchError(handleHttpError<IGrantCheck>));
  }

  updateCheck(grantId: number, checkId: number, obj: Partial<IGrantCheck>) {
    return this._httpClient
      .patch<IGrantCheck>(`${this.getBaseURL(grantId)}/${checkId}`, obj)
      .pipe(catchError(handleHttpError<IGrantCheck>));
  }

  deleteCheck(grantId: number, checkId: number) {
    return this._httpClient
      .delete<IGrantCheck>(`${this.getBaseURL(grantId)}/${checkId}`)
      .pipe(catchError(handleHttpError<IGrantCheck>));
  }
}
