import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../shared/services';
import { CommonModule } from '@angular/common';
import { SurveyModule } from 'survey-angular-ui';
import { PlaygroundModule } from '../playground/playground.component';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
})
export class ApplicationFormComponent implements OnInit {
  applicationId: number = 0;

  constructor(
    private applicationService: ApplicationsService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    const applicationId =
      this.activatedRoute.snapshot.paramMap.get('applicationId');
    this.applicationId = Number(applicationId);
  }
}

@NgModule({
  imports: [CommonModule, SurveyModule, PlaygroundModule],
  declarations: [ApplicationFormComponent],
  exports: [ApplicationFormComponent],
})
export class ApplicationsFormModule {}
