<dx-data-grid [dataSource]="dataSource" style="padding: 5px"
  [scrolling]="{mode: 'virtual'}">
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate class="data-grid-header">
        Application Checks
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>
  <dxo-editing
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true">
  </dxo-editing>
  <dxi-column
    dataType="string"
    alignment="center"
    dataField="name">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column
    dataType="boolean"
    alignment="center"
    dataField="isRequired">
  </dxi-column>
  <dxi-column
    dataType="number"
    alignment="center"
    dataField="sortOrder">
  </dxi-column>
  <dxi-column
    dataType="number"
    alignment="center"
    dataField="points">
  </dxi-column>
</dx-data-grid>
