import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { notifyWrapper } from '../../../shared/globals/notify-wrapper';

type BtnType = 'default' | 'primary' | 'success' | 'danger';

export interface PopupState {
  title?: string;
  message?: string;
  confirmType?: BtnType;
  cancelType?: BtnType;
  isVisible: boolean;
  checkNumber?: string;
}

@Injectable({ providedIn: 'root' })
export class IssueCheckPopupService {
  private $popupState: BehaviorSubject<PopupState> =
    new BehaviorSubject<PopupState>({
      isVisible: false,
      checkNumber: '',
    });
  private $confirm?: Subject<{ confirm: boolean; reason?: string }>;

  confirm(
    title: string,
    message: string,
    confirmType?: BtnType,
    cancelType?: BtnType
  ) {
    this.$popupState.next({
      title: title,
      message,
      confirmType,
      cancelType,
      isVisible: true,
      checkNumber: '',
    });

    this.$confirm = new Subject();
    return this.$confirm.asObservable();
  }

  get popupState() {
    return this.$popupState.asObservable();
  }

  /**
   * Whether the user elected to yes or no (to continue the action)
   * @param continueAction
   */
  closePopup(continueAction: boolean, reason?: string) {
    this.$popupState.next({ isVisible: false, checkNumber: reason });
    this.$confirm?.next({ confirm: continueAction, reason });
    this.$confirm?.complete();
  }
}
