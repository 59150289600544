import {
  Component,
  NgModule,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ItemClickEvent } from 'devextreme/ui/tree_view';
import {
  DxTreeViewModule,
  DxTreeViewComponent,
} from 'devextreme-angular/ui/tree-view';
import { NavigationItem, navigation } from '../../../app-navigation';

import * as events from 'devextreme/events';
import { AbilityService } from '@casl/angular';
import { AppAbility } from '../../services';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss'],
})
export class SideNavigationMenuComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild(DxTreeViewComponent, { static: true })
  menu!: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<ItemClickEvent>();

  @Output()
  openMenu = new EventEmitter<any>();

  private _selectedItem!: string;
  @Input()
  set selectedItem(value: string) {
    this._selectedItem = value;
    if (!this.menu.instance) {
      return;
    }
    this.menu.instance.selectItem(value);
  }

  private _compactMode = false;

  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  private _items?: Record<string, unknown>[];
  public items: Record<string, unknown>[] = [];

  constructor(
    private elementRef: ElementRef,
    private abilityService: AbilityService<AppAbility>
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getItems();
  }

  onItemClick(event: ItemClickEvent) {
    this.selectedItemChanged.emit(event);
  }

  async ngAfterViewInit() {
    await this.getItems();
    events.on(this.elementRef.nativeElement, 'dxclick', (e: Event) => {
      this.openMenu.next(e);
    });
  }

  async ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
    console.log('Destroying Side Navigation Menu');
  }

  getItems = async () => {
    if (!this._items) {
      const ability = await firstValueFrom(this.abilityService.ability$);

      /***
       * Handles permissiosn recursivly for each nav item
       */
      const handlePermissions = (
        navItems: NavigationItem[]
      ): NavigationItem[] => {
        const returnedNavItems: NavigationItem[] = [];
        navItems.forEach((nav) => {
          if (nav.items) {
            nav.items = [...handlePermissions(nav.items)];
          }

          if (!nav.permissions) {
            returnedNavItems.push(nav);
          } else {
            let canView = false;
            nav.permissions.forEach((permission) => {
              if (ability.can(permission.action, permission.subject)) {
                canView = true;
              }
            });
            if (canView) returnedNavItems.push(nav);
          }
        });

        return returnedNavItems;
      };

      // Deep copy needed for navigation items
      function deepCopyArrayWithChildren(array: NavigationItem[]) {
        return array.map((item) => {
          const newItem = { ...item }; // Shallow copy the item

          if (newItem.items && newItem.items.length > 0) {
            newItem.items = deepCopyArrayWithChildren(newItem.items); // Recursively copy the children array
          }

          return newItem;
        });
      }
      this._items = handlePermissions(deepCopyArrayWithChildren(navigation));
    }

    this.items = this._items;
    this._items = undefined;
    this.menu.instance?._refresh();
  };
}

@NgModule({
  imports: [DxTreeViewModule],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
