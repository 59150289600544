
<div
  style="display: flex; width: 100%; justify-content: center; justify-items: center; background-color: #1c4e71; z-index: 99; color: white;">
  <div
    style="flex: 1; display: flex; justify-items: center; align-items: center;">
    <dx-button
      style="margin-left: 10px;"
      type="normal"
      (onClick)="onClickBack()"
      [stylingMode]="'contained'"
      icon="back">
    </dx-button>
  </div>
  <div style="flex: 4; text-align: center;">
    <h4 style="margin: 0px;">{{title}}</h4>
  </div>
  <div style="flex: 1">
  </div>
</div>
