<dx-data-grid
  [rowAlternationEnabled]="true"
  [dataSource]="store">
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <div class="data-grid-header">Budget</div>
      </div>
    </dxi-item>
  </dxo-toolbar>
  <dxi-column
    [format]="{
                type: 'currency',
                precision: 2
                }"
    alignment="center"
    dataField="category"></dxi-column>
  <dxi-column
    [format]="{
                type: 'currency',
                precision: 2
                }"
    alignment="center"
    dataField="description"></dxi-column>
  <dxi-column
    [format]="{
                type: 'currency',
                precision: 2
                }"
    alignment="center"
    dataField="cost"
    caption="Cost"></dxi-column>
  <dxi-column
    [format]="{
                type: 'currency',
                precision: 2
                }"
    alignment="center"
    dataField="available"></dxi-column>
  <dxi-column
    [format]="{
                type: 'currency',
                precision: 2
                }"
    alignment="center"
    dataField="leftToSpend"></dxi-column>
  <dxi-column
    [format]="{
                type: 'currency',
                precision: 2
                }"
    alignment="center"
    dataField="pendingApproval"></dxi-column>
  <dxi-column
    [format]="{
                type: 'currency',
                precision: 2
                }"
    alignment="center"
    dataField="approved"></dxi-column>

  <dxo-summary>
    <dxi-total-item
      [valueFormat]="{
                type: 'currency',
                precision: 2
                }"
      column="cost"
      summaryType="sum">
    </dxi-total-item>
    <dxi-total-item
      [valueFormat]="{
                type: 'currency',
                precision: 2
                }"
      column="available"
      summaryType="sum">
    </dxi-total-item>
    <dxi-total-item
      [valueFormat]="{
                type: 'currency',
                precision: 2
                }"
      column="pendingApproval"
      summaryType="sum">
    </dxi-total-item>
    <dxi-total-item
      [valueFormat]="{
                type: 'currency',
                precision: 2
                }"
      column="approved"
      summaryType="sum">
    </dxi-total-item>
  </dxo-summary>
</dx-data-grid>
