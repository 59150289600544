<dx-scroll-view height="100%" width="100%" class="with-footer single-card">
  <div class="dx-card content" width="500px">
    <div class="header">
      <div class="title">{{title}}</div>
      <div class="description">{{description}}</div>
    </div>
    <!-- <div style="display: flex; justify-content: center; margin: 20px">
      <img width="80px" src="../../../assets/GMPro - NO Text.png" />
    </div> -->
    <ng-content></ng-content>
  </div>
</dx-scroll-view>
