import { Component, Input, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { IApplication } from 'src/app/shared/types/application.interface';
import { Router } from '@angular/router';
import 'devextreme/integration/jquery';
import { GrantGroupService } from '../../../../shared/services/eval/group.service';
import { IGrantGroup } from '../../../../shared/types/IGrantGroup.interface';
import { GrantsService } from '../../../../shared/services';
import { IGrantCheck } from '../../../../shared/types/IGrantCheck';
import { GrantChecksService } from '../../../../shared/services/eval/checks.service';

@Component({
  selector: 'app-grant-app-checks',
  templateUrl: './app-checks.component.html',
  styleUrl: './app-checks.component.scss',
})
export class GrantAppChecksGroupComponent implements OnInit {
  @Input({ required: true })
  grantId!: number;

  grantGroup?: CustomStore<IGrantCheck>;
  dataSource!: DataSource<IGrantCheck>;

  constructor(
    private readonly _checkService: GrantChecksService,
    private readonly _router: Router
  ) {}

  async ngOnInit() {
    this.grantGroup = new CustomStore<IGrantCheck>({
      key: 'id',
      load: async () =>
        await lastValueFrom(this._checkService.getChecks(this.grantId)),
      byKey: async (id) =>
        await lastValueFrom(this._checkService.getCheck(this.grantId, id)),
      insert: async (value) =>
        await lastValueFrom(
          this._checkService.createCheck(this.grantId, value)
        ),
      update: async (id, value) =>
        await lastValueFrom(
          this._checkService.updateCheck(this.grantId, id, value)
        ),
      remove: async (id) => {
        await lastValueFrom(this._checkService.deleteCheck(this.grantId, id));
      },
    });

    this.dataSource = new DataSource<IGrantCheck, number>({
      store: this.grantGroup,
    });
  }
}
