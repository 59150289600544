import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { IApplication } from 'src/app/shared/types/application.interface';
import { ApplicationsService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { APPLICATION_STATUS } from '../../../../shared/enums/ApplicationStatus.enum';
import 'devextreme/integration/jquery';

@Component({
  selector: 'app-application-eval-datagrid',
  templateUrl: './application-eval-datagrid.component.html',
  styleUrl: './application-eval-datagrid.component.scss',
})
export class ApplicationEvalDataGridComponent implements OnInit {
  applicationsStore?: CustomStore<IApplication>;
  applicationsDataSource!: DataSource<IApplication>;
  dataGridColumns: DxDataGridTypes.Column[] = [];

  constructor(
    private readonly _applicationsService: ApplicationsService,
    private readonly _router: Router
  ) {}

  async ngOnInit() {
    this.applicationsStore = new CustomStore<IApplication>({
      key: 'id',
      load: async () =>
        await lastValueFrom(
          this._applicationsService.getEvaluatingApplications()
        ),
    });

    this.applicationsDataSource = new DataSource<IApplication, number>({
      store: this.applicationsStore,
    });
  }

  onViewApplicationResults = async (rowData: any) => {
    const applicationId = rowData.row.data.id;
    this._router.navigate([`/application/evaluate`, applicationId]);
  };

  onCellPrepared(e: any) {
    // if (e.rowType === 'data' && e.column.type === 'buttons') {
    //   var $links = e.cellElement.find('.reviewBtn');
    //   if (e.row.data.statusValue < APPLICATION_STATUS.SUBMITTED)
    //     $links.filter('.reviewBtn').remove();
    // }
  }
}
