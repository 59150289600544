<app-nav-header title="Review"></app-nav-header>
<div class="container" *ngIf="application && grant">
  <div class="box left">
    <app-view-application
      pageKey="Review"
      [application]="application"
      [grant]="grant"></app-view-application>
  </div>
  <div class="box right">
    <div *ngIf="application.statusValue == APPLICATION_STATUS.SUBMITTED">
      <div *ngIf="!showDenyForm">
        <p>Please check the following before accepting the application for
          review <b>('*' are required checks):</b></p>
        <form (submit)="onSubmit($event)">
          <dx-form [formData]="formData">
            <dxi-item
              *ngFor="let check of checks"
              editorType="dxCheckBox"
              dataField="checks.{{check.id}}"
              [editorOptions]="{iconSize: 20, value: false}">
              <dxo-label [text]="check.name"></dxo-label>
              <dxi-validation-rule type="required"
                *ngIf="check.isRequired"></dxi-validation-rule>
            </dxi-item>
            <dxi-item itemType="group" [colSpan]="2" [colCount]="2">
              <dxi-item
                itemType="button">
                <dxo-button-options
                  text="Send Back"
                  type="danger"
                  width="100%"
                  [onClick]="onSendBackClick">
                </dxo-button-options>
              </dxi-item>
              <dxi-item itemType="button"
                [buttonOptions]="{text: 'Approve for Review', useSubmitBehavior: true, type: 'default', width: '100%'}"></dxi-item>
            </dxi-item>
          </dx-form>
        </form>
      </div>
      <div *ngIf="showDenyForm">
        <app-deny-app-form
          (onSubmit)="onSendBackSubmit($event)"
          (onCancel)="onSendBackFormCancel()"></app-deny-app-form>
      </div>
    </div>
    <div *ngIf="application.statusValue >= APPLICATION_STATUS.REVIEWED">
      <div>
        <h4 style="margin: 0px;">Points:</h4>
        <p style="margin: 0px;">
          <b>Total Points:</b> {{totalPoints | number: '1.2-2'}}<br />
          <b>Total Bonus Points:</b> {{totalBonusPoints | number:
          '1.2-2'}}<br />
          <b>Total Evaluation Points:</b> {{totalEvaluationPoints | number:
          '1.2-2'}}</p>

      </div>
      <div *ngIf="bonusPoints.length > 0">
        <h4 style="margin: 0px;">Bonus Points:</h4>
        <dx-data-grid [dataSource]="bonusPoints">
          <dxi-column caption="Name" dataField="bonusItemName"
            alignment="center"></dxi-column>
          <dxi-column caption="Bonus Value" dataField="bonusValue"
            alignment="center"></dxi-column>
          <dxi-column caption="Obtained" dataField="obtained"
            alignment="center"></dxi-column>
        </dx-data-grid>
      </div>
      <h4 style="margin: 0px;">Evaluators:</h4>
      <dx-data-grid
        *ngIf="evaluations.length > 0"
        [dataSource]="evaluations">
        <dxi-column
          caption="First Name"
          dataField="evaluator.firstName">
        </dxi-column>
        <dxi-column
          caption="Last Name"
          dataField="evaluator.lastName">
        </dxi-column>
        <dxi-column
          caption="Status"
          dataField="status">
        </dxi-column>
        <dxi-column
          caption="Total Points"
          [calculateCellValue]="calculateScoreColumn"
          [calculateDisplayValue]="calculateScoreColumn"></dxi-column>
      </dx-data-grid>
      <br />
      <dx-button
        *ngIf="evaluations.length == 0"
        width="100%"
        type="default"
        (onClick)="onClickAssignEvaluators()"
        text="Assign Evaluators"></dx-button>
    </div>
    <div>
      <application-read-history
        [applicationId]="applicationId"></application-read-history>
      <application-status-history *ngIf="application.statusHistory"
        [applicationHistory]="application.statusHistory"></application-status-history>
    </div>
  </div>
</div>
