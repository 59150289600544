<app-nav-header title="Evaluate"></app-nav-header>
<div class="container" *ngIf="application && grant && scoringCritera">
  <div class="box left">
    <app-view-application
      pageKey="Evaluate"
      [application]="this.application"
      [grant]="this.grant"></app-view-application>
  </div>
  <div class="box right">
    <app-app-eval
      (onEvalSubmit)="submitEval()"
      (onSoreValueChange)="setScoredValue($event)"
      [applicationCriteria]="scoringCritera">
    </app-app-eval>
  </div>
</div>
