import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {
  SideNavOuterToolbarModule,
  SideNavInnerToolbarModule,
  SingleCardModule,
} from './layouts';
import {
  FooterModule,
  CreateAccountFormModule,
  ChangePasswordFormModule,
  LoginFormModule,
} from './shared/components';
import {
  AuthService,
  ScreenService,
  AppInfoService,
  AppAbility,
  createAbility,
} from './shared/services';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './shared/services/auth.interceptor.service';
import { PlaygroundModule } from './pages/playground/playground.component';
import { ConfirmationPopupModule } from './shared/components/confirmation-popup/confirmation-popup.component';
import { AbilityModule, AbilityService } from '@casl/angular';
import { AdvPaymentReimbursementsPopupComponent } from './shared/components/application-awards/adv-payment-popup/adv-payment-popup.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    AbilityModule,
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    CreateAccountFormModule,
    ChangePasswordFormModule,
    LoginFormModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    PlaygroundModule,
    ConfirmationPopupModule,
    AdvPaymentReimbursementsPopupComponent,
  ],
  providers: [
    AuthService,
    ScreenService,
    AppInfoService,
    AbilityService,
    { provide: AppAbility, useFactory: createAbility, deps: [AuthService] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
