import { PermissionAction, PermissionItem } from './shared/enums';
import { Actions } from './shared/services/app-user-abilities.service';

export const navigation: NavigationItem[] = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
  },
  {
    text: 'Grants',
    path: '/grants',
    icon: 'doc',
  },
  {
    text: 'Admin',
    icon: 'lock',
    permissions: [
      { subject: PermissionItem.GRANTS_PAGE, action: PermissionAction.READ },
      {
        subject: PermissionItem.CAN_BE_APPLICATION_EVALUATOR,
        action: PermissionAction.CAN,
      },
      {
        subject: PermissionItem.APPLICATIONS_PAGE,
        action: PermissionAction.READ,
      },
      { subject: PermissionItem.USERS_PAGE, action: PermissionAction.READ },
      { subject: PermissionItem.REIMBURSEMENTS, action: PermissionAction.READ },
    ],
    items: [
      {
        text: 'Grants',
        path: '/admin/grants',
        icon: 'activefolder',
        permissions: [
          {
            subject: PermissionItem.GRANTS_PAGE,
            action: PermissionAction.READ,
          },
        ],
      },
      {
        text: 'Applications',
        path: '/admin/applications',
        icon: 'edit',
        permissions: [
          {
            subject: PermissionItem.APPLICATIONS_PAGE,
            action: PermissionAction.READ,
          },
        ],
      },
      {
        text: 'Evaluating',
        path: '/admin/evaluating',
        icon: 'edit',
        permissions: [
          {
            subject: PermissionItem.CAN_BE_APPLICATION_EVALUATOR,
            action: PermissionAction.CAN,
          },
        ],
      },
      {
        text: 'Staff',
        path: '/admin/staff',
        icon: 'group',
        permissions: [
          {
            subject: PermissionItem.USERS_PAGE,
            action: PermissionAction.READ,
          },
        ],
      },
      {
        text: 'Reimbursements',
        path: '/admin/reimbursements',
        icon: 'group',
        permissions: [
          {
            subject: PermissionItem.REIMBURSEMENTS,
            action: PermissionAction.READ,
          },
        ],
      },
    ],
  },
];

type UserRolePermissionItem = {
  subject: PermissionItem;
  action: PermissionAction;
};

export type NavigationItem = {
  text: string;
  path?: string;
  icon: string;
  permissions?: UserRolePermissionItem[];
  items?: NavigationItem[];
};
