 <form (submit)="_onSubmit($event)">
  <dx-form [formData]="rejectFormData">
    <dxi-item
      dataField="reason"
      editorType="dxTextArea"
      [editorOptions]="{
          autoResizeEnabled: true
        }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      [label]="{text: 'Deadline (No deadline, means they cannot resubmit)'}"
      dataField="resubmitDeadline"
      editorType="dxDateBox"
      [editorOptions]="{type: 'datetime',showClearButton: true,useMaskBehavior: true, openOnFieldClick: true, acceptCustomValue: false}">
    </dxi-item>
    <dxi-item itemType="group" colCount="2">
      <dxi-item
        itemType="button">
        <dxo-button-options
          [onClick]="onCancelClicked"
          text="Cancel"
          type="default"
          width="100%">
        </dxo-button-options>
      </dxi-item>
      <dxi-item
        itemType="button">
        <dxo-button-options
          [useSubmitBehavior]="true"
          text="Send Back"
          type="danger"
          width="100%">
        </dxo-button-options>
      </dxi-item>
    </dxi-item>
  </dx-form>
</form>
