import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { GrantsService } from 'src/app/shared/services';
import * as _ from 'lodash';
import {
  IGrant,
  IGrantTableColumns,
  IGrantWithoutJsonData,
} from 'src/app/pages/grants/types/IGrants';
import { omitProperties } from 'src/app/shared/utils/object.utils';
import { generateDataGridColumns } from 'src/app/shared/utils/data-grid/data-grid.utils';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-data-grid',
  templateUrl: './admin-data-grid.component.html',
  styleUrl: './admin-data-grid.component.scss',
})
export class AdminDataGridComponent implements OnInit {
  grantsStore?: CustomStore<IGrant>;
  grantsDataSource!: DataSource<IGrant>;
  dataGridColumns: DxDataGridTypes.Column[] = [];

  onViewGrant = async (e: any) => {
    await this._router.navigate([`/admin/grants`, e.row.data.id]);
  };

  constructor(
    private _grantsService: GrantsService,
    private readonly _router: Router
  ) {}

  async ngOnInit() {
    this.grantsStore = new CustomStore<IGrant>({
      key: 'id',
      load: async () => {
        const grants = await lastValueFrom(this._grantsService.getAllGrants());

        const grantsWithoutJsonDataResults = await omitProperties(grants, [
          'jsonData',
        ]);

        const columnsToFormat: IGrantTableColumns[] = [
          'availableFunding',
          'awardedFunding',
        ];

        this.dataGridColumns = await generateDataGridColumns(
          grantsWithoutJsonDataResults[0],
          columnsToFormat
        );

        return grants;
      },
      update: async (key, updatedGrant: Partial<IGrantWithoutJsonData>) => {
        await lastValueFrom(this._grantsService.updateGrant(key, updatedGrant));
      },
      remove: async (key) => {
        await lastValueFrom(this._grantsService.deleteGrant(key));
      },
      insert: async (grant: Partial<IGrant>) => {
        return await lastValueFrom(this._grantsService.createGrant(grant));
      },
    });

    this.grantsDataSource = new DataSource<IGrant, number>({
      store: this.grantsStore,
    });
  }
}
