import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrl: './nav-header.component.scss',
})
export class NavHeaderComponent {
  @Input({ required: true })
  title!: string;

  constructor(private _location: Location) {}

  onClickBack = () => {
    this._location.back();
  };
}
