import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SingleCardModule } from './layouts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthenticated-content',
  template: `
    <app-single-card [title]="title" [description]="description">
      <router-outlet></router-outlet>

      <div style="text-align: center;">
        Please contact
        <a href="mailto:gmprosupport@lsu.edu"> gmprosupport&#64;lsu.edu</a> for
        support!
      </div>
    </app-single-card>
  `,
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class UnauthenticatedContentComponent {
  constructor(private router: Router) {}

  get title() {
    let path = this.router.url.split('/')[2];
    if (path) {
      path = path.split('?')[0];
    }
    switch (path) {
      case 'login':
        return 'Sign In';
      case 'reset-password':
        return 'Reset Password';
      case 'create-account':
        return 'Sign Up';
      case 'change-password':
        return 'Change Password';
      case 'forgot-username':
        return 'Forgot Username';
      case 'forgot-password':
        return 'Forgot Password';
      default:
        return '';
    }
  }

  get description() {
    let path = this.router.url.split('/')[1];
    switch (path) {
      case 'auth':
        path = this.router.url.split('/')[2];
        switch (path) {
          case 'forgot-username':
            return 'After submitting, you will receved a list of usernames that belong to your email.';
          case 'forgot-password':
            return 'After submitting, you will receved a link to reset your password.';
          default:
            return '';
        }
      default:
        return '';
    }
  }
}
@NgModule({
  imports: [CommonModule, RouterModule, SingleCardModule],
  declarations: [UnauthenticatedContentComponent],
  exports: [UnauthenticatedContentComponent],
})
export class UnauthenticatedContentModule {}
