import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs';
import { IApplication } from '../../types/application.interface';
import { handleHttpError } from '../../http.error-handler';
import { ApplicationRejectBody } from '../../types/application-reject.interface';
import { ApplicationReviewBody } from '../../types/application-review.interface';
import { IApplicationReadHistory } from '../../types/IApplicationReadHistory.interface';
import { IFile } from '../../types/IFile';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/applications';

  constructor(private _httpClient: HttpClient) {}

  getBaseURL() {
    return this.baseUrl + this.path;
  }

  getAllApplications() {
    return this._httpClient
      .get<IApplication[]>(this.getBaseURL())
      .pipe(catchError(handleHttpError<IApplication[]>));
  }

  getEvaluatingApplications() {
    return this._httpClient
      .get<IApplication[]>(this.getBaseURL() + '/assigned')
      .pipe(catchError(handleHttpError<IApplication[]>));
  }

  getApplication(id: number) {
    return this._httpClient
      .get<IApplication>(`${this.getBaseURL()}/${id}`)
      .pipe(catchError(handleHttpError<IApplication>));
  }

  getEvaluationsForApplication(id: number) {
    return this._httpClient
      .get<any>(`${this.getBaseURL()}/${id}/evaluations`)
      .pipe(catchError(handleHttpError<any>));
  }

  postApplicationForm(id: number, formData: object) {
    return this._httpClient
      .post<IApplication>(`${this.getBaseURL()}/${id}`, formData)
      .pipe(catchError(handleHttpError<IApplication>));
  }

  patchApplicationForm(id: number, formData: object) {
    return this._httpClient
      .patch<IApplication>(`${this.getBaseURL()}/${id}`, formData)
      .pipe(catchError(handleHttpError<IApplication>));
  }

  getFilesForKey(id: number, key: string) {
    return this._httpClient.get<IFile[]>(
      `${this.getBaseURL()}/${id}/file/${key}`
    );
  }

  getFiles(id: number) {
    return this._httpClient
      .get<IFile[]>(`${this.getBaseURL()}/${id}/file`)
      .pipe(catchError(handleHttpError<IFile[]>));
  }

  getFile(fileId: string) {
    return this._httpClient.get(`${this.getBaseURL()}/file/${fileId}`, {
      responseType: 'blob',
    });
  }

  deleteFile(fileId: string) {
    return this._httpClient.delete(`${this.getBaseURL()}/file/${fileId}`);
  }

  uploadFile(id: number, key: string, data: { files: File[]; tag?: string }) {
    const formData: FormData = new FormData();
    formData.append('files[]', data.files[0]);
    if (data.tag) {
      formData.append('tag', data.tag);
    }
    return this._httpClient.post(
      `${this.getBaseURL()}/${id}/file/${key}`,
      formData
    );
  }

  submitApplication(id: number) {
    return this._httpClient.post(`${this.getBaseURL()}/${id}/submit`, {});
  }

  assignEvaluators(id: number, force: boolean = false) {
    return this._httpClient
      .post<AssignResponse>(
        `${this.getBaseURL()}/${id}/assignEvaluators?force=${force}`,
        {}
      )
      .pipe(catchError(handleHttpError<AssignResponse>));
  }

  getReadHistory(id: number) {
    return this._httpClient
      .get<IApplicationReadHistory[]>(`${this.getBaseURL()}/${id}/readHistory`)
      .pipe(catchError(handleHttpError<IApplicationReadHistory[]>));
  }

  getBonusPoints(id: number) {
    return this._httpClient
      .get<any>(`${this.getBaseURL()}/${id}/bonusPoints`)
      .pipe(catchError(handleHttpError<any>));
  }
}

export type AssignResponse = {
  data?: IApplication;
  success: boolean;
  message?: string;
};
