import { Component, Input, OnInit } from '@angular/core';
import { ApplicationsService } from '../../services';
import { IApplicationReadHistory } from '../../types/IApplicationReadHistory.interface';
import { lastValueFrom } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'application-read-history',
  styleUrl: './application-read-history.component.scss',
  templateUrl: './application-read-history.component.html',
})
export class ApplicationReadHistoryComponent {
  @Input({ required: true })
  applicationId!: number;
  readDataSource: DataSource<IApplicationReadHistory, number>;
  constructor(private readonly _applicationService: ApplicationsService) {
    const store = new CustomStore({
      load: async () =>
        await lastValueFrom(
          this._applicationService.getReadHistory(this.applicationId)
        ),
      key: 'id',
    });
    this.readDataSource = new DataSource({ store });
  }

  calculateLatest = (e: any) => {
    console.log(e);
  };
}
