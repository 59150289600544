import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { AppReimbursementsService } from '../../../services/application/app-reimbursement.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { notifyWrapper } from '../../../globals/notify-wrapper';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-reimbursement-budget',
  standalone: true,
  imports: [CommonModule, DxDataGridModule],
  templateUrl: './reimbursement-budget.component.html',
  styleUrl: './reimbursement-budget.component.scss',
})
export class ReimbursementBudgetComponent implements OnDestroy {
  budgetItems: any[] = [];

  @Input({ required: true })
  applicationId!: number;
  @Input({ required: true })
  awardId!: number;
  @Input({ required: false })
  reimbursementId?: number;

  @ViewChild(DxDataGridComponent)
  dataGrid?: DxDataGridComponent;
  subscriptions: Subscription = new Subscription();
  constructor(private riService: AppReimbursementsService) {
    this.subscriptions.add(
      riService.refresh$.subscribe(async () => {
        this.dataGrid?.instance.refresh();
      })
    );
  }

  store = new CustomStore({
    key: 'id',
    load: async () =>
      await lastValueFrom(
        this.riService.getBudgetItems(
          this.applicationId,
          this.awardId,
          this.reimbursementId
        )
      ),
  });
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // async loadBudgetItems() {
  //   console.log('Loading budget items');
  //   try {
  //     this.budgetItems = await lastValueFrom(
  //       this.riService.getBudgetItems(this.applicationId, this.awardId)
  //     );
  //   } catch (error) {
  //     notifyWrapper(`Error loading budget items! ${error}`, 'error');
  //   }
  // }
}
