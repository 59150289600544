import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxNumberBoxComponent,
  DxNumberBoxModule,
  DxPopupModule,
  DxTabPanelModule,
  DxTextBoxModule,
} from 'devextreme-angular';

import { ReimbursementFormComponent } from '../reimbursement-form/reimbursement-form.component';
import { ReimbursementCommentsComponent } from '../../../../pages/reimbursement/comments/comments.component';
import { ReimbursementFilesComponent } from '../../../../pages/reimbursement/invoice-files/invoice-files.component';
import { AppAwardInterface } from '../../../services/application/awards.service';
import { ReimbursementBudgetComponent } from '../reimbursement-budget/reimbursement-budget.component';
import { InputEvent } from 'devextreme/ui/text_box';
import {
  AppReimbursementsService,
  AwardBalance,
} from '../../../services/application/app-reimbursement.service';
import { lastValueFrom } from 'rxjs';
import { ReimbursementsService } from '../../../services/reimbursements.service';

@Component({
  selector: 'app-adv-payment-reimbursements-popup',
  standalone: true,
  imports: [
    DxPopupModule,
    DxFormModule,
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxTabPanelModule,
    DxTextBoxModule,
    DxNumberBoxModule,
  ],
  templateUrl: './adv-payment-popup.component.html',
  styleUrl: './adv-payment-popup.component.scss',
})
export class AdvPaymentReimbursementsPopupComponent implements OnInit {
  amount: number = 0;

  @Output()
  onAgree: EventEmitter<any> = new EventEmitter();

  constructor(private appRiService: AppReimbursementsService) {}

  async ngOnInit() {
    await this.loadBalance();
  }

  onInput($event: any) {
    console.log('INPUT', $event, $event.element.get(0), $event.target);
  }

  balance?: AwardBalance;

  async loadBalance() {
    const balance = await lastValueFrom(
      this.appRiService.getBalance(this.award.applicationId, this.award.id)
    );

    console.log(balance);

    this.balance = balance;
  }

  @Input()
  visible: boolean = false;

  @Input({ required: true })
  award!: AppAwardInterface;

  @Output()
  visibleChange = new EventEmitter<boolean>();

  textBoxValue: string = '';

  get canAgree() {
    return this.textBoxValue.toLowerCase() == 'i agree' && this.amount > 0;
  }

  _onAgree = () => {
    const textAgreedToo = document.getElementById('agreementText')?.innerHTML;

    if (!textAgreedToo) throw new Error("Can't find agreement text");

    this.onAgree.emit({
      advRequested: this.amount,
      acceptTermsText: textAgreedToo,
    });
  };
  onHiding = () => {
    this.visible = false;
    this.visibleChange.emit(false);
  };
}
