import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IUserOptions,
  UserService,
} from '../../../../shared/services/user.service';
import { IUser } from '../../../../shared/types/IUser';
import { DxFormComponent } from 'devextreme-angular';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { lastValueFrom } from 'rxjs';
import { notifyWrapper } from '../../../../shared/globals/notify-wrapper';

@Component({
  selector: 'app-admin-invite-user-popup',
  templateUrl: 'invite-user.component.html',
  styleUrl: 'invite-user.component.scss',
})
export class AdminInviteUserPopupComponent implements OnInit {
  constructor(private readonly _userService: UserService) {}

  @Input()
  visible: boolean = true;

  @Output()
  visibleChange: EventEmitter<boolean> = new EventEmitter();

  async ngOnInit() {
    await this.loadOptions();
  }
  options?: IUserOptions;
  async loadOptions() {
    try {
      this.options = await lastValueFrom(this._userService.getOptions());
    } catch (error) {
      notifyWrapper('Error loading options!', 'error');
    }
  }

  submitButtonOptions: DxButtonTypes.Properties = {
    useSubmitBehavior: true,
    text: 'Invite',
    type: 'default',
  };

  user: Partial<IUser> = {};

  closePopup() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const res = await lastValueFrom(this._userService.inviteUser(this.user));
      notifyWrapper('Successfully invited user!', 'success');
      this.closePopup();
    } catch (e: any) {
      console.log(e);
      if (e.error && e.error.message) {
        notifyWrapper(e.error.message, 'error');
      } else {
        notifyWrapper('Error inviting user!', 'error');
      }
    }
  };
}
