<dx-popup
  height="100%"
  width="100%"
  (onHidden)="onHiding()"
  [visible]="visible"
  [showTitle]="true"
  [title]="'View Reimbursement'">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="formdata.id">
      <p><b>Current Status:</b> {{formdata.statusString}}</p>
      <p
        *ngIf="formdata.status == REIMBURSEMENT_STATUS['Sent Back to Applicant']"><b>Reason
          for Sending Back: </b>{{formdata.reason}}</p>
    </div>
    <app-reimbursement-form
      [award]="award"
      [advancedPaymentEligible]="!!award.advancedPaymentPercentage"
      [readonly]="!canEdit"
      [formdata]="formdata"
      [(isEditing)]="isEditing"
      (onAdvSave)="submitAdvPayment($event)"
      (onSave)="saveEdit($event)"></app-reimbursement-form>
    <div *dxTemplate="let data of 'content'">
      <span style="font-size: 20px;">Reimbursement Details</span>
    </div>
    <!-- Can only view the below if you have created a reimbursement -->
    <div *ngIf="formdata.id">
      <app-reimbursement-budget
        [reimbursementId]="formdata.id"
        [applicationId]="application.id"
        [awardId]="award.id"></app-reimbursement-budget>
      <dx-tab-panel style="padding-top: 10px;">
        <dxi-item title="Invoices">
          <div *dxTemplate>
            <dx-data-grid
              [rowAlternationEnabled]="true"
              (onEditorPreparing)="onEditorPreparing($event)"
              (onSaved)="rowChanges()"
              [editing]="{
          allowAdding: canEdit,
          allowDeleting: canEdit,
          allowUpdating: canEdit,
          mode: 'row'}"
              [dataSource]="invoiceStore ?? []">
              <dxo-toolbar>
                <dxi-item location="before">
                  <div *dxTemplate>
                    <div class="data-grid-header">Vendor Invoices</div>
                  </div>
                </dxi-item>
                <dxi-item name="addRowButton"></dxi-item>
                <dxi-item name="saveButton"></dxi-item>
              </dxo-toolbar>
              <dxi-column
                alignment="center"
                dataType="date"
                caption="Invoice Date"
                [editorOptions]="{ useMaskBehavior: true, openOnFieldClick: true }"
                format="MMM dd, yyyy h:mm a"
                dataField="date"></dxi-column>
              <dxi-column
                alignment="center"
                dataField="vendorName"
                caption="Vendor Name">
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-column>
              <dxi-column
                alignment="center"
                dataField="invoiceNumber"
                caption="Invoice Number">
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-column>
              <dxi-column
                alignment="center"
                dataField="budgetId"
                caption="Budget"
                dataType="number"
                [editorOptions]="{ itemTemplate: 'budgetItem' }">
                <dxo-lookup
                  [displayExpr]="budgetDisplayExpr"
                  valueExpr="id"
                  [dataSource]="budgetItemStore">
                </dxo-lookup>
                <div *dxTemplate="let data of 'budgetItem'">
                  <p><b>Category:</b> {{data.category}} <br />
                    <b>Description:</b> {{data.description}}<br />
                    <b>Total:</b> {{data.cost | currency}}<br />
                    <b>Available:</b> {{data.available | currency}}<br />
                    <b>Left to spend:</b> {{data.leftToSpend | currency}}<br />
                  </p>
                </div>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-column>
              <dxi-column
                alignment="center"
                dataType="text"
                dataField="costJustification"
                caption="Cost Justification">
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-column>
              <dxi-column
                alignment="center"
                dataType="number"
                dataField="amountRequested"
                caption="Amount Requested"
                [format]="{
                type: 'currency',
                precision: 2
                }"
                [editorOptions]="{ format: '$ #,##0.##', min: 0 }">
                <dxi-validation-rule
                  type="async"
                  [validationCallback]="validateAmount"
                  message="Amount requested is greater than award amount!">
                </dxi-validation-rule>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-column>
              <dxo-summary>
                <dxi-total-item
                  [valueFormat]="{
                type: 'currency',
                precision: 2
                }"
                  column="amountRequested"
                  summaryType="sum">
                </dxi-total-item>
              </dxo-summary>
              <dxo-master-detail
                [autoExpandAll]="false"
                [enabled]="true"
                template="detail"></dxo-master-detail>
              <div *dxTemplate="let row of 'detail'">
                <app-reimbursement-files
                  *ngIf="reimbursementId"
                  [canEdit]="canEdit"
                  [applicationId]="application.id"
                  [awardId]="award.id"
                  [reimbursementId]="reimbursementId"
                  [invoiceId]="row.data.id">
                </app-reimbursement-files>
              </div>

            </dx-data-grid>
            <div class="balance">
              <p>Award Amount: <b>{{ balance?.awardAmount | currency:'USD'
                  }}</b></p>
              <p>Available: <b>{{ balance?.available | currency:'USD'
                  }}</b></p>
              <p>Requested: <b>{{balance?.submitted | currency:'USD'}}</b></p>
              <p>Pending (Includes other reimbursements): <b>{{balance?.pending
                  |
                  currency:'USD'}}</b></p>
            </div>
          </div>
        </dxi-item>
        <dxi-item title="Attachments">
          <div *dxTemplate>
            <app-reimbursement-files
              *ngIf="formdata.id"
              [canEdit]="canEdit"
              [applicationId]="application.id"
              [awardId]="award.id"
              [reimbursementId]="formdata.id"></app-reimbursement-files>
          </div>
        </dxi-item>
        <dxi-item title="Comments">
          <div *dxTemplate>
            <app-reimbursement-comments
              *ngIf="formdata.id"
              [applicationId]="application.id"
              [awardId]="award.id"
              [reimbursementId]="formdata.id">
            </app-reimbursement-comments>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </div>

    <br />
    <div
      style="display: flex; justify-content: right; padding-bottom: 10px;"
      *ngIf="canEdit && this.formdata.id != undefined">
      <div>
        <dx-button
          [disabled]="cannotSubmitReason != undefined"
          type="default" (onClick)="submitReimbursement()">Submit
          Reimbursement</dx-button>
        <p *ngIf="cannotSubmitReason"
          style="font-weight: bold; color: red;">{{cannotSubmitReason}}</p>
      </div>
    </div>
  </div>
</dx-popup>
