<form (submit)="this._onSave($event)">
  <dx-form
    [showColonAfterLabel]="true"
    [readOnly]="!isEditing || readonly || loading"
    [colCount]="2"
    validationGroup="reimbursement"
    [(formData)]="formdata"
  >
    <dxi-item
      dataField="date"
      editorType="dxDateBox"
      [editorOptions]="{ useMaskBehavior: true, openOnFieldClick: true }"
    >
      <dxi-validation-rule type="required" message="Date is required">
      </dxi-validation-rule>
    </dxi-item>
    <dxi-item
      [label]="{ text: 'Applicant (Agency Name)' }"
      dataField="agencyName"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      *ngIf="!isEditing"
      dataField="awardNumber"
      [label]="{ text: 'Grant Award Number' }"
      [editorOptions]="{ disabled: true }"
      [disabled]="true"
    ></dxi-item>
    <dxi-item
      *ngIf="!isEditing"
      dataField="reimbursementIdentification"
      [label]="{ text: 'Reimbursement Number' }"
      [editorOptions]="{ disabled: true }"
      [disabled]="true"
    ></dxi-item>
    <dxi-item
      *ngIf="!isEditing && formdata.checkNumber"
      dataField="checkNumber"
      [label]="{ text: 'Check Number' }"
      [editorOptions]="{ disabled: true }"
      [disabled]="true"
    ></dxi-item>
    <dxi-item
      dataField="vendorNumber"
      [colSpan]="1"
      [label]="{ text: 'LAGOV Vendor Number' }"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      caption="Authorized Agent"
      itemType="group"
      [colSpan]="2"
      [colCount]="2"
    >
      <dxi-item dataField="applicantFirstName" [label]="{ text: 'First Name' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item [label]="{ text: 'Last Name' }" dataField="applicantLastName">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        [label]="{ text: 'Phone Number' }"
        dataField="applicantPhoneNumber"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    </dxi-item>
    <dxi-item
      *ngIf="(balance?.availableToReconcile ?? 0) > 0"
      [label]="{ text: 'Reconciliation?' }"
      dataField="isReconciliation"
      type="boolean"
      editorType="dxSwitch"
      [disabled]="this.formdata.id ? true : false"
      [helpText]="
        !this.formdata.id
          ? 'Check this if you want to reconcile your advance payments!'
          : ''
      "
    >
    </dxi-item>
    <dxi-item itemType="empty"></dxi-item>
    <dxi-item itemType="empty"></dxi-item>
    <dxi-item itemType="group" [colSpan]="2" *ngIf="!readonly">
      <dxi-item>
        <div
          *dxTemplate
          style="display: flex; justify-content: right; gap: 5px"
        >
          <dx-button
            *ngIf="isEditing"
            [text]="formdata.id ? 'Save' : 'Create'"
            type="success"
            [useSubmitBehavior]="true"
          >
          </dx-button>
          <dx-button
            *ngIf="isEditing && advancedPaymentEligible && !formdata.id"
            text="Request Advance Payment"
            type="danger"
            (onClick)="_advPayment()"
          >
          </dx-button>
        </div>
      </dxi-item>
      <dxi-item
        *ngIf="!isEditing"
        name="Edit"
        itemType="button"
        [buttonOptions]="{
          text: 'Edit Details',
          onClick: editForm
        }"
      >
      </dxi-item>
      <dxi-item
        *ngIf="isEditing && this.formdata.id != undefined"
        name="Cancel"
        itemType="button"
        [buttonOptions]="{ text: 'Cancel', onClick: cancelEdit }"
      >
      </dxi-item>
    </dxi-item>
  </dx-form>
</form>
<app-adv-payment-reimbursements-popup
  *ngIf="award"
  [award]="award"
  (onAgree)="_onAdvPaymentSave($event)"
  [(visible)]="showAdvPaymentPopup"
></app-adv-payment-reimbursements-popup>
