<dx-data-grid [dataSource]="dataSource" style="padding: 5px">
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate class="data-grid-header">
        Assigned Evaluators
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>

  </dxo-toolbar>
  <dxo-editing [allowAdding]="true" mode="popup" [allowDeleting]="true">
    <dxo-popup title="Assign Evaluator" [width]="400" [height]="200">
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item
        [label]="{text: 'Evaluator'}"
        dataField="id" editorType="dxSelectBox"
        [editorOptions]="{dataSource: assignableStore, displayExpr: 'username', valueExpr: 'id'}">
      </dxi-item>
    </dxo-form>
  </dxo-editing>
  <dxi-column
    [allowEditing]="false"
    alignment="center"
    dataField="firstName"></dxi-column>
  <dxi-column
    [allowEditing]="false"
    alignment="center"
    dataField="lastName"></dxi-column>
  <dxi-column
    [allowEditing]="false"
    alignment="center"
    dataField="username"></dxi-column>
  <dxi-column
    [visible]="false"
    alignment="center"
    dataField="id"></dxi-column>
</dx-data-grid>
