export enum APPLICATION_STATUS {
  DENIED = -20,
  SENT_BACK = -10,
  CREATED = 10,
  SUBMITTED = 20,
  REVIEWED = 30,
  EVALUATED = 50,
  NOT_CONSIDERED = 51,
  AWAITING_SIGNATURE = 60,
  DECLINED_AWARD = 61,
  AWARDED = 70, // Signed
}
