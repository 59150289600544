import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  ReplaySubject,
  firstValueFrom,
  lastValueFrom,
} from 'rxjs';
import { ApplicationsService } from '../../shared/services';
import { DxFormComponent } from 'devextreme-angular';
import { notifyWrapper } from '../../shared/globals/notify-wrapper';
import { ISection } from '../grants/types/IGrants';

export interface IFormData extends Object {
  metadata?: {
    selectedSectionId?: number;
    completedSectionIds?: number[];
  };
}

@Injectable({ providedIn: 'root' })
export class PlaygroundService {
  sections: BehaviorSubject<ISection[]> = new BehaviorSubject([] as ISection[]);
  formData: BehaviorSubject<IFormData> = new BehaviorSubject({});
  form?: DxFormComponent;
  selectedSectionId: ReplaySubject<number | undefined> = new ReplaySubject(1);
  application_id?: number;
  application: BehaviorSubject<any | undefined> = new BehaviorSubject(
    undefined
  );

  lastSaved: BehaviorSubject<Date | undefined> = new BehaviorSubject<
    Date | undefined
  >(undefined);
  saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private applicationService: ApplicationsService) {
    // const grant = grants;
  }

  setFormData(formData: any) {
    this.formData.next(formData);
  }

  setSelectedSectionId(id: number) {
    this.selectedSectionId.next(id);
    this.formData.next({
      ...this.formData.value,
      metadata: { ...this.formData.value.metadata, selectedSectionId: id },
    });
    this.saveForm();
  }

  async loadForm(app_id: number) {
    this.application_id = app_id;
    const application = (await lastValueFrom(
      this.applicationService.getApplication(app_id) as any
    )) as any;

    this.application.next(application);

    this.formData.next({});
    this.sections.next([]);
    this.selectedSectionId.next(undefined);

    this.sections.next(application.grant.jsonData as ISection[]);

    if (application.formData) {
      this.formData.next(application.formData);
    }
    const fd = this.formData.getValue();
    if (fd.metadata && fd.metadata.selectedSectionId !== undefined) {
      this.setSelectedSectionId(
        application.formData.metadata.selectedSectionId
      );
    } else {
      this.selectedSectionId.next(this.sections.value[0].id);
    }
  }

  async saveForm(formData?: object) {
    if (this.readOnly.getValue()) {
      return;
    }
    if (this.application.value.isCompleted) {
      return;
    }

    this.saving.next(true);
    if (this.application_id) {
      if (formData) {
        await lastValueFrom(
          this.applicationService.patchApplicationForm(this.application_id, {
            formData: formData,
          })
        );
      } else {
        await lastValueFrom(
          this.applicationService.postApplicationForm(this.application_id, {
            formData: this.formData.value,
          })
        );
      }
      this.lastSaved.next(new Date());
      this.saving.next(false);
    }
  }

  async markSectionAsComplete(sectionId: number) {
    const validate = this.form?.instance.validate();

    const formData = this.formData.value;
    if (!formData.metadata) formData.metadata = {};
    if (!formData.metadata.completedSectionIds)
      formData.metadata.completedSectionIds = [];
    const completedSections = formData.metadata.completedSectionIds;
    const index = completedSections.findIndex((s) => s == sectionId);
    if (index == -1) {
      // Only validate if marking as complete.
      // JSON Form may change, and unmarking as complete could error
      if (validate?.isValid) {
        notifyWrapper('Marked section as complete!', 'success', 2000);
        completedSections.push(sectionId);
      } else {
        notifyWrapper(
          'Please fix all errors before marking as complete!',
          'error'
        );
      }
    } else {
      notifyWrapper('Unmarked section as complete!', 'warning', 2000);
      completedSections.splice(index, 1);
    }

    this.setFormData(formData);
    this.saveForm();
  }

  async isApplicationCompleted() {
    const app = await firstValueFrom(this.application);
    return (await firstValueFrom(this.application)).isCompleted;
  }

  isSectionCompleted(sectionId?: number) {
    if (sectionId === undefined) return false;
    const completedSectionIds =
      this.formData.value.metadata?.completedSectionIds;
    if (!completedSectionIds) return false;
    const element = completedSectionIds.find((id) => sectionId == id);
    if (element === undefined) return false;
    return true;
  }

  async isSelectedSectionCompleted() {
    const selectedSectionid = await firstValueFrom(this.selectedSectionId);
    return this.isSectionCompleted(selectedSectionid);
  }

  async getFile(fileId: string, fileName: string) {
    try {
      const blob = await lastValueFrom(this.applicationService.getFile(fileId));
      let url = window.URL.createObjectURL(blob);

      // Create an anchor element
      let anchor = document.createElement('a');
      anchor.href = url;

      // Specify the filename
      anchor.download = fileName;

      // Simulate a click on the anchor element to trigger the download
      anchor.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    } catch (error) {
      notifyWrapper('Error downloading file!', 'error');
    }
  }

  async deleteFile(fileId: string) {
    await lastValueFrom(this.applicationService.deleteFile(fileId));
  }

  areAllSectionsComplete() {
    const sections = this.sections.value;
    const formData = this.formData.value;
    const completedSections = formData.metadata?.completedSectionIds;

    if (!completedSections) return false;

    let allComplete = true;
    for (const section of sections) {
      const isInCompleted = completedSections?.findIndex(
        (id) => id == section.id
      );
      if (isInCompleted == -1) allComplete = false;
    }
    return allComplete;
  }

  async submitApplication() {
    if (!this.application_id) return;
    try {
      await lastValueFrom(
        this.applicationService.submitApplication(this.application_id)
      );
      this.loadForm(this.application_id);
    } catch (e: any) {
      notifyWrapper(e.error.message, 'error');
    }
  }

  formatDateTime(date: Date | string): string {
    let currentDate: Date;

    if (typeof date === 'string') {
      currentDate = new Date(date);
    } else {
      currentDate = date;
    }

    const year = currentDate.getFullYear().toString().slice(-2);
    const month = this.padZero(currentDate.getMonth() + 1);
    const day = this.padZero(currentDate.getDate());

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = this.padZero(minutes);
    const formattedSeconds = this.padZero(seconds);

    const formattedDate = `${month}/${day}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${amPM}`;
    return formattedDate;
  }

  private padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  setReadOnly = (value: boolean) => {
    this.readOnly.next(value);
  };
}
