
<div
  style="display: flex; align-items: center; gap: 5px; color: gray">
  <b>Last Saved: </b>
  <span
    *ngIf="!loading && lastSaved">{{pgService.formatDateTime(lastSaved)}}</span>
  <dx-load-indicator [visible]="loading" height="15px" width="15px">
  </dx-load-indicator>
</div>
<br />
