import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxFileUploaderModule,
  DxFormComponent,
  DxFormModule,
} from 'devextreme-angular';
import { ApplicationsService } from '../../../../shared/services';
import { lastValueFrom } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { notifyWrapper } from '../../../../shared/globals/notify-wrapper';

@Component({
  selector: 'app-additional-files',
  standalone: true,
  imports: [DxFormModule, DxFileUploaderModule, DxDataGridModule],
  templateUrl: './additional-files.component.html',
  styleUrl: './additional-files.component.scss',
})
export class AdditionalFilesComponent {
  constructor(private appService: ApplicationsService) {}

  @ViewChild(DxDataGridComponent)
  dataGrid?: DxDataGridComponent;

  @ViewChild(DxFormComponent)
  form?: DxFormComponent;

  @Input({ required: true })
  appId!: number;

  documentStore: CustomStore = new CustomStore({
    key: 'hashedName',
    load: async () => await lastValueFrom(this.appService.getFiles(this.appId)),
    remove: async (key: any) => {
      await lastValueFrom(this.appService.deleteFile(key));
    },
  });

  @Output()
  onClickDownload: EventEmitter<any> = new EventEmitter();

  _onClickDownload = ($event: any) => {
    console.log('onClickDownload', $event);
    this.onClickDownload.emit($event);
  };

  formData: any = { files: [], tag: undefined };

  get canUpload() {
    if (this.formData && this.formData.files.length > 0) return true;
    return false;
  }

  async onFormSubmit($event: SubmitEvent) {
    $event.preventDefault();

    console.log(await lastValueFrom(this.appService.getFiles(this.appId)));

    try {
      await lastValueFrom(
        this.appService.uploadFile(this.appId, 'additional', {
          files: this.formData.files,
          tag: this.formData.tag,
        })
      );

      this.formData = {};
      await this.dataGrid?.instance.refresh();
    } catch (error) {
      notifyWrapper('Error uploading file', 'error');
      console.log(error);
    }
  }

  canDelete($event: any) {
    if ($event.row.data.key == 'additional') {
      return true;
    }
    return false;
  }
}
