import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IGrant } from 'src/app/pages/grants/types/IGrants';
import { catchError } from 'rxjs';
import { handleHttpError } from '../http.error-handler';
import { IGrantCheck } from '../types/IGrantCheck';

@Injectable({
  providedIn: 'root',
})
export class GrantsService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/grants';

  constructor(private _httpClient: HttpClient) {}

  getUserGrants() {
    return this._httpClient
      .get<IGrant[]>(`${this.getBaseURL()}/userGrants`)
      .pipe(catchError(handleHttpError<IGrant[]>));
  }

  getAllGrants() {
    return this._httpClient
      .get<IGrant[]>(`${this.getBaseURL()}`)
      .pipe(catchError(handleHttpError<IGrant[]>));
  }

  getGrant(grantId: number) {
    return this._httpClient
      .get<IGrant>(`${this.getBaseURL()}/${grantId}`)
      .pipe(catchError(handleHttpError<IGrant>));
  }

  getGrantChecks(grantId: number) {
    return this._httpClient
      .get<IGrantCheck[]>(`${this.getBaseURL()}/${grantId}/checks`)
      .pipe(catchError(handleHttpError<IGrantCheck[]>));
  }

  apply(grantId: number) {
    return this._httpClient.post(`${this.getBaseURL()}/${grantId}/apply`, {});
  }

  unApply(grantId: number) {
    return this._httpClient.delete(`${this.getBaseURL()}/${grantId}/apply`);
  }

  createGrant(grant: Partial<IGrant>) {
    return this._httpClient
      .post<IGrant>(`${this.getBaseURL()}`, grant)
      .pipe(catchError(handleHttpError<IGrant>));
  }

  updateGrant(grantId: number, grant: Partial<IGrant>) {
    return this._httpClient
      .patch(`${this.getBaseURL()}/${grantId}/`, grant)
      .pipe(catchError(handleHttpError<IGrant>));
  }

  deleteGrant(grantId: number) {
    return this._httpClient
      .delete<IGrant>(`${this.getBaseURL()}/${grantId}`)
      .pipe(catchError(handleHttpError<IGrant>));
  }

  getBaseURL() {
    return this.baseUrl + this.path;
  }
}
