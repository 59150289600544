<dx-responsive-box
  singleColumnScreen="xs sm"
  *ngIf="selectedSection && selectedSectionId != 9999"
>
  <dxi-row></dxi-row>
  <dxi-col></dxi-col>
  <dxi-col></dxi-col>
  <dxi-item>
    <dxi-location [row]="0" [col]="0" [colspan]="1"></dxi-location>
    <div *dxTemplate style="padding: 10px">
      <div>
        <h5>{{ selectedSection.heading }}</h5>
        <div [innerHTML]="selectedSection.body"></div>
      </div>
    </div>
  </dxi-item>
  <dxi-item>
    <dxi-location [row]="0" [col]="1" [colspan]="1"></dxi-location>
    <div *dxTemplate style="padding: 10px">
      <div>
        <h5>Questions:</h5>
        <grant-question-form
          (formChange)="onFormInstanceChange($event)"
          (onFieldDataChange)="onFieldDataChange($event)"
          [formData]="formData"
          [readOnly]="readOnly"
          [questions]="selectedSection.questions"
        >
        </grant-question-form>
      </div>
    </div>
  </dxi-item>
</dx-responsive-box>

<!-- Review Section -->
<div
  *ngIf="selectedSectionId == 9999"
  style="
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  "
>
  <div style="width: 80%; max-width: 1200px">
    <div style="text-align: center">
      <dx-button
        icon="download"
        (onClick)="printTOPDF()"
        text="Download Application"
      ></dx-button>
    </div>
    <div
      *ngIf="
        application && application.statusValue >= APPLICATION_STATUS.AWARDED
      "
    >
      <h4 style="margin: 0px">Awards</h4>
      <app-application-awards
        [application]="application"
      ></app-application-awards>
    </div>
    <div
      *ngIf="
        application && application.statusValue >= APPLICATION_STATUS.AWARDED
      "
    >
      <app-additional-files
        (onClickDownload)="onClickDownload($event)"
        [appId]="this.application.id"
      ></app-additional-files>
    </div>

    <h4 style="margin: 0px">Review:</h4>
    <div
      *ngIf="application?.statusValue === APPLICATION_STATUS.DENIED"
      style="text-align: center"
    >
      <h4>Application is not allowed to be resubmitted!</h4>
      <br />
      Reason: {{ application?.reasonFor }}
    </div>
    <div
      *ngIf="application?.statusValue === APPLICATION_STATUS.SENT_BACK"
      style="text-align: center"
    >
      <h4 style="margin: 0px">
        Application was sent back!
        <br />
        @if(this.canResubmit){ You have until
        <b>{{ application?.resubmitDeadline | date : "medium" }}</b>
        to make recommended revivisions and resubmit! } @else { Your deadline
        ({{ application?.resubmitDeadline | date : "medium" }}) to resubmit has
        passed! }
        <br />
        Reason:
      </h4>
      <p>{{ application?.reasonFor }}</p>
    </div>

    <grant-question-form
      [formData]="formData"
      [readOnly]="true"
      [questions]="allQuestions"
    >
    </grant-question-form>
    <div>
      @if(application?.statusValue == APPLICATION_STATUS.SENT_BACK ||
      application?.statusValue == APPLICATION_STATUS.CREATED){
      <dx-button
        text="Submit"
        (onClick)="onSubmit()"
        *ngIf="!application?.isCompleted"
      ></dx-button>
      }
    </div>
    <div *ngIf="application?.isCompleted">
      <p>
        Date Submitted:
        {{ pgService.formatDateTime(application?.isCompletedDate ?? "") }}
      </p>
    </div>
  </div>
</div>

<div style="display: flex; justify-content: right; gap: 5px">
  <application-action-bar></application-action-bar>
</div>
