<div class="centered-title">
  Are you interested in this grant opportunity? Please check the below criteria
  to see if you can apply.
</div>
<br />

<div class="title">Eligibility:</div>

<div class="subtitle">
  The following entities seeking funding to improve school safety programs are
  eligible to apply:
</div>

<div class="subtitle">- Public Schools</div>
<div class="subtitle">- Charter Schools</div>
<div class="subtitle">- Private Schools</div>
<div class="subtitle">- Eligible NGO’s</div>

<div class="subtitle">
  If you fall under one of the above categories, you are eligible to apply.
  <!-- Click on the icon below to read the full Notice of Funding Announcement
  (NOFA). -->
</div>

<div class="subtitle">
  The Louisiana School and Nonprofit Security Grant
  Program (LSNSGP) is a -competitive, State-funded grant program designed for
  local school systems and eligible non-profits located within the State of
  Louisiana. The main purpose of the LSNSGP grant is to provide funding for
  school safety and security-related matters of other vulnerable sites as
  described under SB 207. The grant funds are to be applied toward the cost of
  school safety or other site safety-related projects and services. This grant
  program is part of a comprehensive set of measures authorized by the Louisiana
  Legislation to help strengthen Louisiana communities against potential acts of
  violence or other extremist attacks.
</div>

<div class="subtitle">
  If you are eligible to apply and have projects or school safety programs in
  need of funding, use the below checklist to get started!
</div>
<br />

<div class="bold-subtitle">
  Online Application From and Support Documentation
</div>

<div class="subtitle">
  Each applicant must submit a complete application. Applications without the
  required information will be denied and/or returned for additional
  information.
</div>

<div class="subtitle">- Applicant Information (REQUIRED).</div>
<div class="subtitle">
  - Background Information – not more than 2 pages (REQUIRED).
</div>
<div class="subtitle">
  - Detailed Project Description/Risk – not more than 2 pages (REQUIRED).
</div>
<div class="subtitle">- Milestones – not more than 1 page (REQUIRED).</div>
<div class="subtitle">
  - Project Management and Budget – not more than 2 pages (REQUIRED).
</div>
<div class="subtitle">- Impact – not more than 1 page (REQUIRED).</div>
<div class="subtitle">
  - Proof of incidents or problems to support application
  (Recommended/optional).
</div>
<div class="subtitle">
  - Completed Threat, Hazard, Risk and/or Vulnerability Assessment (Optional for
  Schools, REQUIRED for Non-profits).
</div>
<div class="subtitle">
  - Other supporting documentation/Letters of support or commitment (Optional).
</div>
<div>
  <a href="assets/guidance.docx">Download Guidance</a>
</div>
