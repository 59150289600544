import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxPopupModule, DxTextAreaModule } from 'devextreme-angular';
import { SendBackPopupService } from './send-back-popup.service';
import { Subscription } from 'rxjs';
import { ChangeEvent } from 'devextreme/ui/text_area_types';
import { notifyWrapper } from '../../../shared/globals/notify-wrapper';

@Component({
  standalone: true,
  imports: [DxPopupModule, CommonModule, DxTextAreaModule],
  selector: 'app-send-back-popup',
  templateUrl: 'send-back-popup.component.html',
})
export class SendBackPopupComponent implements OnInit, OnDestroy {
  title: string = 'Confirm';
  message: string = '';
  cancelBtnType: string = 'default';
  confirmBtnType: string = 'default';

  reason: string = '';

  show: boolean = false;

  constructor(private popupService: SendBackPopupService) {}

  subsciptions = new Subscription();
  ngOnInit(): void {
    this.subsciptions.add(
      this.popupService.popupState.subscribe((state) => {
        if (state.message) this.message = state.message;
        if (state.title) this.title = state.title;
        if (state.cancelType) this.cancelBtnType = state.cancelType;
        if (state.confirmType) this.confirmBtnType = state.confirmType;
        this.show = state.isVisible;
      })
    );
  }

  ngOnDestroy(): void {
    this.subsciptions.unsubscribe();
  }

  confirmAction = () => {
    if (!this.reason) {
      notifyWrapper('Please provide a reason', 'error');
    } else {
      this.popupService.closePopup(true, this.reason);
    }
  };
  denyAction = () => {
    this.popupService.closePopup(false);
  };

  onPopupClose() {
    this.popupService.closePopup(false);
  }
}
