import { Injectable, Injector } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { AuthService } from './auth.service';
import { from, lastValueFrom, tap } from 'rxjs';
import { Router } from '@angular/router';
import { notifyWrapper } from '../globals/notify-wrapper';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // convert promise to observable using 'from' operator
    // Workaround to get an async interceptor
    return from(this.handle(req, next));
  }

  async handle(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    const routerService = this.injector.get(Router);

    // So if auth headers are passed they won't be overridden.
    // Or if AUTH_ROUTE header is set to 0 (we ignore authentication)
    if (
      req.headers.get('Authorization') ||
      req.headers.get('AUTH_ROUTE') == '0'
    ) {
      return lastValueFrom(next.handle(req));
    }

    // Makes sure user is logged in before making request.
    // Will auto grab new refresh token if needed.
    const loggedInStatus = await authService.loggedInStatus();

    if (loggedInStatus.isLoggedIn) {
      const tokenizedReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'bearer ' + authService.getAccessToken()
        ),
      });
      return lastValueFrom(
        next.handle(tokenizedReq).pipe(
          tap({
            error: (err: any) => {
              if (err instanceof HttpErrorResponse) {
                // On 403, tell user they are not authorized to view that.
                if (err.status == 403) {
                  notifyWrapper('Not authorized!', 'error');
                }

                // On 401, redirect to login
                if (err.status === 401) {
                  notifyWrapper('Not authenticated!', 'error');

                  authService.clearAuthStorage();
                  routerService.navigate(['/auth/login']);
                }
              }
            },
          })
        )
      );
    } else {
      // Use should be redirected to login page here, since there token must be expired
      routerService.navigate(['/auth/login']);
      return lastValueFrom(next.handle(req));
    }
  }
}
