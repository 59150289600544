import { Observable, throwError } from 'rxjs';

// A utility function to handle errors and extract the error message
export function handleHttpError<T>(error: any): Observable<T> {
  let errorMessage = 'An error occurred';
  if (error.error && error.error.message) {
    errorMessage = error.error.message;
  } else if (error.message) {
    errorMessage = error.message;
  }
  console.error(errorMessage);
  return throwError(() => new Error(errorMessage)) as Observable<T>;
}
