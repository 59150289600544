import { Component, Input, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
} from 'devextreme-angular';
import {
  ReimbursementInterface,
  AppReimbursementsService,
} from '../../../services/application/app-reimbursement.service';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { ReimbursementsPopupComponent } from '../reimbursements-popup/reimbursements-popup.component';
import { CommonModule } from '@angular/common';
import { AppAwardInterface } from '../../../services/application/awards.service';
import { IApplication } from '../../../types/application.interface';

@Component({
  selector: 'app-reimbursements',
  standalone: true,
  imports: [
    DxDataGridModule,
    ReimbursementsPopupComponent,
    CommonModule,
    DxButtonModule,
  ],
  templateUrl: './reimbursements.component.html',
  styleUrl: './reimbursements.component.scss',
})
export class ReimbursementsComponent {
  @ViewChild(DxDataGridComponent)
  dataGrid?: DxDataGridComponent;

  @Input({ required: true })
  award!: AppAwardInterface;

  @Input({ required: true })
  awardId!: number;

  @Input({ required: true })
  application!: IApplication;

  riStore = new CustomStore<ReimbursementInterface>({
    key: 'id',
    load: async () =>
      await lastValueFrom(
        this.riService.getAllReimbursements(this.application.id, this.awardId)
      ),
    update: async (key, values) =>
      await lastValueFrom(
        this.riService.updateReimbursement(
          this.application.id,
          this.awardId,
          key,
          values
        )
      ),
    insert: async (values) =>
      await lastValueFrom(
        this.riService.createReimbursement(
          this.application.id,
          this.awardId,
          values
        )
      ),
  });

  constructor(private riService: AppReimbursementsService) {}

  getReType(reimbursement: ReimbursementInterface) {
    return reimbursement.isReconciliation
      ? 'Reconciliation'
      : reimbursement.advancedPayment
      ? 'Advance'
      : 'Reimbursement';
  }

  get popupVisible() {
    return this._popupVisible;
  }

  selectedReimbursementId?: number | null;
  private _popupVisible = false;

  showPopup(reimbursementId?: number) {
    this.selectedReimbursementId = reimbursementId;
    this._popupVisible = true;
  }

  hidePopup() {
    this.dataGrid?.instance.refresh();
    this.selectedReimbursementId = undefined;
    this._popupVisible = false;
  }

  onReimbursementViewClick = (e: any) => {
    if (e.row && e.row.data) {
      this.showPopup(e.row.data.id);
    }
  };

  createReimbursement = () => {
    this.selectedReimbursementId = null;
    this.showPopup();
  };
}
