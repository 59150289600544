import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { IApplication } from 'src/app/shared/types/application.interface';
import { ApplicationsService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { APPLICATION_STATUS } from '../../../../shared/enums/ApplicationStatus.enum';
import 'devextreme/integration/jquery';
import { EvaluationStatus } from '../../../../shared/enums/EvaluationStatus.enum';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { decompress } from 'compress-json';

@Component({
  selector: 'app-admin-application-data-grid',
  templateUrl: './admin-application-data-grid.component.html',
  styleUrl: './admin-application-data-grid.component.scss',
})
export class AdminApplicationDataGridComponent implements OnInit {
  applicationsStore?: CustomStore<IApplication>;
  applicationsDataSource!: DataSource<IApplication>;
  dataGridColumns: DxDataGridTypes.Column[] = [];

  constructor(
    private readonly _applicationsService: ApplicationsService,
    private readonly _router: Router
  ) {}

  async ngOnInit() {
    this.applicationsStore = new CustomStore<IApplication>({
      key: 'id',
      load: async () => {
        const apps = await lastValueFrom(
          this._applicationsService.getAllApplications()
        );

        return decompress(apps as any);
      },
    });

    this.applicationsDataSource = new DataSource<IApplication, number>({
      store: this.applicationsStore,
    });
  }

  onViewApplicationResults = async (rowData: any) => {
    const applicationId = rowData.row.data.id;
    this._router.navigate([`/application/review`, applicationId]);
  };

  onCellPrepared(e: any) {
    if (e.rowType === 'data' && e.column.type === 'buttons') {
      var $links = e.cellElement.find('.reviewBtn');
      if (e.row.data.statusValue < APPLICATION_STATUS.SUBMITTED)
        $links.filter('.reviewBtn').remove();
    }
  }

  calculateTotalEvaluationScore = (e: IApplication) => {
    if (e.evaluations && e.evaluations.length > 0) {
      let total = 0;
      let count = e.evaluations.length;
      for (const evaluation of e.evaluations) {
        if (evaluation.statusValue >= EvaluationStatus.EVALUATED) {
          for (const score of evaluation.scored) {
            total += score.score;
          }
        }
      }

      return parseFloat((total / count).toFixed(2));
    }
    return 0;
  };
  calculateTotalBonusScore = (e: IApplication) => {
    let totalBonus = 0;
    if (e.bonusObtained && e.bonusObtained.length > 0) {
      for (const bonus of e.bonusObtained) {
        if (bonus.BonusOpportunity) {
          totalBonus += bonus.BonusOpportunity?.bonusValue;
        }
      }
    }

    return parseFloat(totalBonus.toFixed(2));
  };

  calculateTotalScore = (e: IApplication) => {
    let total = this.calculateTotalEvaluationScore(e);
    let totalBonus = this.calculateTotalBonusScore(e);
    return parseFloat((total + totalBonus).toFixed(2));
  };

  calculateTotalBudget = (e: IApplication) => {
    try {
      if (e.formData['S5bBudget'] === undefined) return 0;
      if (!e.formData && Array.isArray(e.formData['S5bBudget'])) return 0;
      let total = 0;
      for (const b of e.formData['S5bBudget']) {
        if (typeof b.value == 'number') total += b.value;
      }
      return total;
    } catch (error) {
      return 0;
    }
  };

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options) {
        options.excelCell.font = { name: 'Arial', size: 12 };
        options.excelCell.alignment = { horizontal: 'left' };
      },
    }).then(function () {
      const now = new Date();
      workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Reimbursements-${now.toLocaleDateString()}-${now.toLocaleTimeString()}.xlsx`
        );
      });
    });
  }
}
