import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services';

/**
 * If a user is not logged in, this guard will prevent them from visiting this page and redirect to login.
 */
export const AuthGuard: CanActivateFn = async (e, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const status = await authService.loggedInStatus();

  if (status.isLoggedIn) {
    return true;
  } else {
    return router.parseUrl('/auth/login?returnURL=' + state?.url);
  }
};

/**
 * If a user is logged in, this guard will prevent them from visiting this page and redirect home.
 * ie. Cant view login or signup page if logged in.
 */
export const LoggedInGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const status = await authService.loggedInStatus();

  if (status.isLoggedIn) {
    return router.parseUrl('/');
  } else {
    return true;
  }
};

/**
 * If a user is logged in, this will log them out and then allow access to the page
 * ie. Cant view login or signup page if logged in.
 */
export const LogOutGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const status = await authService.loggedInStatus();

  if (status.isLoggedIn) {
    // Log the user out without redirecting
    authService.logout(false);
  }
  return true;
};
