import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTextAreaModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { AdminDataGridComponent } from './components/grant-datagrid/admin-data-grid.component';
import { AdminApplicationDataGridComponent } from './components/admin-application-data-grid/admin-application-data-grid.component';
import { AdminStaffDataGridComponent } from './components/admin-staff-data-grid/admin-staff-data-grid.component';
import { AdminInviteUserPopupComponent } from './components/admin-invite-user-popup/invite-user.component';
import { ReviewComponent } from './review/review.component';
import {
  ViewApplicationComponent,
  ViewApplicationModule,
} from '../../shared/components/view-application/view-application.component';
import { PlaygroundModule } from '../playground/playground.component';
import { DenyAppFormComponent } from './deny-application-form/deny-application-form.component';
import { ApplicationEvalDataGridComponent } from './components/eval-data-grid/application-eval-datagrid.component';
import { ApplicationEvaluationComponent } from './evaluate/eval/app-eval.component';
import { EvaluateComponent } from './evaluate/evaluate.component';
import { GrantPageComponent } from './grant/grant.component';
import { GrantEvalGroupComponent } from './grant/eval-groups/eval-groups.component';
import { GrantAssignedEvaluatorsComponent } from './grant/eval-groups/assigned-evaluators/assigned-evaluators.component';
import { GrantAppChecksGroupComponent } from './grant/app-checks/app-checks.component';
import { ApplicationReadHistoryModule } from '../../shared/components/application-read-history/application-read-history.module';
import { GrantEvalCriteriaComponent } from './grant/eval-criteria/eval-criteria.component';
import { NavHeaderModule } from '../../shared/components/nav-header/nav-header.module';
import { ApplicationStatusHistoryModule } from '../../shared/components/application-status-history/application-status-history.module';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxPopupModule,
    DxFormModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxAccordionModule,
    DxTabPanelModule,
    ViewApplicationModule,
    DxSwitchModule,
    ApplicationReadHistoryModule,
    DxHtmlEditorModule,
    NavHeaderModule,
    ApplicationStatusHistoryModule,
  ],
  declarations: [
    AdminComponent,
    AdminDataGridComponent,
    AdminApplicationDataGridComponent,
    AdminStaffDataGridComponent,
    AdminInviteUserPopupComponent,
    ReviewComponent,
    DenyAppFormComponent,
    ApplicationEvalDataGridComponent,
    ApplicationEvaluationComponent,
    EvaluateComponent,
    GrantPageComponent,
    GrantEvalGroupComponent,
    GrantAssignedEvaluatorsComponent,
    GrantAppChecksGroupComponent,
    GrantEvalCriteriaComponent,
  ],
  exports: [AdminComponent],
})
export class AdminModule {}
