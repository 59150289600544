import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { handleHttpError } from '../http.error-handler';
import { AppAwardsService } from './application/awards.service';
import {
  ReimbursementInterface,
  ReimbursementInvoice,
} from './application/app-reimbursement.service';
import { ReviewDetailResponse } from './application/review.service';
import { ReimbursementStatus } from '../globals/enums/ReimbursementStatus.enum';
import { IFile } from '../types/IFile';
import { REIMBURSEMENT_ROLE } from '../enums/ReimbursementRole';

@Injectable({
  providedIn: 'root',
})
export class ReimbursementsService {
  baseUrl: string = environment.baseApiUrl + '/reimbursements';

  constructor(
    private _httpClient: HttpClient,
    private awardService: AppAwardsService
  ) {}

  getAllReimbursements(showAll: boolean, awardId?: number) {
    let params = new HttpParams().set('showAll', showAll.toString());

    if (awardId !== undefined) {
      params = params.set('awardId', awardId.toString());
    }

    return this._httpClient
      .get<ReimbursementInterface[]>(`${this.baseUrl}`, { params: params })
      .pipe(catchError(handleHttpError<ReimbursementInterface[]>));
  }

  getReimbursement(id: number) {
    return this._httpClient
      .get<ReimbursementInterface>(`${this.baseUrl}/${id}`)
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  getAppInfo(reimbursementId: number) {
    console.log('here');
    return this._httpClient.get<ReviewDetailResponse>(
      `${this.baseUrl}/${reimbursementId}/application`
    );
  }

  getChecks(reimbursementId: number) {
    return this._httpClient
      .get<ReimbursementCheckInterface[]>(
        `${this.baseUrl}/${reimbursementId}/checks`
      )
      .pipe(catchError(handleHttpError<ReimbursementCheckInterface[]>));
  }

  patchCheck(reimbursementId: number, checkId: number, isChecked: boolean) {
    return this._httpClient
      .patch<ReimbursementCheckInterface>(
        `${this.baseUrl}/${reimbursementId}/checks/${checkId}`,
        { isChecked }
      )
      .pipe(catchError(handleHttpError<ReimbursementCheckInterface>));
  }

  patchPO(reimbursementId: number, poNumber: string) {
    return this._httpClient
      .patch<ReimbursementInterface>(
        `${this.baseUrl}/${reimbursementId}/updatePO`,
        { purchaseOrderNumber: poNumber }
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  markAsReviewed(reimbursementId: number) {
    return this._httpClient
      .post<ReimbursementInterface>(
        `${this.baseUrl}/${reimbursementId}/reviewed`,
        {}
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  markAsApproved(reimbursementId: number) {
    return this._httpClient
      .post<ReimbursementInterface>(
        `${this.baseUrl}/${reimbursementId}/approved`,
        {}
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  markAsApprovedFunding(reimbursementId: number) {
    return this._httpClient
      .post<ReimbursementInterface>(
        `${this.baseUrl}/${reimbursementId}/approveFunding`,
        {}
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  generateInvoice(reimbursementId: number) {
    return this._httpClient
      .post<GenerateInvoiceResponse>(
        `${this.baseUrl}/${reimbursementId}/generateInvoice`,
        {}
      )
      .pipe(catchError(handleHttpError<GenerateInvoiceResponse>));
  }

  getInvoiceStatus(reimbursementId: number) {
    return this._httpClient
      .get<GenerateInvoiceResponse>(
        `${this.baseUrl}/${reimbursementId}/getInvoiceStatus`
      )
      .pipe(catchError(handleHttpError<GenerateInvoiceResponse>));
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  downloadInvoice(reimbursementId: number) {
    return this._httpClient
      .get<Blob>(`${this.baseUrl}/${reimbursementId}/getInvoice`, {
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(handleHttpError<Blob>));
  }

  markAsQAQC(reimbursementId: number) {
    return this._httpClient
      .post<ReimbursementInterface>(
        `${this.baseUrl}/${reimbursementId}/approveQAQC`,
        {}
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  issueCheck(reimbursementId: number, checkNumber: string) {
    return this._httpClient
      .post<ReimbursementInterface>(
        `${this.baseUrl}/${reimbursementId}/issueCheck/${checkNumber}`,
        {}
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  sendBack(
    reimbursementId: number,
    status: ReimbursementStatus,
    reason?: string
  ) {
    return this._httpClient
      .post<ReimbursementInterface>(
        `${this.baseUrl}/${reimbursementId}/sendBack/${status}`,
        { reason }
      )
      .pipe(catchError(handleHttpError<ReimbursementInterface>));
  }

  updateInvoice(
    invoiceId: number,
    invoice: Pick<ReimbursementInvoice, 'amountApproved'>
  ) {
    return this._httpClient
      .patch<ReimbursementInvoice>(
        `${this.baseUrl}/invoice/${invoiceId}`,
        invoice
      )
      .pipe(catchError(handleHttpError<ReimbursementInvoice>));
  }

  getComments(reimbursementId: number) {
    return this._httpClient
      .get<ReimbursementCommentInterface>(
        `${this.baseUrl}/${reimbursementId}/comments`
      )
      .pipe(catchError(handleHttpError<ReimbursementCommentInterface>));
  }

  addComment(reimbursementId: number, comment: ReimbursementCommentInterface) {
    return this._httpClient
      .post<ReimbursementCommentInterface>(
        `${this.baseUrl}/${reimbursementId}/comments`,
        comment
      )
      .pipe(catchError(handleHttpError<ReimbursementCommentInterface>));
  }

  editComment(
    reimbursementId: number,
    commentId: number,
    comment: Partial<ReimbursementCommentInterface>
  ) {
    return this._httpClient
      .patch<ReimbursementCommentInterface>(
        `${this.baseUrl}/${reimbursementId}/comments/${commentId}`,
        comment
      )
      .pipe(catchError(handleHttpError<ReimbursementCommentInterface>));
  }

  deleteComment(reimbursementId: number, commentId: number) {
    return this._httpClient
      .delete<ReimbursementCommentInterface>(
        `${this.baseUrl}/${reimbursementId}/comments/${commentId}`
      )
      .pipe(catchError(handleHttpError<ReimbursementCommentInterface>));
  }

  getReimbursementFiles(reimbursementId: number) {
    return this._httpClient.get<IFile[]>(
      `${this.baseUrl}/files?reimbursementId=${reimbursementId}`
    );
  }
  getInvoiceFiles(invoiceId: number) {
    return this._httpClient.get<IFile[]>(
      `${this.baseUrl}/files?invoiceId=${invoiceId}`
    );
  }

  getStatusHistory(reimbursementId: number) {
    return this._httpClient
      .get<ReimbursementStatusHistoryInterface[]>(
        `${this.baseUrl}/${reimbursementId}/statusHistory`
      )
      .pipe(catchError(handleHttpError<ReimbursementStatusHistoryInterface[]>));
  }
}

export interface ReimbursementRoleInterface {
  role: REIMBURSEMENT_ROLE;
  id: number;
  userId: number;
}
export interface ReimbursementCheckInterface {
  id: number;
  name: string;
  required: boolean;
  grantId: number;
  isChecked: boolean;
}

export interface ReimbursementCommentInterface {
  id: number;
  text: string;
  internalOnly: boolean;
  reimbursementId: number;
  userId: number;
  archived: boolean;
  updated: string;
  created: string;
  username: string;
}

export interface ReimbursementStatusHistoryInterface {
  id: number;
  status: ReimbursementStatus;
  reason?: string | null;
  reimbursementId: number;
  userId: number;
  username: string;
  created: Date;
}

export interface GenerateInvoiceResponse {
  reimbursementId: number;
  signedStatus: string;
  documentId: number;
  signingURL: string;
  signedDate: Date;
  sentGOHSEPInvoicesEmail: boolean;
}
