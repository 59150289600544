<dx-data-grid [dataSource]="grantGroupDataSource" style="padding: 5px">
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate class="data-grid-header">
        Groups
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>

  </dxo-toolbar>
  <dxo-editing [allowAdding]="true" [allowUpdating]="true"
    [allowDeleting]="true"></dxo-editing>
  <dxi-column
    alignment="center"
    dataType="string"
    dataField="name">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column
    alignment="center"
    dataType="number"
    dataField="required_evaluators_per_application"
    caption="Evaluators Per Application">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-validation-rule [min]="0" type="range"></dxi-validation-rule>
  </dxi-column>

  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
  <div *dxTemplate="let group of 'detail'">
    <app-assigned-evaluators
      [grantId]="grantId"
      [groupId]="group.data.id"></app-assigned-evaluators>
  </div>
</dx-data-grid>
