<dx-popup
  (onHiding)="onPopupClose()"
  [title]="title"
  [visible]="show"
  maxWidth="400px"
  titleTemplate="<div style='font-size: 16px;'><b>{{title}}</b></div>"
  maxHeight="300px">
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="{text: 'Cancel', cancelBtnType, onClick: denyAction}">
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="{text: 'Confirm', type: confirmBtnType, onClick: confirmAction}">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" style="text-align: center;">
    <dx-text-area
      [(value)]="reason"
      height="150px"
      label="Provide a Reason:"></dx-text-area>
  </div>
</dx-popup>
