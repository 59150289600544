import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DxFormComponent, DxTemplateDirective } from 'devextreme-angular';
import $ from 'jquery';
import DevExpress from 'devextreme';
import { PlaygroundService } from '../playground.service';
import { Subscription, firstValueFrom, lastValueFrom } from 'rxjs';
import _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { ApplicationsService, AuthService } from '../../../shared/services';
import dxFileUploader from 'devextreme/ui/file_uploader';
import FileUploader from 'devextreme/ui/file_uploader';
import Datagrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { IQuestion } from '../../grants/types/IGrants';

@Component({
  selector: 'grant-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent implements AfterViewInit {
  @ViewChild(DxFormComponent)
  form?: DxFormComponent;

  @Output()
  formChange: EventEmitter<DxFormComponent> = new EventEmitter();

  @Input()
  formData: any = {};

  @Input()
  questions: IQuestion[] = [];

  @Input()
  readOnly: boolean = false;

  @Output()
  onFieldDataChange: EventEmitter<(e: any) => void> = new EventEmitter();

  /**
   * Headers used to upload files
   */
  uploadHeaders = {};

  constructor(
    private pgService: PlaygroundService,
    private authService: AuthService,
    private applicationService: ApplicationsService
  ) {}

  async ngAfterViewInit() {
    await this.initForm();
  }

  onFieldDataChanged = async (e: any) => {
    this.onFieldDataChange.emit(e);
  };

  onOptionChange = (e: any) => {
    if (e.name == 'formData') {
      this.handleFormLifecycle();
    }
  };

  handleFormLifecycle() {
    for (const question of this.questions) {
      if (question.characterCount) {
        $(`#${question.datafield}cc`).html(
          this.calculateCharacterCount(
            question.datafield,
            question.characterCount.max
          )
        );
      }

      // Handle if questions (whether to show the question or not.)
      if (question.if) {
        const show = this.handleIf(question.if);
        const item = this.form?.instance.itemOption(question.datafield);
        if (item && ((show && !item.visible) || (!show && item.visible)))
          this.form?.instance.itemOption(question.datafield, {
            visible: show,
          });

        // Delete value if not showing
        if (!show) this.formData[question.datafield] = null;
      }

      // Update items that have a calculated value
      if (
        question.itemType == 'display_item' &&
        question.calculateDisplayValueFnStr
      ) {
        try {
          const fn = new Function('fd', question.calculateDisplayValueFnStr);
          $(`#${question.datafield}`).html(fn(this.formData));
        } catch (error) {
          console.log('Display function error', question.datafield);
        }
      }

      if (this.readOnly) {
        $(`.hide-on-read-only`).hide();
      } else {
        $(`.hide-on-read-only`).show();
      }
    }
  }

  handleArrayGroup(question: IQuestion) {
    if (!this.form) return;
    if (this.formData[question.datafield] === undefined) {
      // Init with one question
      this.formData[question.datafield] = [{}];
      this.pgService.setFormData(this.formData);
    }

    const getRow = (i: number) => {
      return question.questions?.map((q): DevExpress.ui.dxFormSimpleItem => {
        return {
          colSpan: 1,
          editorType: q.editorType as any,
          editorOptions: q.editorOptions,
          dataField: `${question.datafield}[${i}].${q.datafield}`,
          label: { text: q.label },
          validationRules: q.validationRules,
        };
      });
    };

    const rows = this.formData[question.datafield].map((e: any, i: number) => {
      return getRow(i);
    });

    if (rows)
      for (const row of rows) {
        this._pushItemToFormBuffer({
          colSpan: 3,
          colCount: 3,
          name: question.datafield,
          itemType: 'group',
          items: row,
        });
      }

    this._pushItemToFormBuffer({
      cssClass: 'hide-on-read-only',
      editorType: 'dxButtonGroup' as any,
      label: { visible: false },
      editorOptions: {
        items: [
          {
            disabled: this.formData[question.datafield].length == 1,
            icon: 'minus',
            onClick: async () => {
              this.formData[question.datafield].pop();
              await this.initForm();
            },
          },
          {
            icon: 'plus',
            onClick: async () => {
              this.formData[question.datafield].push({});
              await this.initForm();
            },
          },
        ],
      },
    });
  }

  handleDisplayItem(question: IQuestion) {
    if (!this.form || !question.calculateDisplayValueFnStr) return;

    const fn = new Function('fd', question.calculateDisplayValueFnStr);
    this._pushItemToFormBuffer({
      itemType: 'simple',
      name: question.datafield,
      label: {
        text: question.label,
      },
      template: `
            <span id="${question.datafield}">${fn(this.formData)}</span>
            `,
      visible: true,
    });
  }

  formItemBuffer: any[] = [];
  /**
   * Private method to handle pushing items to form buffer
   * @param item
   * @returns
   */
  private _pushItemToFormBuffer(item: any) {
    this.formItemBuffer.push(item);
  }
  /**
   * Push buffer to form
   */
  private _pushFormBufferToForm() {
    if (this.form) {
      this.form.instance.option('items', this.formItemBuffer);
      this.handleFormLifecycle();
      // TODO: Remove when you hide the budget buttons better
      setTimeout(() => this.handleFormLifecycle(), 500);
    }
  }

  /**
   * Creates the authorization headers for the upload form
   */
  async updatedUploadHeaders() {
    const loggedInStatus = await this.authService.loggedInStatus();
    if (loggedInStatus.isLoggedIn) {
      const headers = {
        Authorization: `bearer ${this.authService.getAccessToken()}`,
      };
      this.uploadHeaders = headers;
    }
  }

  async handleFileUpload(question: IQuestion) {
    await this.updatedUploadHeaders();

    this._pushItemToFormBuffer({
      cssClass: 'hide-on-read-only',
      itemType: 'simple',
      label: { text: question.label },
      template: (data: any, element: any) => {
        return new FileUploader(element, {
          multiple: true,
          maxFileSize: 1.5e7,
          uploadUrl:
            environment.baseApiUrl +
            `/applications/${this.pgService.application_id}/file/${question.datafield}/`,
          onBeforeSend: async () => {
            const fileUpload = dxFileUploader.getInstance(element);
            await this.updatedUploadHeaders();
            fileUpload.option('uploadHeaders', this.uploadHeaders);
          },
          onValueChanged: async () => {
            const fileUpload = dxFileUploader.getInstance(element);
            await this.updatedUploadHeaders();
            fileUpload.option('uploadHeaders', this.uploadHeaders);
          },
          uploadHeaders: this.uploadHeaders,
          onUploaded: async (x) => {
            handleFileUploadList();
          },
        });
      },
    });

    const handleFileUploadList = async () => {
      if (this.pgService.application_id) {
        const fileStore = new CustomStore({
          load: async () => {
            if (!this.pgService.application_id) return [];
            return await lastValueFrom(
              this.applicationService.getFilesForKey(
                this.pgService.application_id,
                question.datafield
              )
            );
          },
          key: 'hashedName',
        });

        const fileDS = new DataSource({ store: fileStore });
        await fileDS.load();

        const items = [];
        if (fileDS.items().length != 0) {
          items.push({
            itemType: 'simple',
            template: (data: any, element: any) => {
              const dgInstance = new Datagrid(element, {
                dataSource: fileDS,
                columns: [
                  {
                    name: 'File Name',
                    dataField: 'fileName',
                  },
                  {
                    type: 'buttons',
                    buttons: [
                      {
                        icon: 'download',
                        onClick: async (e) => {
                          await this.pgService.getFile(
                            e.row?.data.hashedName,
                            e.row?.data.fileName
                          );
                        },
                      },
                      {
                        cssClass: 'hide-on-read-only',
                        icon: 'trash',
                        onClick: async (e) => {
                          await this.pgService.deleteFile(
                            e.row?.data.hashedName
                          );
                          await dgInstance.refresh();
                          // Remove fileUpload from dom if item list 0
                          if (fileDS.items().length == 0) {
                            handleFileUploadList();
                          }
                        },
                      },
                    ],
                  },
                ],
              });
            },
            visible: true,
          });
        }

        const existingItemGroup = this.form?.instance.itemOption(
          `$FILEITEMSGROUP_${question.datafield}`
        );
        // Check if group already exists so we dont make a new one.
        if (existingItemGroup) {
          this.form?.instance.itemOption(
            `$FILEITEMSGROUP_${question.datafield}`,
            'items',
            items
          );
        } else {
          this._pushItemToFormBuffer({
            name: `$FILEITEMSGROUP_${question.datafield}`,
            itemType: 'group',
            items,
          });
        }
      }
    };
    await handleFileUploadList();
  }

  midInit = false;
  async initForm() {
    if (this.midInit == true) return;
    this.midInit = true;
    this.formChange.emit(this.form);

    if (!this.form) return;

    this.formItemBuffer = [];
    this.form.items = [];
    this.form.instance.option('items', []);

    for (const question of this.questions) {
      if (question.itemType == 'array_group') {
        this.handleArrayGroup(question);
      } else if (question.itemType == 'display_item') {
        this.handleDisplayItem(question);
      } else if (question.editorType == 'dxFileUploader') {
        await this.handleFileUpload(question);
      } else {
        if (question.getLoadURL) {
          const loadUrl = this.handleLoadURL(question.getLoadURL);
          if (!question.editorOptions) question.editorOptions = {};
          question.editorOptions.dataSource = loadUrl;
        }

        this._pushItemToFormBuffer({
          dataField: question.datafield,
          name: question.datafield,
          editorType: question.editorType,
          editorOptions: question.editorOptions,
          label: {
            text: question.label,
          },
          validationRules: this.buildValidationRules(
            question.validationRules
          ) as any,
          visible: true,
        });

        // If question requires a character count, push a new item to form to track this.
        if (question.characterCount) {
          this._pushItemToFormBuffer({
            visible: true,
            itemType: 'simple',
            template: `
          <div style="display: flex; justify-content: right;">
          <span id="${question.datafield}cc">
          ${this.calculateCharacterCount(
            question.datafield,
            question.characterCount.max
          )}</span>
          </div>`,
          });
        }
      }
    }

    this._pushFormBufferToForm();
    this.midInit = false;
  }

  calculateCharacterCount = (e: any, max: number) => {
    if (!this.formData[e]) return `0/${max}`;
    // Remove HTML tags using regular expression
    const textWithoutTags = this.formData[e].replace(/<[^>]*>/g, '');
    return `${textWithoutTags.length}/${max}`;
  };

  /**
   * Converts strings to a function that returns a boolean.
   * @param e
   * @returns
   */
  handleIf(e: string) {
    if (!e) return true;
    try {
      let f = new Function('fd', e);
      const show = f(this.formData);
      return show;
    } catch (error) {
      console.log('Error with if function: ', e);
      return true;
    }
  }

  handleLoadURL(e: string) {
    if (!e) return true;
    try {
      let f = new Function('fd', e);
      const show = f(this.formData);
      return show;
    } catch (error) {
      console.log('Error with load url function: ', error, e);
      return true;
    }
  }

  buildValidationRules(e: any) {
    if (!e) return;
    const rulesParsed = e;
    const rules = [];
    for (const rule of rulesParsed) {
      if (rule.type != 'custom') rules.push(rule);
      // Create functions from strings
      if (rule.validationCallbackFnStr) {
        const fn = new Function('option', rule.validationCallbackFnStr);
        rules.push({
          ...rule,
          validationCallback: fn,
        });
      }
    }

    return rules;
  }
}
