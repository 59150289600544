
<dx-list
  class="maxHeightListBox"
  [dataSource]="dataSource ?? []"
  displayExpr="about.name"
  valueExpr="uniqueId"
  selectionMode='single'
  [showSelectionControls]="true"
  [searchEnabled]="true"
  hint="Select a school"
  template="item"
  (onOptionChanged)="optionChange($event)">
  <div *dxTemplate="let item of 'item'">
    <p style="font-size: 16px;"><b>{{item.about.name}}</b></p>
    <p style="font-size: 14px;">{{item.about.addressStreet}}</p>
    <p style="font-size: 14px;" *ngIf="type == ACCOUNT_TYPE.SCHOOL">
      {{item.about.addressCity}}, {{item.about.addressState}}
      {{item.about.addressPostalCode}}</p>
  </div>
</dx-list>
