<h3>Accept Invite</h3>
<div *ngIf="!error;else errorMsg">
  <p>Add/update any information, and click submit to create your account!</p>
  <form (submit)="submit($event)">
    <dx-form [formData]="user">
      <dxi-item dataField="username">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="password" [editorOptions]="{mode: 'password'}">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="confirmPassword"
        [editorOptions]="{mode: 'password'}">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule
          type="custom"
          message="Passwords do not match!"
          [validationCallback]="confirmPassword"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="firstName">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="lastName">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="contactInfo.emailAddress">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-label text="Email"></dxo-label>
      </dxi-item>
      <dxi-item dataField="contactInfo.phoneNumber">
        <dxo-label text="Phone"></dxo-label>

      </dxi-item>
      <dxi-item itemType="button"
        [buttonOptions]="{text: 'Submit', type: 'default', useSubmitBehavior: true}"></dxi-item>
    </dx-form>
  </form>
</div>
<ng-template #errorMsg><h5
    style="color: red;">{{errorMessage}}</h5></ng-template>
