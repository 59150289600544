import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApplicationsService } from '../../../shared/services';
import { ApplicationRejectBody } from '../../../shared/types/application-reject.interface';

@Component({
  selector: 'app-deny-app-form',
  templateUrl: './deny-application-form.component.html',
  styleUrls: ['./deny-application-form.component.scss'],
})
export class DenyAppFormComponent implements OnInit {
  rejectFormData: Partial<ApplicationRejectBody> = {};

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter();
  @Output()
  onSubmit: EventEmitter<Partial<ApplicationRejectBody>> = new EventEmitter();

  constructor(private readonly _applicationService: ApplicationsService) {}
  async ngOnInit() {}

  _onSubmit = async (e: Event) => {
    e.preventDefault();
    this.onSubmit.emit(this.rejectFormData);
  };

  onCancelClicked = async () => {
    this.rejectFormData = {};
    this.onCancel.emit();
  };
}
