import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService, GrantsService } from '../../../shared/services';
import { IApplication } from '../../../shared/types/application.interface';
import { lastValueFrom } from 'rxjs';
import { IGrant } from '../../grants/types/IGrants';
import { notifyWrapper } from '../../../shared/globals/notify-wrapper';
import { APPLICATION_STATUS } from '../../../shared/enums/ApplicationStatus.enum';
import { ApplicationRejectBody } from '../../../shared/types/application-reject.interface';
import { ConfirmationPopupService } from '../../../shared/components/confirmation-popup/confirmation-popup.service';
import { ApplicationReviewService } from '../../../shared/services/application/review.service';
import { IApplicationReadHistory } from '../../../shared/types/IApplicationReadHistory.interface';
import { IGrantCheck } from '../../../shared/types/IGrantCheck';
import { EvaluationStatus } from '../../../shared/enums/EvaluationStatus.enum';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  applicationId!: number;
  application?: IApplication;
  grant?: IGrant;
  formData: any = { checks: {} };
  checks: IGrantCheck[] = [];
  APPLICATION_STATUS = APPLICATION_STATUS;
  showDenyForm: boolean = false;
  readHistory: IApplicationReadHistory[] = [];

  evaluations: any = [];

  bonusPoints: any[] = [];

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _applicationService: ApplicationsService,
    private readonly _applicationReviewService: ApplicationReviewService,
    private readonly _confirmationService: ConfirmationPopupService
  ) {}

  async ngOnInit() {
    this.applicationId = Number(
      this._activatedRoute.snapshot.paramMap.get('applicationId')
    );

    const res = await lastValueFrom(
      this._applicationService.getApplication(this.applicationId)
    );

    const readHistory = await lastValueFrom(
      this._applicationService.getReadHistory(this.applicationId)
    );

    this.bonusPoints = await lastValueFrom(
      this._applicationService.getBonusPoints(this.applicationId)
    );

    console.log(this.bonusPoints);

    this.readHistory = readHistory;

    if (res.grant) {
      this.checks = res.grant.checks;
      this.grant = res.grant;
    }
    this.application = res;

    await this.loadEvaluations();
  }

  async loadEvaluations() {
    const evaluations = await lastValueFrom(
      this._applicationService.getEvaluationsForApplication(this.applicationId)
    );

    this.evaluations = evaluations;
  }

  async onSubmit(e: any) {
    e.preventDefault();

    const confirm = await lastValueFrom(
      this._confirmationService.confirm(
        'Approve for Review',
        'Are you sure you want to approve this application for review?',
        'success',
        'default'
      )
    );
    if (!confirm) return;

    const completedCheckIds: number[] = [];
    for (const key of Object.keys(this.formData.checks)) {
      if (this.formData.checks[key] === true)
        completedCheckIds.push(parseInt(key));
    }

    try {
      const app = await lastValueFrom(
        this._applicationReviewService.reviewApplication(this.applicationId, {
          completedCheckIds,
        })
      );

      this.application = app;
      notifyWrapper('Successfully approved for review!', 'success');
    } catch (e: any) {
      notifyWrapper(`Error reviewing: ${e.message}!`, 'error');
    }
  }

  onSendBackClick = () => {
    this.showDenyForm = true;
  };

  onSendBackFormCancel = () => {
    this.showDenyForm = false;
  };

  onSendBackSubmit = async (fd: Partial<ApplicationRejectBody>) => {
    const confirm = await lastValueFrom(
      this._confirmationService.confirm(
        'Send Back',
        'Are you sure you want to send this application back?',
        'danger',
        'default'
      )
    );
    if (!confirm) return;
    try {
      const res = await lastValueFrom(
        this._applicationReviewService.rejectApplication(
          this.applicationId,
          fd as ApplicationRejectBody
        )
      );
      this.application = res;
      notifyWrapper('Successfully rejected!', 'success');
      this.showDenyForm = false;
    } catch (e: any) {
      notifyWrapper(`Error rejecting! ${e}`, 'error');
    }
  };

  onClickAssignEvaluators = async () => {
    const confirm = await lastValueFrom(
      this._confirmationService.confirm(
        'Assign Evaluators?',
        `Are you sure you want to assign evaluators?`,
        'success',
        'default'
      )
    );
    if (confirm) {
      this.assignEvaluators();
    }
  };

  assignEvaluators = async (force: boolean = false) => {
    try {
      const res = await lastValueFrom(
        this._applicationService.assignEvaluators(this.applicationId, force)
      );

      if (res.success && res.data) {
        this.application = res.data;
        await this.loadEvaluations();
      } else {
        const confirm = await lastValueFrom(
          this._confirmationService.confirm(
            'Error assigning evaluators!',
            `${res.message} Assign anyways?`,
            'danger',
            'default'
          )
        );

        if (confirm) {
          this.assignEvaluators(true);
        }
      }
    } catch (error) {
      notifyWrapper('Error assigning evaluators!', 'error');
    }
  };

  calculateScoreColumn = (evaluation: any) => {
    if (evaluation.scored && evaluation.scored.length > 0) {
      if (evaluation.statusValue >= EvaluationStatus.EVALUATED) {
        let total = 0;
        for (const score of evaluation.scored) {
          total += score.score;
        }
        return `${total.toFixed(2)}`;
      }
    }
    return undefined;
  };

  get totalPoints() {
    return this.totalEvaluationPoints + this.totalBonusPoints;
  }
  get totalEvaluationPoints() {
    if (this.evaluations.length == 0) {
      return 0;
    }
    let sum = 0;
    for (const evaluation of this.evaluations) {
      for (const score of evaluation.scored) {
        sum += score.score;
      }
    }
    return sum / this.evaluations.length;
  }
  get totalBonusPoints() {
    if (this.bonusPoints.length == 0) {
      return 0;
    }
    let sum = 0;
    for (const bonus of this.bonusPoints) {
      if (bonus.obtained) {
        sum += bonus.bonusValue;
      }
    }
    return sum;
  }
}
