import { Component, Input, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { IApplication } from 'src/app/shared/types/application.interface';
import 'devextreme/integration/jquery';
import { GrantGroupService } from '../../../../shared/services/eval/group.service';
import { IGrantGroup } from '../../../../shared/types/IGrantGroup.interface';

@Component({
  selector: 'app-grant-eval-groups',
  templateUrl: './eval-groups.component.html',
  styleUrl: './eval-groups.component.scss',
})
export class GrantEvalGroupComponent implements OnInit {
  @Input({ required: true })
  grantId!: number;

  grantGroup?: CustomStore<IGrantGroup>;
  grantGroupDataSource!: DataSource<IApplication>;

  constructor(private readonly _grantGroupService: GrantGroupService) {}

  async ngOnInit() {
    this.grantGroup = new CustomStore<IGrantGroup>({
      key: 'id',
      load: async () =>
        await lastValueFrom(this._grantGroupService.getGroups(this.grantId)),
      update: async (key, value) =>
        await lastValueFrom(
          this._grantGroupService.updateGroup(this.grantId, key, value)
        ),
      insert: async (value) =>
        await lastValueFrom(
          this._grantGroupService.createGroup(this.grantId, value)
        ),
      remove: async (key) => {
        await lastValueFrom(
          this._grantGroupService.deleteGroup(this.grantId, key)
        );
      },
    });

    this.grantGroupDataSource = new DataSource<IApplication, number>({
      store: this.grantGroup,
    });
  }
}
