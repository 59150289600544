import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { IApplication } from 'src/app/shared/types/application.interface';
import { Router } from '@angular/router';
import 'devextreme/integration/jquery';
import { GrantGroupService } from '../../../../../shared/services/eval/group.service';
import { IGrantGroup } from '../../../../../shared/types/IGrantGroup.interface';
import { IUser } from '../../../../../shared/types/IUser';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-assigned-evaluators',
  templateUrl: './assigned-evaluators.component.html',
  styleUrl: './assigned-evaluators.component.scss',
})
export class GrantAssignedEvaluatorsComponent implements OnInit, AfterViewInit {
  @Input({ required: true })
  groupId!: number;

  @Input({ required: true })
  grantId!: number;

  @ViewChild(DxDataGridComponent)
  datagrid?: DxDataGridComponent;

  assignedEvaluators?: CustomStore<IUser>;
  dataSource!: DataSource<IUser>;

  assignableStore!: CustomStore<IUser>;

  constructor(private readonly _grantGroupService: GrantGroupService) {}
  ngAfterViewInit(): void {
    this.assignableStore = new CustomStore({
      load: async () =>
        await lastValueFrom(
          this._grantGroupService.getAssignableEvaluators(this.grantId)
        ),
      key: 'id',
    });
  }

  async ngOnInit() {
    this.assignedEvaluators = new CustomStore<IUser>({
      key: 'id',
      load: async () =>
        await lastValueFrom(
          this._grantGroupService.getAssignedEvaluators(this.groupId)
        ),
      insert: async (value) => {
        await lastValueFrom(
          this._grantGroupService.assignUserToGroup(this.groupId, value.id)
        );
        return value;
      },
      remove: async (key) => {
        await lastValueFrom(
          this._grantGroupService.removeUserFromGroup(this.groupId, key)
        );
      },
    });
    this.dataSource = new DataSource<IUser, number>({
      store: this.assignedEvaluators,
    });
  }
}
