import * as _ from 'lodash';
import { DataObject, DxiColumnEditorOptions } from './data-grid.types';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { isISO8601, isNumberString } from 'class-validator';
import { FormatObject } from 'devextreme/localization';

/**
 * Generate Dev Extreme Date Grid Column(dxi-column component from dev extreme) objects for DevExtreme DataGrid (dx-data-grid component from dev extreme)
 * @param obj
 * @returns
 */
export async function generateDataGridColumns<T extends DataObject>(
  obj: T,
  columnsWithFomattingOptions: (keyof T)[] = []
): Promise<DxDataGridTypes.Column[]> {
  return Promise.all(
    Object.entries(obj).map(
      async ([key, value]): Promise<DxDataGridTypes.Column> => {
        const addFormattingOptions = columnsWithFomattingOptions.includes(key);

        const allowEditing = await removeEditingFromPrimaryId(key);

        const visible = await disableVisibility(key);

        const dataType = await getDxiColumnDataType(value);

        const caption = await formatDxiColumnCaption(key);

        const format = await getDxiColumnValueFormat(
          value,
          addFormattingOptions
        );

        const editorOptions = await getDxiColumnEditorOptions(
          value,
          addFormattingOptions
        );

        const dxiColumn: DxDataGridTypes.Column = {
          allowEditing: allowEditing,
          dataField: key,
          dataType: dataType,
          format: format,
          caption: caption,
          visible: visible,
          editorOptions: editorOptions,
        };
        return dxiColumn;
      }
    )
  );
}

/**
 * Gets the data type of the value from the object
 * @param value
 * @returns  the dxi-column data type property value
 */
async function getDxiColumnDataType(
  value: any
): Promise<DxDataGridTypes.DataType> {
  return new Promise((resolve, reject) => {
    switch (typeof value) {
      case 'number':
        resolve('number');
        break;
      case 'boolean':
        resolve('boolean');
        break;
      case 'string':
        if (isISO8601(value)) {
          resolve('date');
        } else if (isNumberString(value)) {
          resolve('number');
        } else {
          resolve('string');
        }
        break;
      case 'object':
        resolve('object');
        break;
      default:
        resolve('string');
    }
  });
}

/**
 * Returns a promise for async function getDxiColumnEditorOptions
 * @param value The value to get editor options for
 * @param addOptions Whether to add options or not
 * @returns A promise resolving to DxiColumnEditorOptions or undefined
 */
async function getDxiColumnEditorOptions(
  value: any,
  addOptions: boolean
): Promise<DxiColumnEditorOptions | undefined> {
  return new Promise((resolve, reject) => {
    let dxiColumnEditorOptions: DxiColumnEditorOptions = {};
    if (addOptions === true) {
      if (typeof value === 'number' || typeof value === 'string') {
        dxiColumnEditorOptions.format = { type: 'currency', precision: 2 };
        resolve(dxiColumnEditorOptions);
      } else {
        resolve(undefined);
      }
    } else {
      resolve(undefined);
    }
  });
}

/**
 * Returns a promise for async function getDxiColumnEditorOptions
 * @param value The value to get editor options for
 * @param addOptions Whether to add options or not
 * @returns A promise resolving to DxiColumnEditorOptions or undefined
 */
async function getDxiColumnValueFormat(
  value: any,
  addOptions: boolean
): Promise<FormatObject | undefined> {
  return new Promise((resolve, reject) => {
    let dxiColumnValueFormat: FormatObject = {};
    if (addOptions === true) {
      if (typeof value === 'number' || typeof value === 'string') {
        dxiColumnValueFormat.type = 'currency';
        dxiColumnValueFormat.precision = 2;
        resolve(dxiColumnValueFormat);
      } else {
        resolve(undefined);
      }
    } else {
      resolve(undefined);
    }
  });
}

/**
 * Adds a space before every capital letter in the key and capitalizes the first letter of the key
 * @param key The key to format
 * @returns A promise resolving to the formatted caption
 */
async function formatDxiColumnCaption(key: string): Promise<string> {
  return new Promise((resolve, reject) => {
    resolve(
      key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')
    );
  });
}

/**
 * Removes the editing property from the primary id
 * @param key
 * @returns
 */
async function removeEditingFromPrimaryId(key: string): Promise<boolean> {
  const primaryId = ['id'];
  return new Promise((resolve, reject) => {
    resolve(primaryId.includes(key) ? false : true);
  });
}

/**
 * Disables visibility for the primary id
 * @param key
 * @returns
 */
async function disableVisibility(key: string): Promise<boolean> {
  const primaryId = ['id'];

  return new Promise((resolve, reject) => {
    resolve(primaryId.includes(key) ? false : true);
  });
}
