import { Component, HostBinding, HostListener, OnDestroy } from '@angular/core';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { Subject, Subscription } from 'rxjs';
import config from 'devextreme/core/config';
import { environment } from '../environments/environment';
import { notifyWrapper } from './shared/globals/notify-wrapper';

config({ licenseKey: environment.deKey });

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(' ');
  }

  isLoggedInObservable?: Subscription;
  userActivity?: any;
  userInactive: Subject<any> = new Subject();

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService
  ) {
    this.isLoggedInObservable = this.authService.isLoggedIn.subscribe({
      next: (loggedIn) => {
        this.isAuthenticated = loggedIn;
      },
    });

    this.userInactive.subscribe({
      next: () => {
        if (this.isAuthenticated) {
          this.authService.logout();
          notifyWrapper(
            'User has been logged out due to inactivity',
            'warning',
            60000 * 15
          );
        }
      },
    });
  }

  isAuthenticated: boolean = false;

  ngOnDestroy(): void {
    this.isLoggedInObservable?.unsubscribe();
  }

  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      60000 * 15
    );
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
