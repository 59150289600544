import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxTabPanelModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import {
  AwardBalance,
  ReimbursementInterface,
  AppReimbursementsService,
  AppBudget,
} from '../../../services/application/app-reimbursement.service';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom, Subscription } from 'rxjs';
import { notifyWrapper } from '../../../globals/notify-wrapper';
import { ReimbursementStatus } from '../../../globals/enums/ReimbursementStatus.enum';
import { ConfirmationPopupService } from '../../confirmation-popup/confirmation-popup.service';
import { ReimbursementFormComponent } from '../reimbursement-form/reimbursement-form.component';
import { ReimbursementCommentsComponent } from '../../../../pages/reimbursement/comments/comments.component';
import { ReimbursementFilesComponent } from '../../../../pages/reimbursement/invoice-files/invoice-files.component';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { AppAwardInterface } from '../../../services/application/awards.service';
import { ReimbursementBudgetComponent } from '../reimbursement-budget/reimbursement-budget.component';
import { IApplication } from '../../../types/application.interface';

@Component({
  selector: 'app-reimbursements-popup',
  standalone: true,
  imports: [
    DxPopupModule,
    DxFormModule,
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    ReimbursementFormComponent,
    ReimbursementCommentsComponent,
    ReimbursementFilesComponent,
    DxTabPanelModule,
    ReimbursementBudgetComponent,
    DxTextBoxModule,
  ],
  templateUrl: './reimbursements-popup.component.html',
  styleUrl: './reimbursements-popup.component.scss',
})
export class ReimbursementsPopupComponent implements OnInit {
  budgetDisplayExpr(e: AppBudget) {
    console.log(e);
    if (!e) return '';
    return `${e.category} - ${e.description}`;
  }

  onEditorPreparing(
    e: DxDataGridTypes.EditorPreparingEvent<ReimbursementInterface>
  ) {
    if (
      e.parentType === 'dataRow' &&
      e.dataField === 'budgetId' &&
      e.row?.isNewRow == undefined
    ) {
      e.editorOptions.disabled = true;
    }
  }

  @Input({ required: true })
  application!: IApplication;

  @Input()
  reimbursementId?: number | null;

  @Input()
  visible: boolean = false;
  @Input({ required: true })
  award!: AppAwardInterface;

  isEditing: boolean = false;
  loading: boolean = false;
  balance?: AwardBalance;

  @ViewChild(DxDataGridComponent)
  datagrid?: DxDataGridComponent;

  REIMBURSEMENT_STATUS = ReimbursementStatus;

  @Output()
  visibleChange = new EventEmitter<boolean>();

  onHiding = () => {
    this.visible = false;
    this.visibleChange.emit(false);
  };

  async saveEdit(e: ReimbursementInterface) {
    try {
      this.loading = true;
      if (e.id) {
        this.formdata = await lastValueFrom(
          this.riService.updateReimbursement(
            this.application.id,
            this.award.id,
            e.id,
            this.formdata
          )
        );
      } else {
        this.formdata = await lastValueFrom(
          this.riService.createReimbursement(
            this.application.id,
            this.award.id,
            e
          )
        );

        this.reimbursementId = this.formdata.id;
        await this.createInvoiceStore();
        await this.loadBalance();
      }

      this.isEditing = false;
    } catch (error) {
      notifyWrapper(`Error saving! ${error}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  formdata: Partial<ReimbursementInterface> = {};
  previousFormData: Partial<ReimbursementInterface> = {};

  invoiceStore?: CustomStore;

  async rowChanges() {
    await this.loadBalance();
  }

  async loadReimbursement() {
    if (this.reimbursementId) {
      const reimbursement = await lastValueFrom(
        this.riService.getReimbursement(
          this.application.id,
          this.award.id,
          this.reimbursementId
        )
      );
      this.formdata = reimbursement;
    }
  }

  cannotSubmitReason?: string = undefined;
  async loadBalance() {
    try {
      this.balance = await lastValueFrom(
        this.riService.getBalance(this.application.id, this.award.id)
      );

      if (this.formdata.id) {
        const invoices = await lastValueFrom(
          this.riService.getAllInvocies(
            this.application.id,
            this.award.id,
            this.formdata.id
          )
        );

        const invoiceTotal = invoices
          .map((i) => i.amountRequested)
          .reduce((a, b) => a + b, 0);

        if (invoiceTotal > this.balance.available) {
          this.cannotSubmitReason = 'Invoice total exceeds available balance!';
        } else if (invoiceTotal == 0) {
          this.cannotSubmitReason = 'Cannot submit an empty invoice!';
        } else {
          this.cannotSubmitReason = undefined;
        }
      }
    } catch (error) {
      notifyWrapper(`Error loading balance! ${error}`, 'error');
    }
  }

  createInvoiceStore() {
    if (this.formdata.id) {
      const reimbursementId = this.formdata.id;
      this.invoiceStore = new CustomStore({
        key: 'id',
        load: async () =>
          await lastValueFrom(
            this.riService.getAllInvocies(
              this.application.id,
              this.award.id,
              reimbursementId
            )
          ),
        insert: async (values) => {
          const res = await lastValueFrom(
            this.riService.createInvoice(
              this.application.id,
              this.award.id,
              reimbursementId,
              values
            )
          );
          this.riService.triggerRefresh();
          return res;
        },
        update: async (key, values) => {
          const res = await lastValueFrom(
            this.riService.updateInvoice(
              this.application.id,
              this.award.id,
              reimbursementId,
              key,
              values
            )
          );
          this.riService.triggerRefresh();
          return res;
        },
        remove: async (key) => {
          await lastValueFrom(
            this.riService.removeInvoice(
              this.application.id,
              this.award.id,
              reimbursementId,
              key
            )
          );
          this.riService.triggerRefresh();
        },
      });
    } else {
      throw new Error('Cannot create invoice store without a reimbursement id');
    }
  }

  budgetItemStore = new CustomStore({
    key: 'id',
    load: async () => {
      return await lastValueFrom(
        this.riService.getBudgetItems(
          this.application.id,
          this.award.id,
          this.formdata.id
        )
      );
    },
    byKey: async (key) => {
      const items = await lastValueFrom(
        this.riService.getBudgetItems(
          this.application.id,
          this.award.id,
          this.formdata.id
        )
      );
      return items.find((i) => i.id == key);
    },
  });

  constructor(
    private riService: AppReimbursementsService,
    private confirmPopupService: ConfirmationPopupService
  ) {}
  async ngOnInit() {
    if (this.reimbursementId) {
      await this.loadReimbursement();
      await this.createInvoiceStore();
      await this.loadBalance();
    } else {
      if (!this.award) throw new Error('Pass award to this component!');
      this.isEditing = true;

      const reimbursementInfo = this.application.formData.reimbursementInfo;
      this.formdata.vendorNumber = reimbursementInfo.vendorNumber;
      this.formdata.applicantFirstName =
        reimbursementInfo.authorizedAgentFirstName;
      this.formdata.applicantLastName =
        reimbursementInfo.authorizedAgentLastName;
      this.formdata.agencyName = reimbursementInfo.agencyName;
      console.log(reimbursementInfo);
    }
  }

  validateAmount = async (e: any) => {
    if (!this.formdata.id) return false;
    const invoices = await lastValueFrom(
      this.riService.getAllInvocies(
        this.application.id,
        this.award.id,
        this.formdata.id
      )
    );

    const invoiceTotal = invoices
      .filter((i) => i.id !== e.data.id) // Remove current invoice from total, as we get it from the value
      .map((i) => i.amountRequested)
      .reduce((a, b) => a + b, 0);

    const t = invoiceTotal + e.value;
    if (this.balance && e.value) {
      if (t > this.balance.awardAmount) {
        return false;
      }
    }
    return true;
  };

  async submitReimbursement() {
    const confirm = await lastValueFrom(
      this.confirmPopupService.confirm(
        'Confirm Submit',
        'After submitting, you will not be able to make changes to this reimbursement. Are you sure you want to submit?',
        'danger'
      )
    );

    if (!confirm) return;
    this.loading = true;
    try {
      if (this.formdata.id) {
        this.formdata = await lastValueFrom(
          this.riService.submitReimbursement(
            this.application.id,
            this.award.id,
            this.formdata.id
          )
        );

        notifyWrapper('Successfully submitted reimbursement!', 'success');

        this.riService.triggerRefresh();
        await this.loadReimbursement();
        await this.loadBalance();
      }
    } catch (e: any) {
      notifyWrapper(
        `Error submitting reimbursement! ${
          e?.error?.message ?? 'Unknown Error'
        }`,
        'error',
        15000
      );
    } finally {
      this.loading = false;
    }
  }

  get canEdit() {
    if (!this.formdata.status) return true;
    return (
      this.formdata.status == ReimbursementStatus.Created ||
      this.formdata.status == ReimbursementStatus['Sent Back to Applicant']
    );
  }

  isEligibleForAdvancedPayment = false;
}
