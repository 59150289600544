import {
  AbilityClass,
  createMongoAbility,
  MongoAbility,
  PureAbility,
} from '@casl/ability';
import { AuthService } from './auth.service';
import { IUserRolePermission } from '../types/user-role-permission.interface';
import { PermissionAction, PermissionItem } from '../enums';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
export type Subjects = 'all' | 'Admin';

export type AppAbility = MongoAbility<[PermissionAction, PermissionItem]>;
export const AppAbility = PureAbility as AbilityClass<AppAbility>;

export function buildAbility(permissions: IUserRolePermission[]) {
  const caslPermissions = permissions.map((p) => ({
    action: p.action,
    subject: p.permissionItem.name,
  })) as any;
  return caslPermissions;
}

export function createAbility(authService: AuthService) {
  const permissions = authService.getUserPermissions();
  return createMongoAbility(buildAbility(permissions));
}
