import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFileUploaderModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxLoadIndicatorModule,
  DxResponsiveBoxModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
} from 'devextreme-angular';
import { QuestionFormComponent } from './question-form/question-form.component';
import { ApplicationSectionTabs } from './section-tabs/section-tabs.component';
import { PlaygroundService } from './playground.service';
import { ApplicationSavedStatus } from './saved-status/saved-status.component';
import { ApplicationSectionHandler } from './sections-handler/sections-handler.component';
import { ApplicationActionBarComponent } from './action-bar/action-bar.component';
import { ApplicationAwardsComponent } from '../../shared/components/application-awards/application-awards.component';
import { AdditionalFilesComponent } from './sections-handler/additional-files/additional-files.component';

@Component({
  selector: 'app-playground',
  templateUrl: 'playground.component.html',
  styleUrls: ['./playground.component.scss'],
})
export class PlaygroundComponent implements OnInit {
  @Input({ required: true })
  applicationId!: number;
  @Output()
  saveEvent: EventEmitter<any> = new EventEmitter();
  constructor(private pgService: PlaygroundService) {}
  async ngOnInit() {
    await this.pgService.loadForm(this.applicationId);
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxResponsiveBoxModule,
    DxFormModule,
    DxTextAreaModule,
    DxHtmlEditorModule,
    DxButtonModule,
    DxTagBoxModule,
    DxTabsModule,
    DxLoadIndicatorModule,
    DxFileUploaderModule,
    DxDataGridModule,
    ApplicationAwardsComponent,
    AdditionalFilesComponent,
  ],
  declarations: [
    PlaygroundComponent,
    QuestionFormComponent,
    ApplicationSectionTabs,
    ApplicationSectionHandler,
    ApplicationSavedStatus,
    ApplicationActionBarComponent,
  ],
  exports: [PlaygroundComponent, QuestionFormComponent],
})
export class PlaygroundModule {}
