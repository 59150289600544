 <div>
  <h4 style="margin: 0px;">Status History:</h4>
  <dx-data-grid [dataSource]="applicationHistory"
    style="max-height: 300px;">
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

    <dxi-column
      dataField="status"
      caption="Status"></dxi-column>
    <dxi-column
      dataField="reason"
      caption="Reason"></dxi-column>
    <dxi-column
      caption="Date"
      dataField="created"
      dataType="datetime"></dxi-column>
  </dx-data-grid>
</div>
