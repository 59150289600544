import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { GrantsService } from 'src/app/shared/services';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { notifyWrapper } from '../../../../shared/globals/notify-wrapper';
import { ConfirmationPopupService } from '../../../../shared/components/confirmation-popup/confirmation-popup.service';
import { IGrant } from '../../types/IGrants';

@Component({
  selector: 'app-grants-accordion',
  templateUrl: './grants-accordion.component.html',
  styleUrls: ['./grants-accordion.component.scss'],
})
export class GrantsAccordionComponent implements OnInit {
  grantsStore?: CustomStore<object, number>;
  grantsDataSource: DataSource<object, number> = new DataSource({});

  constructor(
    private grantsService: GrantsService,
    private _router: Router,
    private confirmationService: ConfirmationPopupService
  ) {}

  ngOnInit() {
    this.grantsStore = new CustomStore({
      key: 'grant.id',
      load: async () => lastValueFrom(this.grantsService.getUserGrants()),
    });

    this.grantsDataSource = new DataSource({
      store: this.grantsStore,
    });
  }

  async onApplyClick(grantId: number) {
    try {
      const application: any = await lastValueFrom(
        this.grantsService.apply(grantId)
      );

      this._router.navigateByUrl(`application/${application.id}`);
    } catch (e: any) {
      notifyWrapper(e.error.message ?? 'Error applying for grant!', 'error');
    }
  }

  async onDeleteClick(grantId: number) {
    const confirm = await lastValueFrom(
      this.confirmationService.confirm(
        'Delete application?',
        'Are you sure you want to delete this application? You will not be able to retrieve it once it is deleted.',
        'danger'
      )
    );
    if (!confirm) return;
    try {
      await lastValueFrom(this.grantsService.unApply(grantId));

      this.grantsDataSource?.load();
    } catch (e: any) {
      notifyWrapper(
        e.error.message ?? 'Error deleting application for grant!',
        'error'
      );
    }
  }

  async onResumeClick(applicationId: number) {
    this._router.navigateByUrl(`application/${applicationId}`);
  }
}
