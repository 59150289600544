<div class="admin-data-grid-container">
  <dx-data-grid
    id="data-grid"
    (onExporting)="onExporting($event)"
    [dataSource]="reimbursementsStore"
    [showBorders]="true"
    [focusedRowEnabled]="false"
    [columnAutoWidth]="true"
    [rowAlternationEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [columnChooser]="{ enabled: true }"
  >
    <dxo-toolbar>
      <dxi-item location="before">
        <div class="data-grid-header">Reimbursements</div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-check-box
            [(value)]="showAll"
            text="Show All"
            (onValueChanged)="dataGrid?.instance?.refresh()"
          ></dx-check-box>
        </div>
      </dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item location="auto" name="columnChooserButton"> </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="refresh" (onClick)="dataGrid?.instance?.refresh()">
          </dx-button>
        </div>
      </dxi-item>
    </dxo-toolbar>
    <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-paging [pageSize]="50"> </dxo-paging>
    <dxo-pager
      [visible]="true"
      [allowedPageSizes]="[50, 100, 250, 500]"
      displayMode="full"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [showNavigationButtons]="true"
    >
    </dxo-pager>
    <dxo-selection mode="multiple"></dxo-selection>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="reimbursementsPageV0.0.06"
    ></dxo-state-storing>
    <dxi-column type="buttons">
      <dxi-button icon="eyeopen" text="View" [onClick]="this.onViewClick">
      </dxi-button>
    </dxi-column>
    <dxi-column
      alignment="center"
      caption="Type"
      [calculateCellValue]="getREType"
      [allowFiltering]="true"
    >
      <dxo-lookup
        [dataSource]="['Reimbursement', 'Advance', 'Reconciliation']"
      ></dxo-lookup>
    </dxi-column>
    <dxi-column
      width="auto"
      [allowEditing]="false"
      alignment="center"
      dataField="reimbursementIdentification"
      caption="Reimbursement Number"
    ></dxi-column>
    <dxi-column alignment="center" caption="Region" dataField="region">
    </dxi-column>
    <dxi-column alignment="center" caption="Agency" dataField="agencyName">
    </dxi-column>
    <dxi-column
      alignment="center"
      caption="Check Number"
      dataField="checkNumber"
    >
    </dxi-column>
    <dxi-column
      alignment="center"
      dataType="string"
      caption="Vendor #"
      dataField="vendorNumber"
    >
    </dxi-column>
    <dxi-column
      alignment="center"
      caption="PO Number"
      dataField="purchaseOrderNumber"
    >
    </dxi-column>
    <dxi-column
      [visible]="false"
      alignment="center"
      dataType="string"
      caption="FN"
      dataField="applicantFirstName"
    >
    </dxi-column>
    <dxi-column
      [visible]="false"
      alignment="center"
      dataType="string"
      caption="LN"
      dataField="applicantLastName"
    >
    </dxi-column>
    <dxi-column
      [visible]="false"
      alignment="center"
      dataType="boolean"
      caption="Advanced Payment?"
      dataField="advancedPayment"
    >
    </dxi-column>

    <dxi-column
      alignment="center"
      dataField="totalRequested"
      dataType="number"
      format="currency"
      [editorOptions]="{ format: '$ #,##0.##', min: 0 }"
    >
    </dxi-column>
    <dxi-column
      alignment="center"
      dataField="totalApproved"
      alignment="center"
      dataType="number"
      format="currency"
      [editorOptions]="{ format: '$ #,##0.##', min: 0 }"
    >
    </dxi-column>
    <dxi-column
      alignment="center"
      dataType="string"
      caption="Status"
      dataField="statusString"
    >
    </dxi-column>
    <dxi-column alignment="center" dataType="date" dataField="date">
    </dxi-column>
  </dx-data-grid>
</div>
