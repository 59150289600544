import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import {
  ReimbursementCheckInterface,
  ReimbursementsService,
} from '../../../shared/services/reimbursements.service';
import { lastValueFrom } from 'rxjs';
import { ValueChangedEvent } from 'devextreme/ui/check_box';
import { notifyWrapper } from '../../../shared/globals/notify-wrapper';

@Component({
  selector: 'app-reimbursment-checks',
  standalone: true,
  imports: [DxButtonModule, CommonModule, DxCheckBoxModule],
  templateUrl: './checks.component.html',
  styleUrl: './checks.component.scss',
})
export class ChecksComponent implements AfterViewInit {
  loading = false;
  error: string | null = null;
  constructor(private reimbursementService: ReimbursementsService) {}
  async ngAfterViewInit() {
    console.log(this.reimbursementId);
    await this.loadChecks();
  }

  get requiredChecks() {
    return this.checks.filter((check) => check.required);
  }
  get optionalChecks() {
    return this.checks.filter((check) => !check.required);
  }

  @Input({ required: true })
  reimbursementId!: number;

  checks: ReimbursementCheckInterface[] = [];

  loadChecks = async () => {
    try {
      this.checks = await lastValueFrom(
        this.reimbursementService.getChecks(this.reimbursementId)
      );
    } catch (error) {}
  };

  async onCheckChanged(
    $event: ValueChangedEvent,
    check: ReimbursementCheckInterface
  ) {
    try {
      await lastValueFrom(
        this.reimbursementService.patchCheck(
          this.reimbursementId,
          check.id,
          $event.value
        )
      );

      check.isChecked = $event.value;
    } catch (error: any) {
      notifyWrapper(
        `Error checking that requirement! ${error.message || ''}`,
        'error'
      );
    }
  }
}
