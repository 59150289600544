import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PlaygroundService } from '../playground.service';
import { Subscription, firstValueFrom, lastValueFrom } from 'rxjs';
import { QuestionFormComponent } from '../question-form/question-form.component';
import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import debounce from 'lodash-es/debounce';
import { DxFormComponent } from 'devextreme-angular';
import { ConfirmationPopupService } from '../../../shared/components/confirmation-popup/confirmation-popup.service';
import { ISection } from '../../grants/types/IGrants';
import { IApplication } from '../../../shared/types/application.interface';
import { APPLICATION_STATUS } from '../../../shared/enums/ApplicationStatus.enum';
import CustomStore from 'devextreme/data/custom_store';
import { ApplicationsService } from '../../../shared/services';

@Component({
  selector: 'application-section-handler',
  templateUrl: 'sections-handler.component.html',
})
export class ApplicationSectionHandler implements OnInit {
  @ViewChild(QuestionFormComponent)
  formComponent?: QuestionFormComponent;

  APPLICATION_STATUS = APPLICATION_STATUS;
  formData: any;

  application?: IApplication;
  sections: ISection[] = [];
  selectedSectionId?: number;
  selectedSection?: ISection;
  _subscriptions = new Subscription();
  readOnly: boolean = false;
  serviceReadOnly: boolean = false;
  pgService: PlaygroundService;

  documentStore: CustomStore = new CustomStore({
    load: async () => {
      if (this.application?.id) {
        return await lastValueFrom(
          this.applicationService.getFiles(this.application.id)
        );
      }
      return [];
    },
  });

  constructor(
    pgService: PlaygroundService,
    private applicationService: ApplicationsService,
    private cdr: ChangeDetectorRef,
    private confirmService: ConfirmationPopupService
  ) {
    this.pgService = pgService;
  }
  ngOnInit(): void {
    this._subscriptions.add(
      this.pgService.selectedSectionId.subscribe(async (id) => {
        if (this.selectedSectionId != id) {
          this.selectedSectionId = id;
          this.selectedSection = this.sections.find((s) => s.id == id);
          this.cdr.detectChanges();
          await this.formComponent?.initForm();
          this.cdr.detectChanges();
        }
      })
    );

    this._subscriptions.add(
      this.pgService.readOnly.subscribe((v) => {
        this.serviceReadOnly = v;
      })
    );

    this._subscriptions.add(
      this.pgService.sections.subscribe((sections) => {
        this.sections = sections;
      })
    );

    this._subscriptions?.add(
      this.pgService.formData.subscribe(async (fd) => {
        this.formData = cloneDeep(fd);
        const isSelectedSectionCompleted =
          await this.pgService.isSelectedSectionCompleted();
        const isApplicationCompleted =
          await this.pgService.isApplicationCompleted();
        this.readOnly = isSelectedSectionCompleted || isApplicationCompleted;
      })
    );

    this._subscriptions.add(
      this.pgService.application.subscribe((a) => {
        this.application = a;
      })
    );
  }

  /**
   * Get questions from all sections to review
   */
  get allQuestions() {
    const questions = [];
    for (const section of this.sections) {
      for (const question of section.questions) {
        questions.push(question);
      }
    }
    return questions;
  }

  // Debounce saving form, so we dont create 10,000 instances on a slow conenction
  saveFormDataDebounced = debounce((formData?: object) => {
    this.pgService.saveForm(formData);
  }, 200);

  onFieldDataChange = async (e: any) => {
    // Check if our formData is equal to service formData
    if (
      !isEqual(this.formData, await firstValueFrom(this.pgService.formData))
    ) {
      // Update new form data
      this.pgService.setFormData(this.formData);

      // If the item is an array, save entire form so we dont have to parse (DATAFIELD[INDEX].DATAFIELD)
      if (e.dataField.includes('[') && e.dataField.includes(']')) {
        this.saveFormDataDebounced();
      } else {
        // Create a new object with just key/value of the changed state (so we can patch)
        const slimmedForm: any = {};
        slimmedForm[e.dataField] = e.value;
        this.saveFormDataDebounced(slimmedForm);
      }
    }
  };

  onFormInstanceChange = (form: DxFormComponent) => {
    this.pgService.form = form;
  };

  nextSection() {
    const index = this.sections.findIndex(
      (s) => s.id == this.selectedSection?.id
    );
    this.pgService.setSelectedSectionId(this.sections[index + 1].id);
  }
  prevSection() {
    const index = this.sections.findIndex(
      (s) => s.id == this.selectedSection?.id
    );
    this.pgService.setSelectedSectionId(this.sections[index - 1].id);
  }

  toggleSectionCompletion() {
    if (this.selectedSectionId === undefined) return;
    this.pgService.markSectionAsComplete(this.selectedSectionId);
  }

  get isSectionCompleted() {
    return this.pgService.isSectionCompleted(this.selectedSectionId);
  }

  get isNextDisabled() {
    return (
      this.sections.findIndex((a) => a.id == this.selectedSectionId) ==
      this.sections.length - 1
    );
  }
  get isPrevDisabled() {
    return this.sections.findIndex((a) => a.id == this.selectedSectionId) == 0;
  }

  async onSubmit() {
    const confirm = await lastValueFrom(
      this.confirmService.confirm(
        'Submit application?',
        'You will no longer be able to edit you application after it is submitted!',
        'danger'
      )
    );
    if (confirm) await this.pgService.submitApplication();
  }

  get canResubmit() {
    if (this.application?.resubmitDeadline) {
      const deadline = new Date(this.application.resubmitDeadline);

      if (deadline < new Date()) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  onClickDownload = ({ row }: any) => {
    window.open(row.data.presignedURL, '_blank');
  };
}
