<form class="create-account-form" (submit)="onSubmit($event)">
  <dx-form
    [formData]="formData"
    [disabled]="loading"
    [customizeItem]="customizeItem"
    labelLocation="top">
    <dxi-item
      dataField="username"
      [editorOptions]="{ placeholder: 'Username' }">
      <dxo-label [visible]="false"></dxo-label>
      <dxi-validation-rule type="required"> </dxi-validation-rule>
      <dxi-validation-rule
        message="Username cannot contain spaces!"
        type="custom"
        [validationCallback]="isSpace">
      </dxi-validation-rule>
      <dxi-validation-rule
        type="async"
        message="Username already exists!"
        [validationCallback]="usernameAlreadyExists">
      </dxi-validation-rule>
    </dxi-item>
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        dataField="password"
        editorType="dxTextBox"
        [editorOptions]="{
          stylingMode: 'filled',
          placeholder: 'Password',
          mode: 'password'
        }">
        <dxo-label [visible]="false"></dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="confirmedPassword"
        editorType="dxTextBox"
        [editorOptions]="{
          stylingMode: 'filled',
          placeholder: 'Confirm Password',
          mode: 'password'
        }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule
          type="custom"
          message="Passwords do not match"
          [validationCallback]="confirmPassword">
        </dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" caption="Contact Information" [colCount]="2">
      <dxi-item
        dataField="firstName"
        editorType="dxTextBox"
        [editorOptions]="{ stylingMode: 'filled', placeholder: 'First name' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
      <dxi-item
        dataField="lastName"
        editorType="dxTextBox"
        [editorOptions]="{ stylingMode: 'filled', placeholder: 'Last name' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
      <dxi-item
        dataField="contactInfo.emailAddress"
        editorType="dxTextBox"
        [editorOptions]="{
          stylingMode: 'filled',
          placeholder: 'Email',
          mode: 'email'
        }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="email" message="Email is invalid">
        </dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
      <dxi-item
        editorType="dxTextBox"
        dataField="contactInfo.phoneNumber"
        [editorOptions]="{ placeholder: 'Phone' }">
        <dxo-label [visible]="false"></dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    </dxi-item>
    <dxi-item
      dataField="accountType"
      editorType="dxSelectBox"
      [editorOptions]="{
        placeholder: 'Select an account type',
        dataSource: accountTypeOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        onSelectionChanged: accountTypeChange
      }">
      <dxo-label [visible]="true" text="Account Type"></dxo-label>
    </dxi-item>
    <dxi-item
      [isRequired]="true"
      *ngIf="formData.accountType == ACCOUNT_TYPE.SCHOOL"
      name="schoolList"
      helpText="You must select a school from this list to continue. If your school does not exist in this list please contact sdmi@lsu.edu">
      <div *dxTemplate>
        <app-school-search-list
          [type]="formData.accountType"
          (selectionChanged)="schoolChange($event)">
        </app-school-search-list>
      </div>
      <dxo-label text="Select a school"></dxo-label>
    </dxi-item>
    <dxi-item
      [isRequired]="true"
      *ngIf="this.formData.accountType == ACCOUNT_TYPE.DISTRICT"
      name="districtList"
      helpText="You must select a district from this list to continue. If your district does not exist in this list please contact sdmi@lsu.edu">
      <div *dxTemplate>
        <app-school-search-list
          [type]="formData.accountType"
          (selectionChanged)="schoolChange($event)">
        </app-school-search-list>
      </div>
      <dxo-label text="Select a district:"></dxo-label>
    </dxi-item>
    <dxi-item itemType="group">
      <dxi-item
        itemType="group"
        caption="Verify School Information"
        *ngIf="
          formData.school?.uniqueId &&
          formData.accountType == ACCOUNT_TYPE.SCHOOL
        ">
        <dxi-item dataField="school.about.principalName">
          <dxo-label text="Principal Name"></dxo-label>
          <dxi-validation-rule
            type="required"
            message="School Principal name is required!"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="school.about.name">
          <dxo-label text="Name"></dxo-label>
          <dxi-validation-rule
            type="required"
            message="School name is required!"></dxi-validation-rule>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
          <dxi-item dataField="school.about.addressStreet">
            <dxo-label text="Address"></dxo-label>
            <dxi-validation-rule
              type="required"
              message="School street is required!"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="school.about.addressCity">
            <dxo-label text="City"></dxo-label>
            <dxi-validation-rule
              type="required"
              message="School city is required!"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="school.about.addressState">
            <dxo-label text="State"></dxo-label>
            <dxi-validation-rule
              type="required"
              message="School state is required!"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="school.about.addressPostalCode">
            <dxo-label text="Postal Code"></dxo-label>
            <dxi-validation-rule
              type="required"
              message="School Postal Code is required!"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="school.about.phoneNumber">
            <dxo-label text="Phone"></dxo-label>
            <dxi-validation-rule
              type="required"
              message="School phone is required!"></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            name="schoolType"
            dataField="school.about.schooltype"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: ['Public', 'Private', 'Charter'],
              value: formData.school?.sps ? 'Public' : ''
            }">
            <dxo-label text="Type"></dxo-label>
            <dxi-validation-rule
              type="required"
              message="School type is required!"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>
        <dxi-item dataField="school.about.website">
          <dxo-label text="Website"></dxo-label>
        </dxi-item>
        <dxi-item>
          <div class="policy-info">
            <b>Note:</b> Changes to your selected school may need additional
            verification that may take 24-48 hours to show in your account.
          </div>
        </dxi-item>
      </dxi-item>
      <dxi-item
        itemType="group"
        caption="Enter Organization Information"
        [colCount]="2"
        *ngIf="formData.accountType == ACCOUNT_TYPE.NON_PROFIT">
        <dxi-item dataField="organization.name">
          <dxo-label text="Name"></dxo-label>
        </dxi-item>
        <dxi-item dataField="organization.ein">
          <dxo-label text="EIN"></dxo-label>
        </dxi-item>
        <dxi-item dataField="organization.address">
          <dxo-label text="Address"></dxo-label>
        </dxi-item>
        <dxi-item dataField="organization.city">
          <dxo-label text="City"></dxo-label>
        </dxi-item>
        <dxi-item dataField="organization.state">
          <dxo-label text="State"></dxo-label>
        </dxi-item>
        <dxi-item dataField="organization.postalCode">
          <dxo-label text="Postal Code"></dxo-label>
        </dxi-item>
        <dxi-item dataField="organization.contactInfo.phoneNumber">
          <dxo-label text="Phone Number"></dxo-label>
        </dxi-item>
        <dxi-item dataField="organization.contactInfo.emailAddress">
          <dxo-label text="Email"></dxo-label>
        </dxi-item>
        <dxi-item [colSpan]="2">
          <div class="policy-info">
            <b>Note:</b> Some details may required additional verification
            before grants can be applied for.
          </div>
        </dxi-item>
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="button">
      <dxo-button-options
        width="100%"
        type="default"
        [useSubmitBehavior]="true"
        [template]="'createAccountTemplate'">
      </dxo-button-options>
    </dxi-item>
    <dxi-item>
      <div class="login-link">
        Have an account? <a routerLink="/login">Sign In</a>
      </div>
    </dxi-item>
    <ng-container *dxTemplate="let item of 'createAccountTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="loading; else notLoading">
            <dx-load-indicator
              width="24px"
              height="24px"
              [visible]="true"></dx-load-indicator>
          </ng-container>
          <ng-template #notLoading>Create a new account</ng-template>
        </span>
      </div>
    </ng-container>
  </dx-form>
</form>
