<app-nav-header
  [title]="reimbursement?.isReconciliation ? 'Reconciliation' : 'Reimbursement'"
></app-nav-header>
<div class="container">
  <div
    class="box left"
    *ngIf="
      reimbursement &&
      reimbursement.status < REIMBURSEMENT_STATUS['Funding Approved']
    "
  >
    <p *ngIf="!application">Loading application...</p>
    <app-view-application
      *ngIf="this.grant && this.application"
      pageKey="Reimbursement"
      [application]="application"
      [grant]="grant"
    >
    </app-view-application>
  </div>
  <div class="box right">
    <h5 *ngIf="error" style="color: red">{{ error }}</h5>
    <div *ngIf="!error && this.reimbursement">
      <div *ngIf="!loadingExistingReimbursements">
        <h4 style="margin: 0px">Existing Reimbursements</h4>
        <dx-data-grid
          (onCellPrepared)="onCellPrepared($event)"
          [dataSource]="existingReimbursements ?? []"
          [rowAlternationEnabled]="true"
        >
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxi-column
            width="100%"
            [minWidth]="150"
            alignment="center"
            caption="Type"
            [calculateCellValue]="getREType"
            [allowFiltering]="true"
          >
            <dxo-lookup
              [dataSource]="['Reimbursement', 'Advance', 'Reconciliation']"
            ></dxo-lookup>
          </dxi-column>
          <dxi-column
            width="auto"
            alignment="center"
            dataType="string"
            caption="Status"
            dataField="statusString"
          >
          </dxi-column>
          <dxi-column
            width="auto"
            [allowEditing]="false"
            alignment="center"
            dataField="reimbursementIdentification"
            caption="Reimbursement Number"
          ></dxi-column>
          <dxi-column
            alignment="center"
            width="auto"
            dataField="totalRequested"
            caption="Requested"
            dataType="number"
            format="currency"
            [editorOptions]="{ format: '$ #,##0.##', min: 0 }"
          >
          </dxi-column>
          <dxi-column
            alignment="center"
            width="auto"
            dataField="totalApproved"
            caption="Approved"
            alignment="center"
            dataType="number"
            format="currency"
            [editorOptions]="{ format: '$ #,##0.##', min: 0 }"
          >
          </dxi-column>
          <dxi-column
            alignment="center"
            caption="Paid"
            alignment="center"
            dataType="number"
            format="currency"
            dataField="totalPaid"
            width="auto"
            minWidth="200px"
            [calculateCellValue]="howMuchPaid"
            [editorOptions]="{ format: '$ #,##0.##', min: 0 }"
          >
          </dxi-column>
          <dxi-column type="buttons">
            <dxi-button
              [visible]="isButtonVisible"
              icon="eyeopen"
              text="View"
              [onClick]="this.onViewReimbursementClick"
            >
            </dxi-button>
          </dxi-column>
          <dxo-summary>
            <dxi-total-item
              width="auto"
              column="totalRequested"
              summaryType="sum"
              valueFormat="currency"
              displayFormat="Total: {0}"
            ></dxi-total-item>
            <dxi-total-item
              width="auto"
              column="totalApproved"
              summaryType="sum"
              valueFormat="currency"
              displayFormat="Total: {0}"
            ></dxi-total-item>
            <dxi-total-item
              width="auto"
              column="totalPaid"
              summaryType="sum"
              valueFormat="currency"
              displayFormat="Total: {0}"
            ></dxi-total-item>
          </dxo-summary>
        </dx-data-grid>
      </div>
      <div *ngIf="loadingExistingReimbursements">
        Loading existing reimbursements...
      </div>
      <br />
      <dx-drop-down-button
        noDataText="No actions available"
        type="default"
        text="Actions"
        [dropDownOptions]="{ width: 200 }"
        [items]="dropDownButtonActions"
      >
      </dx-drop-down-button>

      <p style="font-size: large; text-align: center">
        Status: {{ reimbursement.statusString }}
      </p>
      <p
        style="font-size: medium; text-align: center"
        *ngIf="reimbursement.reason"
      >
        Reason: {{ reimbursement.reason }}
      </p>

      <div
        *ngIf="
          (reimbursement.status ===
            REIMBURSEMENT_STATUS['Submitted - Pending Review'] ||
            reimbursement.status ===
              REIMBURSEMENT_STATUS['Sent Back - Pending Review']) &&
          ability?.can(
            PermissionAction.CAN,
            PermissionItem.CAN_REVIEW_REIMBURSEMENT
          )
        "
      >
        <h4 style="margin: 0">Eligibility</h4>
        <p>
          Please review the following checks to continue with the reimbursement
          process:
        </p>
        <app-reimbursment-checks
          [reimbursementId]="reimbursement.id"
        ></app-reimbursment-checks>
        <br />
      </div>
      <h4 style="margin: 0px">
        {{
          this.reimbursement.isReconciliation
            ? "Reconciliation"
            : "Reimbursement"
        }}
      </h4>
      <app-reimbursement-form
        [readonly]="true"
        [formdata]="this.reimbursement"
      ></app-reimbursement-form>
      <dx-text-box
        [disabled]="this.reimbursement.status >= REIMBURSEMENT_STATUS.Invoiced"
        label="PO Number:"
        [value]="this.reimbursement.purchaseOrderNumber ?? ''"
        (onValueChanged)="updatePO($event)"
      ></dx-text-box>
      <dx-tab-panel>
        <dxi-item title="Invoices">
          <div *dxTemplate>
            <dx-data-grid
              [rowAlternationEnabled]="true"
              (onRowUpdating)="onRowUpdating($event)"
              [dataSource]="this.reimbursement.vendorInvoices || []"
            >
              <dxo-editing [allowUpdating]="true" mode="batch"></dxo-editing>
              <dxo-toolbar>
                <dxi-item location="before">
                  <div class="data-grid-header">Vendor Invoices</div>
                </dxi-item>
                <dxi-item
                  *ngIf="
                    this.reimbursement.status <=
                      REIMBURSEMENT_STATUS['Sent Back - Pending Review'] &&
                    this.reimbursement.status >=
                      REIMBURSEMENT_STATUS['Submitted - Pending Review']
                  "
                  name="saveButton"
                ></dxi-item>
                <dxi-item
                  *ngIf="
                    this.reimbursement.status <=
                      REIMBURSEMENT_STATUS['Sent Back - Pending Review'] &&
                    this.reimbursement.status >=
                      REIMBURSEMENT_STATUS['Submitted - Pending Review']
                  "
                  name="revertButton"
                ></dxi-item>
              </dxo-toolbar>
              <dxi-column
                alignment="center"
                [allowEditing]="false"
                dataField="vendorName"
              ></dxi-column>
              <dxi-column
                alignment="center"
                [allowEditing]="false"
                dataField="invoiceNumber"
              ></dxi-column>
              <dxi-column
                alignment="center"
                [allowEditing]="false"
                caption="Category"
                dataField="budget.category"
              ></dxi-column>
              <dxi-column
                alignment="center"
                [allowEditing]="false"
                caption="Description"
                dataField="budget.description"
              ></dxi-column>
              <dxi-column
                [format]="{
                  type: 'currency',
                  precision: 2
                }"
                alignment="center"
                [allowEditing]="false"
                format="currency"
                [editorOptions]="{ format: '$ #,##0.##', min: 0 }"
                dataField="amountRequested"
              ></dxi-column>
              <dxi-column
                alignment="center"
                format="currency"
                [format]="{
                  type: 'currency',
                  precision: 2
                }"
                [editorOptions]="{ format: '$ #,##0.##', min: 0 }"
                [allowEditing]="
                  this.reimbursement.status <=
                    REIMBURSEMENT_STATUS['Sent Back - Pending Review'] &&
                  this.reimbursement.status >=
                    REIMBURSEMENT_STATUS['Submitted - Pending Review']
                "
                dataType="number"
                caption="Amount Eligible"
                dataField="amountApproved"
              >
              </dxi-column>
              <dxo-master-detail
                [autoExpandAll]="false"
                [enabled]="true"
                template="detail"
              ></dxo-master-detail>
              <div *dxTemplate="let row of 'detail'">
                <app-reimbursement-files
                  [reimbursementId]="reimbursement.id"
                  [invoiceId]="row.data.id"
                >
                </app-reimbursement-files>
              </div>
            </dx-data-grid>
          </div>
        </dxi-item>

        <dxi-item title="Attachments">
          <div *dxTemplate>
            <app-reimbursement-files
              [reimbursementId]="reimbursement.id"
            ></app-reimbursement-files>
          </div>
        </dxi-item>
        <dxi-item title="Comments">
          <div *dxTemplate>
            <app-reimbursement-comments
              [reimbursementId]="reimbursement.id"
            ></app-reimbursement-comments>
          </div>
        </dxi-item>
        <dxi-item title="History">
          <div *dxTemplate>
            <app-status-history
              [reimbursementId]="reimbursement.id"
            ></app-status-history>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </div>
  </div>
</div>

<app-send-back-popup></app-send-back-popup>
<app-issue-check-popup></app-issue-check-popup>
<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
