

<span *ngIf="selectedSection">
  <dx-button
    *ngIf="application?.isCompleted != true"
    (onClick)="this.toggleSectionCompletion()"
    type="success"
    [icon]="isSectionCompleted ? 'edit' : 'check'"
    [text]="isSectionCompleted ? 'Make Changes' : 'Complete Section'">
  </dx-button>
  <dx-button
    [disabled]="isPrevDisabled"
    (onClick)="prevSection()"
    icon="arrowleft"
    text="Previous"></dx-button>
  <dx-button
    [disabled]="isNextDisabled"
    icon="arrowright"
    [rtlEnabled]="true"
    (onClick)="nextSection()"
    text="Next"></dx-button>
</span>
<application-saved-status
  *ngIf="application?.isCompleted != true"></application-saved-status>
