import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { notifyWrapper } from '../../shared/globals/notify-wrapper';
import { UserService } from '../../shared/services/user.service';
import { lastValueFrom } from 'rxjs';
import { IUser } from '../../shared/types/IUser';
import { ValidationCallbackData } from 'devextreme/common';
import { AuthService } from '../../shared/services';

@Component({
  selector: 'app-accept-invite-page',
  templateUrl: 'accept-invite.component.html',
  styleUrl: 'accept-invite.component.scss',
})
export class AcceptInviteComponent implements OnInit {
  token?: string;
  userId?: number;
  errorMessage?: string;

  user?: Partial<IUser> = {};
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) {}

  async loadUser(id: number, token: string) {
    try {
      this.user = await lastValueFrom(
        this.userService.getUserByIdWithToken(id, token)
      );
    } catch (e: any) {
      this.errorMessage = e.error.message;
    }
  }

  async ngOnInit() {
    const userId = this.route.snapshot.paramMap.get('id');

    const token = this.route.snapshot.queryParamMap.get('token');
    if (!token) {
      this.errorMessage = 'No token provided!';
      return;
    }

    if (!userId) {
      this.errorMessage = 'No user id param!';
      return;
    }

    this.userId = parseInt(userId);
    this.token = token;

    await this.loadUser(parseInt(userId), token);
  }

  get error() {
    return !!this.errorMessage;
  }

  confirmPassword = (e: ValidationCallbackData) => {
    return e.value === this.user?.password;
  };

  async submit(e: any) {
    e.preventDefault();
    if (!this.userId || !this.user || !this.token) return;

    try {
      const res = await lastValueFrom(
        this.userService.acceptInvite(this.userId, this.user, this.token)
      );
      notifyWrapper(
        'Successfully activated account! You may now login!',
        'success'
      );

      this.router.navigate(['auth/login']);
    } catch (e: any) {
      notifyWrapper(e.error.message, 'error');
    }
  }
}
