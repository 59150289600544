import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavHeaderComponent } from './nav-header.component';
import { DxButtonModule } from 'devextreme-angular';

@NgModule({
  imports: [CommonModule, DxButtonModule],
  declarations: [NavHeaderComponent],
  exports: [NavHeaderComponent],
})
export class NavHeaderModule {}
