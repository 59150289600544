import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GrantsService } from '../../../shared/services';
import { lastValueFrom } from 'rxjs';
import { IGrant } from '../../grants/types/IGrants';
import { notifyWrapper } from '../../../shared/globals/notify-wrapper';
import { ConfirmationPopupService } from '../../../shared/components/confirmation-popup/confirmation-popup.service';

@Component({
  templateUrl: './grant.component.html',
  styleUrls: ['./grant.component.scss'],
})
export class GrantPageComponent implements OnInit {
  grantId!: number;
  grant?: IGrant;

  errorMessage: string | undefined = undefined;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _grantService: GrantsService
  ) {}

  async ngOnInit() {
    this.grantId = Number(
      this._activatedRoute.snapshot.paramMap.get('grantId')
    );

    await this.loadGrant();
  }

  async loadGrant() {
    try {
      this.grant = await lastValueFrom(
        this._grantService.getGrant(this.grantId)
      );
      console.log(this.grant);
    } catch (error: any) {
      this.errorMessage = error;
      notifyWrapper(`Error loading grant!`, 'error');
    }
  }
}
