<app-nav-header [title]="grant?.name ?? 'Grant'"></app-nav-header>
@if (errorMessage) {
<div>{{errorMessage}}</div>
}
@else if(grant) {
<div class="container">
  <h5>{{grant.description}}</h5>
  <dx-tab-panel>
    <dxi-item title="Evaluator Groups">
      <div *dxTemplate>
        <app-grant-eval-groups [grantId]="grant.id"></app-grant-eval-groups>
      </div>
    </dxi-item>
    <dxi-item title="Application Review Checks">
      <div *dxTemplate>
        <app-grant-app-checks [grantId]="grant.id"></app-grant-app-checks>
      </div>
    </dxi-item>
    <dxi-item title="Application Evaluation Rubric">
      <div *dxTemplate>
        <app-grant-eval-criteria [grantId]="grant.id"></app-grant-eval-criteria>
      </div>
    </dxi-item>
  </dx-tab-panel>
</div>
}
