import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrantsComponent } from './grants.component';
import { GrantsDataGridComponent } from './components/grants-data-grid/grants-data-grid.component';
import {
  DxAccordionModule,
  DxButtonModule,
  DxDataGridModule,
  DxListModule,
} from 'devextreme-angular';
import { GrantsAccordionComponent } from './components/grants-accordion/grants-accordion.component';
import { GrantsDescriptionComponent } from './components/grants-accordion/components/description/description.component';
@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxListModule,
    DxAccordionModule,
    DxButtonModule,
  ],
  declarations: [
    GrantsComponent,
    GrantsDataGridComponent,
    GrantsAccordionComponent,
    GrantsDescriptionComponent,
  ],
  exports: [
    GrantsComponent,
    GrantsDataGridComponent,
    GrantsAccordionComponent,
    GrantsDescriptionComponent,
  ],
})
export class GrantsModule {}
