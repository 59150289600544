import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignupForm } from '../components';
import { environment } from '../../../environments/environment';
import { IUser } from '../types/IUser';
import { IUserRolePermission } from '../types/user-role-permission.interface';
import { head } from 'lodash';
import { DeepPartial } from 'devextreme/core';
import { catchError } from 'rxjs';
import { handleHttpError } from '../http.error-handler';

@Injectable({ providedIn: 'root' })
export class UserService {
  baseUrl = environment.baseApiUrl + '/user';
  constructor(private _httpClient: HttpClient) {}

  createAccount(newUser: SignupForm) {
    return this._httpClient.post(this.baseUrl, newUser, {
      headers: { AUTH_ROUTE: '0' },
    });
  }

  getUserById(id: number) {
    let headers = {};
    let params = '';
    return this._httpClient
      .get<IUser>(`${this.baseUrl}/${id}?${params}`, {
        headers,
      })
      .pipe(catchError(handleHttpError<IUser>));
  }

  getUserByIdWithToken(id: number, token?: string) {
    let headers = {};
    let params = '';
    if (token) {
      headers = { AUTH_ROUTE: '0' };
      params = `token=${token}`;
    }
    return this._httpClient
      .get<IUser>(`${this.baseUrl}/${id}/token?${params}`, {
        headers,
      })
      .pipe(catchError(handleHttpError<IUser>));
  }

  doesUserExist(username: string) {
    return this._httpClient
      .get<{ exists: boolean }>(`${this.baseUrl}/doesUserExist`, {
        headers: { AUTH_ROUTE: '0' },
        params: { username },
      })
      .pipe(catchError(handleHttpError<{ exists: boolean }>));
  }

  patchUser(id: number, user: DeepPartial<IUser>) {
    return this._httpClient
      .patch<IUser>(`${this.baseUrl}/${id}`, user)
      .pipe(catchError(handleHttpError<IUser>));
  }

  deleteUser(id: number) {
    return this._httpClient
      .delete(`${this.baseUrl}/${id}`)
      .pipe(catchError(handleHttpError));
  }

  getStaff() {
    return this._httpClient
      .get<IUser>(`${this.baseUrl}/staff`)
      .pipe(catchError(handleHttpError<IUser>));
  }

  getOptions() {
    return this._httpClient
      .get<IUserOptions>(`${this.baseUrl}/options`)
      .pipe(catchError(handleHttpError<IUserOptions>));
  }

  inviteUser(user: Partial<IUser>) {
    return this._httpClient
      .post<IUser>(`${this.baseUrl}/invite`, user)
      .pipe(catchError(handleHttpError<IUser>));
  }

  reinviteUser(id: number) {
    return this._httpClient
      .post<IUser>(`${this.baseUrl}/${id}/reinvite`, {})
      .pipe(catchError(handleHttpError<IUser>));
  }

  acceptInvite(id: number, user: Partial<IUser>, token: string) {
    return this._httpClient
      .post<IUser>(`${this.baseUrl}/${id}/accept?token=${token}`, user, {
        headers: { AUTH_ROUTE: '0' },
      })
      .pipe(catchError(handleHttpError<IUser>));
  }
}

export interface IUserOptions {
  roles: { name: string; id: number }[];
}
