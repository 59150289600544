/**
 * Be sure to update this enum on frontend and backend
 */
export enum PermissionItem {
  ALL = 'all',
  APPLICATIONS_PAGE = 'Applications Page',
  GRANTS_PAGE = 'Grants Page',
  USERS_PAGE = 'Users Page',
  CAN_BE_APPLICATION_REVIEWER = 'Can be Application Reviewer',
  CAN_BE_APPLICATION_EVALUATOR = 'Can be Application Evaluator',
  REIMBURSEMENTS = 'Reimbursements',
  CAN_REVIEW_REIMBURSEMENT = 'Can be Reimbursement Reviewer',
  CAN_ASSIGN_REIMBURSEMENT = 'Can be Reimbursement Assigner', // Assigns money to reimbursement
  CAN_APPROVE_REIMBURSEMENT = 'Can be Reimbursement Approver',
  CAN_QA_REIMBURSEMENT = `Can be Reimbursement QA/QC'er`,
  CAN_FUND_REIMBURSEMENT = 'Can Fund a Reimbursement',
  CAN_MARK_REIMBURSEMENT_PAID = 'Can Mark Reimbursement as Paid',
}
