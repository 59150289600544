<app-admin-invite-user-popup *ngIf="showInvitePopup"
  [(visible)]="showInvitePopup" />
<div class="admin-data-grid-container">
  <dx-data-grid
    id="data-grid"
    [rowAlternationEnabled]="true"
    [dataSource]="staffDataSource"
    [showBorders]="true"
    [focusedRowEnabled]="false"
    [columnAutoWidth]="true"
    (onCellPrepared)="onCellPrepared($event)"
    [editing]="{
      mode: 'form',
      allowUpdating: true,
      allowAdding: true,
      allowDeleting: false
    }">
    <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-paging [pageSize]="50"> </dxo-paging>
    <dxo-pager
      [visible]="true"
      [allowedPageSizes]="[50, 100, 250, 500]"
      displayMode="full"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [showNavigationButtons]="true">
    </dxo-pager>
    <dxo-toolbar>
      <dxi-item location="before">
        <div class="data-grid-header">Staff</div>
      </dxi-item>
      <dxi-item name="revertButton" locateInMenu="auto"></dxi-item>
      <dxi-item name="saveButton" locateInMenu="auto"></dxi-item>
      <dxi-item location="after">
        <dx-button text="Invite" icon="plus"
          (onClick)="showInvitePopup = true"></dx-button>
      </dxi-item>
    </dxo-toolbar>
    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxi-column dataField="firstName">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="lastName">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="username">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column
      dataField="contactInfo.emailAddress"
      caption="Email Address">
      <dxi-validation-rule type="email"></dxi-validation-rule>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column
      dataField="contactInfo.phoneNumber"
      caption="Phone">
    </dxi-column>
    <dxi-column
      dataField="roleId"
      caption="Role">
      <dxo-lookup [dataSource]="options?.roles" displayExpr="name"
        valueExpr="id"></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="isAccepted"
      [allowEditing]="false"
      caption="Accepted">
    </dxi-column>
    <dxi-column type="buttons">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
      <dxi-button
        hint="Resend invite"
        cssClass="resendInviteBtn"
        [onClick]="onResendInviteClick"
        icon="arrowback"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
