/**
 * Be sure to update this enum on frontend and backend
 */
export enum PermissionAction {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  MANAGE = 'manage',
  CAN = 'can',
}
