import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchoolsService {
  constructor(private httpClient: HttpClient) {}

  schoolStore: CustomStore = new CustomStore({
    load: async (o) =>
      await lastValueFrom(
        this.searchSchoolsByName(o.searchValue ?? '', o.skip, o.take)
      ),
    key: 'uniqueId',
  });

  districtStore: CustomStore = new CustomStore({
    load: async (o) =>
      await lastValueFrom(
        this.searchDistrictsByName(o.searchValue ?? '', o.skip, o.take)
      ),
    key: 'uniqueId',
  });

  baseUrl = environment.baseApiUrl + '/schools';

  searchSchoolsByName(name: string, skip?: number, take?: number) {
    return this.httpClient.get<School[]>(`${this.baseUrl}`, {
      params: {
        name,
        skip: skip ?? 0,
        take: take ?? 20,
        entityType: 'school',
      },
      headers: { AUTH_ROUTE: '0' }, // Interceptor reads this and wont treat as an authenticated route
    });
  }

  searchDistrictsByName(name: string = '', skip?: number, take?: number) {
    return this.httpClient.get<School[]>(`${this.baseUrl}`, {
      params: {
        name,
        skip: skip ?? 0,
        take: take ?? 20,
        entityType: 'district',
      },
      headers: { AUTH_ROUTE: '0' }, // Interceptor reads this and wont treat as an authenticated route
    });
  }
}

export interface School {}
