<div class="content-block">
  <div class="title">
    Welcome to the Louisiana Center for Safe Schools (LCSS)
  </div>
  <div class="subtitle">
    Care of: Louisiana Governor’s Office of Homeland Security and Emergency
    Preparedness (GOHSEP)
  </div>
  <div class="bold-subtitle">Grant Management Professional (GMPro)</div>
</div>
<div class="content-block">
  <div class="centered-content">
    <app-lcss-logo [imageWidth]="425" [imageHeight]="300" />
  </div>
</div>
<div class="content-block">
  <div class="inline-centered-content">
    <app-announcement-image [imageWidth]="80" [imageHeight]="80" />
    <div class="title">
      The 2024 Louisiana School and Nonprofit Security Grant (LSNSGP) is no
      longer open for submissions!
    </div>
    <app-announcement-image [imageWidth]="80" [imageHeight]="80" />
  </div>
</div>
<!-- <div class="content-block">
  <div class="inline-centered-content">
    <dx-button
      (onClick)="onApplyClick()"
      text="Apply"
      width="200"
      height="50"></dx-button>
  </div>
</div> -->
