import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
} from 'devextreme-angular';
import {
  ReimbursementCommentInterface,
  ReimbursementsService,
} from '../../../shared/services/reimbursements.service';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { AppReimbursementsService } from '../../../shared/services/application/app-reimbursement.service';
import { CellPreparedEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { UserService } from '../../../shared/services/user.service';
import { AuthService } from '../../../shared/services';
import * as $ from 'jquery';

@Component({
  selector: 'app-reimbursement-comments',
  standalone: true,
  imports: [CommonModule, DxDataGridModule, DxButtonModule],
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss',
})
export class ReimbursementCommentsComponent implements AfterViewInit {
  onCellPrepared(cell: any) {
    if (
      cell.data &&
      cell.data.userId &&
      cell.cellElement &&
      cell.cellElement[0]
    ) {
      if (cell.data.userId != this.currentUserId) {
        const items: HTMLElement[] =
          cell.cellElement[0].querySelectorAll('.dx-link');
        items.forEach((item: HTMLElement) => {
          if (item.parentNode) {
            item.parentNode.removeChild(item);
          }
        });
      }
    }
  }
  @ViewChild(DxDataGridComponent)
  dataGrid?: DxDataGridComponent;

  // onRowPrepared(event: RowPreparedEvent<ReimbursementCommentInterface>) {
  //   if (event.rowType == 'data' && this.currentUserId && !event.isNewRow) {
  //     if (event.data.userId != this.currentUserId) {
  //       const element = event.rowElement as any as HTMLElement;
  //       $(element).find('.dx-command-edit.dx-link').remove();
  //     }
  //   }
  // }
  @Input()
  applicationId?: number;
  @Input()
  awardId?: number;
  @Input({ required: true })
  reimbursementId!: number;

  private currentUserId?: number = this.authService.getUserId();

  store: CustomStore<ReimbursementCommentInterface> = new CustomStore();
  constructor(
    private readonly appReimbursementService: AppReimbursementsService,
    private readonly reimbursementService: ReimbursementsService,
    private readonly authService: AuthService
  ) {}

  async ngAfterViewInit() {
    let i = 0;

    const interval = setInterval(() => {
      if (i == 10) clearInterval(interval);
      i++;
      this.dataGrid?.instance.repaint();
      console.log('repainting');
    }, 100);

    if (!this.applicationId) {
      this.store = new CustomStore({
        key: 'id',
        load: async () =>
          await lastValueFrom(
            this.reimbursementService.getComments(this.reimbursementId)
          ),
        update: async (key, values) =>
          await lastValueFrom(
            this.reimbursementService.editComment(
              this.reimbursementId,
              key,
              values
            )
          ),
        remove: async (key) => {
          await lastValueFrom(
            this.reimbursementService.deleteComment(this.reimbursementId, key)
          );
        },
        insert: async (values) =>
          await lastValueFrom(
            this.reimbursementService.addComment(this.reimbursementId, values)
          ),
      });
    } else if (this.applicationId && this.awardId) {
      const appId = this.applicationId;
      const awdId = this.awardId;
      this.store = new CustomStore({
        key: 'id',
        load: async () =>
          await lastValueFrom(
            this.appReimbursementService.getComments(
              appId,
              awdId,
              this.reimbursementId
            )
          ),
        update: async (key, values) =>
          await lastValueFrom(
            this.appReimbursementService.editComment(
              appId,
              awdId,
              this.reimbursementId,
              key,
              values
            )
          ),
        insert: async (values) =>
          await lastValueFrom(
            this.appReimbursementService.createComment(
              appId,
              awdId,
              this.reimbursementId,
              values
            )
          ),
        remove: async (key) => {
          await lastValueFrom(
            this.appReimbursementService.deleteComment(
              appId,
              awdId,
              this.reimbursementId,
              key
            )
          );
        },
      });
    }
  }
  onEditorPreparing(e: any) {
    if (e.parentType == 'dataRow') {
      if (e.dataField == 'text') {
        e.editorName = 'dxTextArea';
      }
    }
  }

  onInitRow(e: any) {
    e.data.internalOnly = true;
  }
}
