import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs';
import { IApplication } from '../../types/application.interface';
import { handleHttpError } from '../../http.error-handler';
import { ApplicationRejectBody } from '../../types/application-reject.interface';
import { ApplicationReviewBody } from '../../types/application-review.interface';
import { ApplicationsService } from './application.service';
import { IGrant } from '../../../pages/grants/types/IGrants';

@Injectable({
  providedIn: 'root',
})
export class ApplicationReviewService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/review';

  constructor(
    private _httpClient: HttpClient,
    private applicationService: ApplicationsService
  ) {}

  getBaseURL(applicationId: number = 0) {
    return `${this.applicationService.getBaseURL()}/${applicationId}${
      this.path
    }`;
  }

  getReviewDetails(applicationId: number) {
    return this._httpClient
      .get<ReviewDetailResponse>(this.getBaseURL(applicationId))
      .pipe(catchError(handleHttpError<ReviewDetailResponse>));
  }

  rejectApplication(id: number, body: ApplicationRejectBody) {
    return this._httpClient
      .post<IApplication>(`${this.getBaseURL(id)}/markAsRejected`, body)
      .pipe(catchError(handleHttpError<IApplication>));
  }

  reviewApplication(id: number, body: ApplicationReviewBody) {
    return this._httpClient
      .post<IApplication>(`${this.getBaseURL(id)}/markAsReviewed`, body)
      .pipe(catchError(handleHttpError<IApplication>));
  }
}

export type ReviewDetailResponse = {
  application: IApplication;
  grant: IGrant;
};
