import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { notifyWrapper } from 'src/app/shared/globals/notify-wrapper';
import { AuthService } from 'src/app/shared/services';
import { UserService } from 'src/app/shared/services/user.service';
import { IUser } from '../../shared/types/IUser';
import { DeepPartial } from 'devextreme/core';

@Component({
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userId?: number;

  previousUser?: IUser;
  user: DeepPartial<IUser> & { newPassword?: string } = {};

  defaultEditorOptions = { stylingMode: 'underlined' };

  isEditing = false;

  loading = false;

  constructor(
    private _userService: UserService,
    private _authService: AuthService
  ) {}

  async ngOnInit() {
    this.userId = this._authService.getUserId();
    this.loading = true;
    try {
      if (!this.userId) return;
      this.user = await lastValueFrom(
        this._userService.getUserById(this.userId)
      );
      console.log(this.user);
    } catch (error) {
      notifyWrapper('Error loading user!', 'error');
    } finally {
      this.loading = false;
    }
  }

  onClickEdit() {
    if (this.user) this.previousUser = { ...this.user } as any;
    this.isEditing = true;
  }

  async onClickSave(e: Event) {
    e.preventDefault();
    if (this.user) {
      this.loading = true;
      try {
        if (!this.userId) return;
        if (this.user.newPassword && this.user.newPassword.trim().length > 0) {
          this.user.password = this.user.newPassword;
        } else {
          this.user.password = undefined;
        }
        await lastValueFrom(
          this._userService.patchUser(this.userId, this.user)
        );
        await this._authService.refreshAccessToken();

        this.user.newPassword = undefined;
        notifyWrapper(
          `Successfully updated ${this.user.firstName}!`,
          'success'
        );
      } catch (e: any) {
        if (e) notifyWrapper(`${e}`, 'error');
        else notifyWrapper(`Error occured during saving!`, 'error');
      } finally {
        this.loading = false;
      }
    }
  }
}
