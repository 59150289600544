<h2 class="content-block">Profile</h2>

<div class="content-block dx-card responsive-paddings">
  <dx-scroll-view>
    <form (submit)="onClickSave($event)">
      <dx-form
        id="form"
        [formData]="user"
        labelLocation="top">
        <dxi-item dataField="username">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="newPassword" [editorOptions]="{mode: 'password'}">
        </dxi-item>
        <dxi-item dataField="firstName">
          <dxi-validation-rule type="required"></dxi-validation-rule>

        </dxi-item>
        <dxi-item dataField="lastName">
          <dxi-validation-rule type="required"></dxi-validation-rule>

        </dxi-item>
        <dxi-item dataField="contactInfo.emailAddress">
          <dxo-label text="Email"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="contactInfo.phoneNumber">
          <dxo-label text="Phone"></dxo-label>
        </dxi-item>
        <dxi-item itemType="button">
          <dxo-button-options width="100%" type="default"
            [useSubmitBehavior]="true"
            [template]="'submitBtnTemplate'">
          </dxo-button-options>
        </dxi-item>

        <ng-container *dxTemplate="let item of 'submitBtnTemplate'">
          <div>
            <span class="dx-button-text">
              <ng-container *ngIf="loading; else notLoading">
                <dx-load-indicator width="24px" height="24px"
                  [visible]="true"></dx-load-indicator>
              </ng-container>
              <ng-template #notLoading>Submit</ng-template>
            </span>
          </div>
        </ng-container>
      </dx-form>
    </form>

  </dx-scroll-view>
</div>
