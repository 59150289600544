<h4 style="margin: 0px;">Documents</h4>
<dx-data-grid [dataSource]="documentStore"
  [editing]="{allowDeleting: true}">
  <dxi-column dataField="fileName"></dxi-column>
  <dxi-column dataField="tagName"></dxi-column>
  <dxi-column type="buttons">
    <dxi-button
      hint="Download"
      icon="download"
      [onClick]="_onClickDownload"></dxi-button>
    <dxi-button name="delete" [visible]="canDelete"></dxi-button>
  </dxi-column>
</dx-data-grid>
<br />
<form style="margin: auto;" (submit)="onFormSubmit($event)">
  <dx-form [formData]="formData" [colCount]="2">
    <dxi-item
      [colSpan]="1"
      editorType="dxFileUploader"
      dataField="files"
      [editorOptions]="{uploadMode: 'useForm'}"
      [label]="{text: 'Upload Additional Documents'}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      dataField="tag"
      [colSpan]="1"
      [label]="{text: 'File Category'}"
      editorType="dxSelectBox"
      [editorOptions]="{dataSource: ['Other', 'Procurement Policy']}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      [colSpan]="2"
      itemType="button"
      [buttonOptions]="{text: 'Upload', disabled: !canUpload, useSubmitBehavior: true}">
    </dxi-item>
  </dx-form>
</form>
