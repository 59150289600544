<dx-popup
  width="500px"
  height="400px"
  [visible]="visible"
  title="Invite User"
  (onHidden)="closePopup()">
  <div>
    <form (submit)="onSubmit($event)">
      <dx-form [(formData)]="user" [showValidationSummary]="true">
        <dxi-item dataField="username">
          <dxi-validation-rule
            type="required"
            message="Username is required!"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="firstName">
          <dxi-validation-rule
            type="required"
            message="First name is required!"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="lastName">
          <dxi-validation-rule type="required"
            message="Last name is required!"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="contactInfo.emailAddress">
          <dxo-label text="Email"></dxo-label>
          <dxi-validation-rule type="required"
            message="Email name is required!"></dxi-validation-rule>
          <dxi-validation-rule type="email"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="contactInfo.phoneNumber">
          <dxo-label text="Phone"></dxo-label>
        </dxi-item>
        <dxi-item dataField="roleId" editorType="dxSelectBox"
          [editorOptions]="{dataSource: options?.roles, valueExpr: 'id', displayExpr: 'name'}">
          <dxo-label text="Role"></dxo-label>
          <dxi-validation-rule type="required"
            message="Role is required!"></dxi-validation-rule>
        </dxi-item>
        <dxi-item itemType="button" [buttonOptions]="submitButtonOptions">
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
