 <div class="admin-data-grid-container">
  <dx-data-grid
    id="data-grid"
    [dataSource]="applicationsDataSource"
    [showBorders]="true"
    [focusedRowEnabled]="false"
    (onExporting)="onExporting($event)"
    [columnAutoWidth]="true"
    [columnFixing]="{enabled: true}"
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [columnChooser]="{enabled: true}"
    (onCellPrepared)="onCellPrepared($event)">
    <dxo-toolbar>
      <dxi-item location="before">
        <div class="data-grid-header">Applications</div>
      </dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item location="auto" name="columnChooserButton">
      </dxi-item>
    </dxo-toolbar>
    <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-paging [pageSize]="50"> </dxo-paging>
    <dxo-pager
      [visible]="true"
      [allowedPageSizes]="[50, 100, 250, 500]"
      displayMode="full"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [showNavigationButtons]="true">
    </dxo-pager>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="applicationsPageV0.0.04"></dxo-state-storing>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxi-column
      alignment="center"
      dataField="id"
      caption="Application ID" dataType="number">
    </dxi-column>
    <dxi-column alignment="center" dataField="grant.name" caption="Grant Name">
    </dxi-column>
    <dxi-column alignment="center" dataField="formData.S1_Facility_Name"
      caption="Facility Name">
    </dxi-column>
    <dxi-column alignment="center" dataField="formData.S1_Application_Type"
      caption="School Type">
    </dxi-column>
    <dxi-column alignment="center" dataField="formData.S1_Facility_City"
      caption="City">
    </dxi-column>
    <dxi-column
      [visible]="false"
      dataType="datetime"
      alignment="center" dataField="isCompletedDate"
      caption="Date Completed">
    </dxi-column>
    <dxi-column
      alignment="center"
      dataField="applicationStaus"
      caption="Status">
    </dxi-column>
    <!-- <dxi-column
      caption="Eval Points"
      dataType="number"
      alignment="center"
      [allowFiltering]="true"
      [allowSorting]="true"
      [calculateCellValue]="calculateTotalEvaluationScore"
      [calculateDisplayValue]="calculateTotalEvaluationScore"></dxi-column> -->
    <dxi-column
      caption="Award Amount"
      dataField="award_amount"
      dataType="number"
      alignment="center"
      [format]="{
      type: 'currency',
      precision: 2} "
      [allowFiltering]="true"
      [allowSorting]="true"></dxi-column>
    <!-- <dxi-column
      caption="Bonus Points"
      dataType="number"
      alignment="center"
      [allowFiltering]="true"
      [allowSorting]="true"
      [calculateCellValue]="calculateTotalBonusScore"
      [calculateDisplayValue]="calculateTotalBonusScore"></dxi-column>
    <dxi-column
      caption="Total Points"
      dataType="number"
      alignment="center"
      [allowFiltering]="true"
      [allowSorting]="true"
      [calculateCellValue]="calculateTotalScore"
      [calculateDisplayValue]="calculateTotalScore"></dxi-column> -->
    <dxi-column
      caption="Budget"
      dataType="number"
      alignment="center"
      [allowFiltering]="true"
      [allowSorting]="true"
      [format]="{
      type: 'currency',
      precision: 2
    } "
      [calculateCellValue]="calculateTotalBudget"
      [calculateDisplayValue]="calculateTotalBudget"></dxi-column>
    <dxi-column
      type="buttons">
      <dxi-button
        type="normal"
        cssClass="reviewBtn"
        icon="eyeopen"
        [onClick]="onViewApplicationResults"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
