import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  LoginFormComponent,
  CreateAccountFormComponent,
  ChangePasswordFormComponent,
} from './shared/components';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import {
  AuthGuard,
  LoggedInGuard,
  LogOutGuard,
} from './shared/guards/auth.guard';
import { GrantsModule } from './pages/grants/grants.module';
import { GrantsComponent } from './pages/grants/grants.component';
import {
  PlaygroundComponent,
  PlaygroundModule,
} from './pages/playground/playground.component';
import {
  ApplicationFormComponent,
  ApplicationsFormModule,
} from './pages/application-form/application-form.component';
import { LcssLogoModule } from './shared/components/lcss-logo/lcss-logo.module';
import { AnnouncementImageModule } from './shared/components/announcement-image/announcement-image.module';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminModule } from './pages/admin/admin.module';
import { AdminDataGridComponent } from './pages/admin/components/grant-datagrid/admin-data-grid.component';
import { AdminApplicationDataGridComponent } from './pages/admin/components/admin-application-data-grid/admin-application-data-grid.component';
import { AdminStaffDataGridComponent } from './pages/admin/components/admin-staff-data-grid/admin-staff-data-grid.component';
import { AcceptInviteModule } from './pages/accept-invite/accept-invite.module';
import { AcceptInviteComponent } from './pages/accept-invite/accept-invite.component';
import { ForgotUsernameComponent } from './shared/components/forgot-username-form/forgot-username-form.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password-form/forgot-password-form.component';
import { CommonModule } from '@angular/common';
import { ReviewComponent } from './pages/admin/review/review.component';
import { ApplicationEvalDataGridComponent } from './pages/admin/components/eval-data-grid/application-eval-datagrid.component';
import { EvaluateComponent } from './pages/admin/evaluate/evaluate.component';
import { GrantPageComponent } from './pages/admin/grant/grant.component';
import { ReimbursmentsComponent } from './pages/reimbursments/reimbursments.component';
import { ReimbursmentComponent } from './pages/reimbursement/reimbursment.component';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginFormComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'create-account',
        component: CreateAccountFormComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: ':id/accept',
        component: AcceptInviteComponent,
        canActivate: [LogOutGuard],
      },
      {
        path: 'forgot-username',
        component: ForgotUsernameComponent,
        canActivate: [LogOutGuard],
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [LogOutGuard],
      },
      {
        path: 'reset-password/:token',
        component: ChangePasswordFormComponent,
        canActivate: [LogOutGuard],
      },
    ],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'grants',
    component: GrantsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'application/review/:applicationId',
    component: ReviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'application/evaluate/:applicationId',
    component: EvaluateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'application/:applicationId',
    component: ApplicationFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'playground',
    component: PlaygroundComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'grants',
        component: AdminDataGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'grants/:grantId',
        component: GrantPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'applications',
        component: AdminApplicationDataGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'evaluating',
        component: ApplicationEvalDataGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'staff',
        component: AdminStaffDataGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reimbursements',
        component: ReimbursmentsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reimbursements/:reimbursementId',
        component: ReimbursmentComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    DxDataGridModule,
    DxFormModule,
    DxButtonModule,
    DxScrollViewModule,
    GrantsModule,
    AdminModule,
    PlaygroundModule,
    ApplicationsFormModule,
    LcssLogoModule,
    AnnouncementImageModule,
    AcceptInviteModule,
    DxLoadIndicatorModule,
    CommonModule,
  ],
  providers: [],
  exports: [RouterModule],
  declarations: [HomeComponent, ProfileComponent],
})
export class AppRoutingModule {}
