import { ReimbursementInterface } from '../services/application/app-reimbursement.service';

export function getREType(rowData: ReimbursementInterface) {
  if (rowData.isReconciliation) {
    return 'Reconciliation';
  } else if (rowData.advancedPayment) {
    return 'Advance';
  } else {
    return 'Reimbursement';
  }
}
