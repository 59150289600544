 <dx-tabs
  class="hidden-print"
  [showNavButtons]="false"
  [items]="tabs"
  [selectedIndex]="selectedIndex"
  [showNavButtons]="true"
  keyExpr="id"
  width="100%"
  style="background-color: rgb(229, 237, 255);"
  (onOptionChanged)="onTabClick($event)">
</dx-tabs>
