import { Component, Input, OnInit } from '@angular/core';
import { ApplicationsService } from '../../services';
import { IApplicationReadHistory } from '../../types/IApplicationReadHistory.interface';
import { lastValueFrom } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'application-status-history',
  styleUrl: './application-status-history.component.scss',
  templateUrl: './application-status-history.component.html',
})
export class ApplicationStatusHistoryComponent {
  @Input({ required: true })
  applicationHistory: any[] = [];

  ngOnInit(): void {
    console.log(this.applicationHistory);
  }
}
