<div id="accordion" class="grant-accordion-container">
  <h2>Grants</h2>
  <dx-accordion
    #accordion
    [selectedItems]="[]"
    [dataSource]="grantsDataSource"
    [collapsible]="true"
    [multiple]="false"
    id="accordion-container">
    <div
      *dxTemplate="let grantResponse of 'title'"
      class="accordion-item-container">
      <div class="accordion-item">
        <h4>{{ grantResponse.grant.name }} - {{grantResponse.grant.fy}}</h4>
      </div>
    </div>
    <div *dxTemplate="let grantResponse of 'item'">
      <div>
        <div
          *ngIf="!grantResponse.isEligible && grantResponse.grant.fy == 'FY24'"
          style="display: flex; justify-content: center; align-items: center; text-align: center;">
          <div>
            <h4 style="color: red">Your account is not eligible for this grant.
              You need to fill
              out the LCSS Data Call survey.</h4>
            <a target="_blank"
              href="https://lsu.qualtrics.com/jfe/form/SV_39jRlspTaGB85D0">Survey</a>
            <p>Please allow up to 24 hours after completing the survey for your
              eligiblity to update.</p>
          </div>
        </div>
        <div
          *ngIf="!grantResponse.isEligible && grantResponse.grant.fy == 'FY25'"
          style="display: flex; justify-content: center; align-items: center; text-align: center;">
          <div>
            <h4 style="color: red">Your account is not eligible for this
              grant.</h4>
          </div>
        </div>
        <app-grants-description />
      </div>
      <div class="accordion-details-container">
        <div class="accordion-details-left">
        </div>
        <div class="accordion-details-right" *ngIf="grantResponse.isEligible">
          <dx-button
            (onClick)="onApplyClick(grantResponse.grant.id)"
            *ngIf="!grantResponse.applicationId && grantResponse.isEligible"
            text="Apply"
            width="150px"
            height="50"></dx-button>
          <dx-button
            (onClick)="onResumeClick(grantResponse.applicationId)"
            *ngIf="grantResponse.applicationId"
            text="Resume"></dx-button>
        </div>
      </div>
    </div>
  </dx-accordion>
</div>
