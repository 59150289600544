import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ValidationCallbackData } from 'devextreme-angular/common';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services';
import { jwtDecode } from 'jwt-decode';
import { lastValueFrom } from 'rxjs';
import { notifyWrapper } from '../../globals/notify-wrapper';

@Component({
  selector: 'app-change-passsword-form',
  templateUrl: './change-password-form.component.html',
  styleUrl: './change-password-form.component.scss',
})
export class ChangePasswordFormComponent implements OnInit {
  loading = false;
  formData: any = {};
  token?: string;
  errorMessage?: string;

  decodedToken?: { username: string; subject: number };

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(async (params) => {
      await this.onTokenChange(params.get('token') ?? undefined);
    });
  }

  onTokenChange = async (token?: string) => {
    this.token = token;
    if (!this.token) {
      this.errorMessage = 'Url is invalid!';
      return;
    }

    try {
      const decodedToken: any = jwtDecode(this.token);
      if (!decodedToken.username) throw new Error('Token missing username');
      if (!decodedToken.subject) throw new Error('Token missing user id');
      if (!decodedToken.exp) throw new Error('Token missing exp');

      const tokenExpDate = new Date(0);
      tokenExpDate.setUTCSeconds(decodedToken.exp);

      if (tokenExpDate < new Date()) {
        this.errorMessage = 'Link is expired!';
        return;
      }
      this.decodedToken = decodedToken;
      this.formData.username = this.decodedToken?.username;
    } catch (error) {
      console.log(error);
      this.errorMessage = 'Invalid token!';
    }

    try {
      await lastValueFrom(this.authService.isTokenBlacklisted(this.token));
    } catch (e: any) {
      if (e.error && e.error.message) {
        this.errorMessage = 'Link already used!';
      } else {
        this.errorMessage = 'Unknown error!';
      }
    }
  };

  async onSubmit(e: Event) {
    e.preventDefault();

    if (!this.token) return;

    const { password } = this.formData;

    this.loading = true;

    try {
      const result = await lastValueFrom(
        this.authService.resetPassword({ token: this.token, password })
      );
      notifyWrapper(
        `Updated password for ${this.decodedToken?.username}.`,
        'success'
      );
      this.router.navigate(['auth', 'login']);
    } catch (e: any) {
      notifyWrapper(`${e.error.message}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  confirmPassword = (e: ValidationCallbackData) => {
    return e.value === this.formData.password;
  };
}
@NgModule({
  imports: [CommonModule, RouterModule, DxFormModule, DxLoadIndicatorModule],
  declarations: [ChangePasswordFormComponent],
  exports: [ChangePasswordFormComponent],
})
export class ChangePasswordFormModule {}
