/**
 *  Omit properties from an array of objects
 * @param array
 * @param properties
 * @returns
 */
export async function omitProperties<T, K extends keyof T>(
  array: T[],
  properties: K[]
): Promise<Omit<T, K>[]> {
  return Promise.all(
    array.map(async (object) => {
      const { ...rest } = object;
      properties.forEach((propertyToOmit) => {
        delete rest[propertyToOmit];
      });
      return rest as Omit<T, K>;
    })
  );
}
