<div id="accordion" class="admin-data-grid-container">
  <dx-data-grid
    id="data-grid"
    [dataSource]="grantsDataSource"
    [showBorders]="true"
    [focusedRowEnabled]="false"
    [rowAlternationEnabled]="true"
    [columnAutoWidth]="true"
    [editing]="{
      mode: 'form',
      allowUpdating: true,
      allowAdding: true,
      allowDeleting: false
    }"
    id="admin-data-grid-container">
    <dxo-toolbar>
      <dxi-item location="before">
        <div class="data-grid-header">Grants</div>
      </dxi-item>
      <dxi-item name="revertButton" locateInMenu="auto"></dxi-item>
      <dxi-item name="saveButton" locateInMenu="auto"></dxi-item>
      <dxi-item name="addRowButton" locateInMenu="auto"></dxi-item>
    </dxo-toolbar>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxi-column
      dataType="string"
      dataField="name">
    </dxi-column>
    <dxi-column dataField="availableFunding">
      <dxo-format [type]="'currency'" precision="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="awardedFunding" [type]="'currency'"
      precision="2"></dxi-column>
    <dxi-column
      dataType="datetime"
      dataField="openDate"></dxi-column>
    <dxi-column
      dataType="datetime"
      dataField="closeDate">
    </dxi-column>
    <dxi-column
      dataType="date"
      [allowEditing]="false"
      dataField="created"></dxi-column>
    <dxi-column
      type="buttons">
      <dxi-button name="edit"></dxi-button>
      <dxi-button
        text="View"
        type="normal"
        [onClick]="onViewGrant"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
