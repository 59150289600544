<ng-container *ngIf="isAuthenticated; else unauthenticated">

  <app-side-nav-inner-toolbar>
    <router-outlet></router-outlet>
    <app-footer>
      Copyright © {{appInfo.currentYear}} {{appInfo.title}}
      <br />
      All trademarks or registered trademarks are property of their respective
      owners.
    </app-footer>
  </app-side-nav-inner-toolbar>
</ng-container>
<ng-template #unauthenticated>
  <app-unauthenticated-content></app-unauthenticated-content>
</ng-template>
<app-confirmation-popup></app-confirmation-popup>
