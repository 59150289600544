import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { IApplication } from '../../types/application.interface';
import { IGrant, ISection } from '../../../pages/grants/types/IGrants';
import { PlaygroundService } from '../../../pages/playground/playground.service';
import { CommonModule } from '@angular/common';
import { PlaygroundModule } from '../../../pages/playground/playground.component';
import {
  DxButtonModule,
  DxSwitchModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import { IFile } from '../../types/IFile';
import { QuestionFormComponent } from '../../../pages/playground/question-form/question-form.component';

@Component({
  selector: 'app-view-application',
  templateUrl: './view-application.component.html',
  styleUrls: ['./view-application.component.scss'],
})
export class ViewApplicationComponent implements OnInit {
  @ViewChild(QuestionFormComponent)
  form?: QuestionFormComponent;

  @Input({ required: true })
  application!: IApplication;
  @Input({ required: true })
  grant!: IGrant;
  sections: ISection[] = [];

  newTabPanel: boolean = false;

  @Input({ required: true })
  pageKey!: string;

  get tabIndexKey() {
    return `${this.pageKey}_${this.application.id}_selectedTabIndex`;
  }
  selectedTabIndex: number = 0;

  constructor(private readonly _pgService: PlaygroundService) {}

  async ngOnInit() {
    this.sections = this.grant.jsonData as ISection[];
    this._pgService.application_id = this.application.id;
    this._pgService.setFormData(this.application.formData);

    try {
      this.selectedTabIndex = parseInt(
        window.localStorage.getItem(this.tabIndexKey) ?? '0'
      );
    } catch (e) {
      window.localStorage.setItem(this.tabIndexKey, '0');
    }

    const value = window.localStorage.getItem('newTabPanel');
    if (value === 'true') {
      this.newTabPanel = true;
    }
  }

  setLocalStorage = () => {
    window.localStorage.setItem('newTabPanel', `${this.newTabPanel}`);
    window.localStorage.setItem(this.tabIndexKey, `${this.selectedTabIndex}`);
  };

  tabSelectionChanged = () => {
    this.setLocalStorage();
    this.form?.handleFormLifecycle();
  };

  async onFileClick(file: IFile) {
    await this._pgService.getFile(file.hashedName, file.fileName);
  }
}

@NgModule({
  imports: [
    CommonModule,
    PlaygroundModule,
    DxTabPanelModule,
    DxButtonModule,
    DxSwitchModule,
  ],
  declarations: [ViewApplicationComponent],
  exports: [ViewApplicationComponent],
})
export class ViewApplicationModule {}
