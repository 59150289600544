import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxPopupModule } from 'devextreme-angular';
import { ConfirmationPopupService } from './confirmation-popup.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: 'confirmation-popup.component.html',
})
export class ConfirmationPopupComponent implements OnInit, OnDestroy {
  title: string = 'Confirm';
  message: string = '';
  cancelBtnType: string = 'default';
  confirmBtnType: string = 'default';

  show: boolean = false;

  constructor(private popupService: ConfirmationPopupService) {}

  subsciptions = new Subscription();
  ngOnInit(): void {
    this.subsciptions.add(
      this.popupService.popupState.subscribe((state) => {
        if (state.message) this.message = state.message;
        if (state.title) this.title = state.title;
        if (state.cancelType) this.cancelBtnType = state.cancelType;
        if (state.confirmType) this.confirmBtnType = state.confirmType;
        this.show = state.isVisible;
      })
    );
  }

  ngOnDestroy(): void {
    this.subsciptions.unsubscribe();
  }

  confirmAction = () => {
    this.popupService.closePopup(true);
  };
  denyAction = () => {
    this.popupService.closePopup(false);
  };

  onPopupClose() {
    this.popupService.closePopup(false);
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, DxPopupModule],
  declarations: [ConfirmationPopupComponent],
  exports: [ConfirmationPopupComponent],
})
export class ConfirmationPopupModule {}
