import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LcssLogoComponent } from './lcss-logo.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LcssLogoComponent],
  exports: [LcssLogoComponent],
})
export class LcssLogoModule {}
