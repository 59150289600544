import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
} from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import {
  ReimbursementsService,
  ReimbursementStatusHistoryInterface,
} from '../../../shared/services/reimbursements.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-status-history',
  standalone: true,
  imports: [CommonModule, DxDataGridModule, DxButtonModule],
  templateUrl: './status-history.component.html',
  styleUrl: './status-history.component.scss',
})
export class StatusHistoryComponent implements OnInit {
  @Input({ required: true })
  reimbursementId!: number;

  @ViewChild(DxDataGridComponent)
  dataGrid?: DxDataGridComponent;

  constructor(private reimbursementService: ReimbursementsService) {}

  store?: CustomStore<ReimbursementStatusHistoryInterface, number>;
  ngOnInit(): void {
    this.store = new CustomStore({
      load: async () =>
        await lastValueFrom(
          this.reimbursementService.getStatusHistory(this.reimbursementId)
        ),
    });
  }
}
