import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { handleHttpError } from '../http.error-handler';
import { UserService } from './user.service';
import { IUser } from '../types/IUser';

@Injectable({
  providedIn: 'root',
})
export class AdminsService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/admin';

  constructor(
    private _httpClient: HttpClient,
    private readonly _usersService: UserService
  ) {}

  getAllStaff() {
    return this._httpClient
      .get<IUser[]>(`${this.getBaseURL()}/staff`)
      .pipe(catchError(handleHttpError<IUser[]>));
  }

  updateStaff(staffId: number, staff: Partial<IUser>) {
    return this._usersService.patchUser(staffId, staff);
  }

  deleteStaff(staffId: number) {
    return this._httpClient
      .delete<IUser>(`${this.getBaseURL()}/staff/${staffId}`)
      .pipe(catchError(handleHttpError<IUser>));
  }

  createStaff(staff: Partial<IUser>) {
    return this._httpClient
      .post<IUser>(`${this.getBaseURL()}/staff`, staff)
      .pipe(catchError(handleHttpError<IUser>));
  }

  getBaseURL() {
    return this.baseUrl + this.path;
  }
}
