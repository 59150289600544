<dx-data-grid
  style="height: 100%;"
  [dataSource]="store ?? []"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true">
  <dxo-toolbar>
    <dxi-item location="before">
      <div class="data-grid-header">Status History</div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item name="revertButton"></dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" (onClick)="dataGrid?.instance?.refresh()">
        </dx-button>
      </div>
    </dxi-item>
  </dxo-toolbar>
  <dxi-column
    alignment="center"
    caption="Status"
    dataField="statusString"></dxi-column>
  <dxi-column
    alignment="center"
    dataField="reason"></dxi-column>
  <dxi-column
    alignment="center"
    caption="User"
    dataField="username"></dxi-column>
  <dxi-column
    alignment="center"
    caption="Datetime"
    dataType="datetime"
    format="MMM dd, yyyy h:mm a"
    dataField="created"></dxi-column>
</dx-data-grid>
