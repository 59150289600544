import { Component, OnDestroy } from '@angular/core';
import { PlaygroundService } from '../playground.service';
import { Subscription } from 'rxjs';
import { ISection } from '../../grants/types/IGrants';
import { IApplication } from '../../../shared/types/application.interface';

@Component({
  selector: 'application-action-bar',
  templateUrl: 'action-bar.component.html',
})
export class ApplicationActionBarComponent implements OnDestroy {
  sections: ISection[] = [];
  selectedSection?: ISection;

  application?: IApplication;

  subscriptions: Subscription = new Subscription();
  constructor(private pgService: PlaygroundService) {
    this.subscriptions.add(
      pgService.sections.subscribe((sections) => {
        this.sections = sections;
      })
    );

    this.subscriptions.add(
      pgService.selectedSectionId.subscribe((id) => {
        this.selectedSection = this.sections.find((s) => s.id == id);
      })
    );

    this.subscriptions.add(
      pgService.application.subscribe((a) => {
        this.application = a;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  nextSection() {
    if (!this.onLastSection) {
      const index = this.sections.findIndex(
        (s) => s.id == this.selectedSection?.id
      );
      this.pgService.setSelectedSectionId(this.sections[index + 1].id);
    } else {
      this.pgService.setSelectedSectionId(9999);
    }
  }
  prevSection() {
    const index = this.sections.findIndex(
      (s) => s.id == this.selectedSection?.id
    );
    this.pgService.setSelectedSectionId(this.sections[index - 1].id);
  }

  toggleSectionCompletion() {
    if (this.selectedSection === undefined) return;
    this.pgService.markSectionAsComplete(this.selectedSection.id);
  }

  get isSectionCompleted() {
    return this.pgService.isSectionCompleted(this.selectedSection?.id);
  }

  get onLastSection() {
    return (
      this.sections.findIndex((a) => a.id == this.selectedSection?.id) ==
      this.sections.length - 1
    );
  }

  get isNextDisabled() {
    if (this.onLastSection) {
      if (this.pgService.areAllSectionsComplete()) return false;
      return true;
    }
    return false;
  }
  get isPrevDisabled() {
    return (
      this.sections.findIndex((a) => a.id == this.selectedSection?.id) == 0
    );
  }
}
