import { Component, OnDestroy } from '@angular/core';
import { PlaygroundService } from '../playground.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'application-saved-status',
  templateUrl: 'saved-status.component.html',
  styleUrls: ['saved-status.component.scss'],
})
export class ApplicationSavedStatus implements OnDestroy {
  loading: boolean = false;
  lastSaved?: Date;
  subscriptions: Subscription = new Subscription();

  pgService: PlaygroundService;
  constructor(pgService: PlaygroundService) {
    this.pgService = pgService;
    this.subscriptions.add(
      pgService.lastSaved.subscribe((s) => {
        this.lastSaved = s;
      })
    );
    this.subscriptions.add(
      pgService.saving.subscribe((s) => {
        this.loading = s;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
