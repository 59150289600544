import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services';
import { notifyWrapper } from '../../globals/notify-wrapper';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  loading = false;
  formData: any = {};

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async onSubmit(e: Event) {
    e.preventDefault();
    const { username, password, rememberMe } = this.formData;
    // save the current return URL param before calling authService login; if null, set to home
    const returnURL = this.route.snapshot.queryParams['returnURL'] || '/home';
    this.loading = true;
    this.authService
      .login(username, password, rememberMe)
      .subscribe({
        next: () => this.router.navigate([returnURL]),
        error: (e) =>
          notifyWrapper(
            e.error.message ?? 'Failed to connect to server!',
            'error'
          ),
      })
      .add(() => {
        this.loading = false;
      });
  }

  onCreateAccountClick = () => {
    this.router.navigate(['/auth/create-account']);
  };
}
@NgModule({
  imports: [CommonModule, RouterModule, DxFormModule, DxLoadIndicatorModule],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent],
})
export class LoginFormModule {}
