import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxFormModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxTabPanelModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { ReimbursementsService } from '../../shared/services/reimbursements.service';
import { ActivatedRoute } from '@angular/router';
import { ReimbursementInterface } from '../../shared/services/application/app-reimbursement.service';
import { CommonModule } from '@angular/common';
import { ReimbursementFormComponent } from '../../shared/components/application-awards/reimbursement-form/reimbursement-form.component';
import { NavHeaderModule } from '../../shared/components/nav-header/nav-header.module';
import { ReimbursementStatus } from '../../shared/globals/enums/ReimbursementStatus.enum';
import { ViewApplicationModule } from '../../shared/components/view-application/view-application.component';
import { IGrant } from '../grants/types/IGrants';
import { IApplication } from '../../shared/types/application.interface';
import { ChecksComponent } from './checks/checks.component';
import { ConfirmationPopupService } from '../../shared/components/confirmation-popup/confirmation-popup.service';
import { notifyWrapper } from '../../shared/globals/notify-wrapper';
import { RowUpdatedEvent, RowUpdatingEvent } from 'devextreme/ui/data_grid';
import { AbilityService } from '@casl/angular';
import { AppAbility, AuthService } from '../../shared/services';
import { PermissionAction, PermissionItem } from '../../shared/enums';
import { SendBackPopupComponent } from './send-back-popup/send-back-popup.component';
import { SendBackPopupService } from './send-back-popup/send-back-popup.service';
import { ReimbursementCommentsComponent } from './comments/comments.component';
import { ReimbursementFilesComponent } from './invoice-files/invoice-files.component';
import { StatusHistoryComponent } from './status-history/status-history.component';
import { REIMBURSEMENT_ROLE } from '../../shared/enums/ReimbursementRole';
import { IssueCheckPopupComponent } from './issue-check-popup/issue-check-popup.component';
import { IssueCheckPopupService } from './issue-check-popup/issue-check-popup.service';
import { text } from 'stream/consumers';
import { ValueChangedEvent } from 'devextreme/ui/text_box';

@Component({
  selector: 'app-reimbursment',
  standalone: true,
  imports: [
    DxFormModule,
    DxDataGridModule,
    DxButtonModule,
    CommonModule,
    ReimbursementFormComponent,
    NavHeaderModule,
    ViewApplicationModule,
    DxPopupModule,
    ChecksComponent,
    DxDropDownButtonModule,
    SendBackPopupComponent,
    ReimbursementCommentsComponent,
    ReimbursementFilesComponent,
    DxTabPanelModule,
    StatusHistoryComponent,
    DxTextBoxModule,
    IssueCheckPopupComponent,
    DxLoadPanelModule,
    DxTextBoxModule,
  ],
  templateUrl: './reimbursment.component.html',
  styleUrl: './reimbursment.component.scss',
})
export class ReimbursmentComponent implements OnInit, OnDestroy {
  PermissionAction = PermissionAction;
  PermissionItem = PermissionItem;

  async onRowUpdating(e: any) {
    e.cancel = new Promise(async (resolve, reject) => {
      try {
        const x = await lastValueFrom(
          this.reimbursementService.updateInvoice(e.oldData.id, {
            amountApproved: e.newData.amountApproved || 0,
          })
        );

        resolve(false);
      } catch (error: any) {
        reject(`Error updating invoice: ${error.message}`);
      }
    });
  }

  REIMBURSEMENT_STATUS = ReimbursementStatus;
  reimbursement?: ReimbursementInterface;
  loading = false;
  error: string | null = null;
  ability?: AppAbility;

  hasFinanceRole = false;

  refreshTimer?: NodeJS.Timeout;

  constructor(
    private route: ActivatedRoute,
    private reimbursementService: ReimbursementsService,
    private confirmationService: ConfirmationPopupService,
    private sendBackConfirmService: SendBackPopupService,
    private issueCheckPopupService: IssueCheckPopupService,
    private abilityService: AbilityService<AppAbility>,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    clearInterval(this.refreshTimer);
  }

  async updatePO(e: ValueChangedEvent) {
    console.log(e.value);

    if (!this.reimbursement) {
      return;
    }
    try {
      const res = await lastValueFrom(
        this.reimbursementService.patchPO(this.reimbursement.id, e.value)
      );
      this.reimbursement = res;
    } catch (error) {}
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(
      async (params: { reimbursementId?: number }) => {
        if (params.reimbursementId) {
          await this.loadReimbursement(params.reimbursementId);
          await this.loadApplication();
        }
      }
    );

    const jwt = this.authService.getDecodedToken();
    this.hasFinanceRole = jwt?.context?.roleName == 'Finance';
    this.ability = await firstValueFrom(this.abilityService.ability$);

    this.refreshTimer = setInterval(async () => {
      await this.loadReimbursement(this.reimbursement!.id, true);
    }, 2000);
  }

  application?: IApplication;
  grant?: IGrant;

  loadReimbursement = async (
    reimbursementId: number,
    checkForDifferences = false
  ) => {
    try {
      this.error = null;
      if (!checkForDifferences) this.loading = true;
      const riRes = await lastValueFrom(
        this.reimbursementService.getReimbursement(reimbursementId)
      );

      if (checkForDifferences) {
        if (riRes.status != this.reimbursement?.status) {
          this.reimbursement = riRes;
          await this.loadDropdownActions();
        }
      } else {
        this.reimbursement = riRes;
        await this.loadDropdownActions();
      }
    } catch (error: any) {
      this.error = error.message;
    } finally {
      if (!checkForDifferences) this.loading = false;
    }
  };

  loadingApplication = false;
  loadApplication = async () => {
    if (!this.reimbursement) return;
    try {
      this.loadingApplication = true;
      const res = await lastValueFrom(
        this.reimbursementService.getAppInfo(this.reimbursement.id)
      );
      this.application = res.application;
      this.grant = res.grant;
    } catch (error) {
      notifyWrapper('Error loading application!', 'error');
    } finally {
      this.loadingApplication = false;
    }
  };

  sendBack = async (
    reimbursement: ReimbursementInterface,
    status: ReimbursementStatus
  ) => {
    const response = await lastValueFrom(
      this.sendBackConfirmService.confirm(
        'Send reimbursement back?',
        '',
        'danger'
      )
    );

    if (!response.confirm) return;
    try {
      this.loading = true;
      this.reimbursement = await lastValueFrom(
        this.reimbursementService.sendBack(
          reimbursement.id,
          status,
          response.reason
        )
      );

      await this.loadDropdownActions();
    } catch (error: any) {
      notifyWrapper('Error sending back! ' + error.message, 'error');
    } finally {
      this.loading = false;
    }
  };

  markAsApproved = async (reimbursement: ReimbursementInterface) => {
    const confirm = await lastValueFrom(
      this.confirmationService.confirm(
        'Approve?',
        'Are you sure you want to approve this reimbursement?',
        'danger'
      )
    );

    if (confirm) {
      try {
        this.loading = true;
        this.reimbursement = await lastValueFrom(
          this.reimbursementService.markAsApproved(reimbursement.id)
        );
        await this.loadDropdownActions();
      } catch (error: any) {
        console.log(error.message);
        notifyWrapper(`Error approving! ${error.message || ''}`, 'error');
      } finally {
        this.loading = false;
      }
    }
  };

  markAsFundingApproved = async (reimbursement: ReimbursementInterface) => {
    const confirm = await lastValueFrom(
      this.confirmationService.confirm(
        'Approve?',
        'Are you sure you want to approve the approved budget amounts?',
        'danger'
      )
    );

    if (confirm) {
      try {
        this.loading = true;
        this.reimbursement = await lastValueFrom(
          this.reimbursementService.markAsApprovedFunding(reimbursement.id)
        );

        await this.loadDropdownActions();
      } catch (error: any) {
        console.log(error.message);
        notifyWrapper(`Error approving! ${error.message || ''}`, 'error');
      } finally {
        this.loading = false;
      }
    }
  };

  generateInvoice = async (reimbursement: ReimbursementInterface) => {
    const confirm = await lastValueFrom(
      this.confirmationService.confirm(
        'Approve?',
        'Are you sure you want to generate and sign the invoice? No changes can be made to the reimbursement after this process.',
        'danger'
      )
    );

    if (confirm) {
      try {
        this.loading = true;
        const res = await lastValueFrom(
          this.reimbursementService.generateInvoice(reimbursement.id)
        );
        await this.loadReimbursement(reimbursement.id);
        await this.loadDropdownActions();
        window.location.href = res.signingURL;
      } catch (error: any) {
        console.log(error.message);
        notifyWrapper(`Error approving! ${error.message || ''}`, 'error');
      } finally {
        // this.loading = false;
      }
    }
  };

  markAsReviewed = async (reimbursement: ReimbursementInterface) => {
    const confirm = await lastValueFrom(
      this.confirmationService.confirm(
        'Mark as Reviewed?',
        'Are you sure you want to mark this reimbursement as reviewed?',
        'danger'
      )
    );

    if (confirm) {
      try {
        this.loading = true;
        this.reimbursement = await lastValueFrom(
          this.reimbursementService.markAsReviewed(reimbursement.id)
        );

        await this.loadDropdownActions();
      } catch (error: any) {
        console.log(error.message);
        notifyWrapper(
          `Error marking as reviewed! ${error.message || ''}`,
          'error'
        );
      } finally {
        this.loading = false;
      }
    }
  };

  markAsQAQCed = async (reimbursement: ReimbursementInterface) => {
    const confirm = await lastValueFrom(
      this.confirmationService.confirm(
        'Mark as Supervisor Reviewed?',
        'Are you sure you want to mark this reimbursement as Supervisor Reviewed?',
        'danger'
      )
    );

    if (confirm) {
      try {
        this.loading = true;
        this.reimbursement = await lastValueFrom(
          this.reimbursementService.markAsQAQC(reimbursement.id)
        );

        await this.loadDropdownActions();
      } catch (error: any) {
        console.log(error.message);
        notifyWrapper(
          `Error marking as Supervisor Reviewed! ${error.message || ''}`,
          'error'
        );
      } finally {
        this.loading = false;
      }
    }
  };

  dropDownButtonActions: any[] = [];
  async loadDropdownActions() {
    if (!this.reimbursement || !this.ability) {
      this.dropDownButtonActions = [];
      return;
    }
    const actions = [];
    const can_review = this.ability.can(
      PermissionAction.CAN,
      PermissionItem.CAN_REVIEW_REIMBURSEMENT
    );
    const can_approve = this.ability.can(
      PermissionAction.CAN,
      PermissionItem.CAN_APPROVE_REIMBURSEMENT
    );

    const can_assign = this.ability.can(
      PermissionAction.CAN,
      PermissionItem.CAN_ASSIGN_REIMBURSEMENT
    );

    const can_fund = this.ability.can(
      PermissionAction.CAN,
      PermissionItem.CAN_FUND_REIMBURSEMENT
    );

    const can_mark_as_paid = this.ability.can(
      PermissionAction.CAN,
      PermissionItem.CAN_MARK_REIMBURSEMENT_PAID
    );

    const can_qa = this.ability.can(
      PermissionAction.CAN,
      PermissionItem.CAN_QA_REIMBURSEMENT
    );

    if (
      ((this.reimbursement.status ==
        ReimbursementStatus['Submitted - Pending Review'] ||
        this.reimbursement.status ==
          ReimbursementStatus['Sent Back - Pending Review']) &&
        can_review) ||
      // ((this.reimbursement.status ==
      //   ReimbursementStatus['Reviewed - Pending Approval'] ||
      //   this.reimbursement.status ==
      //     ReimbursementStatus['Sent Back - Pending Approval']) &&
      //   can_approve)
      (this.reimbursement.status ==
        ReimbursementStatus['Reviewed - Pending Supervisor Review'] &&
        can_qa) ||
      (this.reimbursement.status ===
        ReimbursementStatus['Supervisor Reviewed - Pending Funding Approval'] &&
        can_fund)
    ) {
      actions.push({
        text: 'Send Back to Applicant',
        onClick: async () => {
          await this.sendBack(
            this.reimbursement!,
            ReimbursementStatus['Sent Back to Applicant']
          );
        },
      });
    }

    if (
      // ((this.reimbursement.status ==
      //   ReimbursementStatus['Reviewed - Pending Approval'] ||
      //   this.reimbursement.status ==
      //     ReimbursementStatus['Sent Back - Pending Approval']) &&
      //   can_approve) ||
      (this.reimbursement.status ==
        ReimbursementStatus['Reviewed - Pending Supervisor Review'] &&
        can_qa) ||
      (this.reimbursement.status ==
        ReimbursementStatus['Supervisor Reviewed - Pending Funding Approval'] &&
        can_fund)
    ) {
      actions.push({
        text: 'Send Back for Review',
        onClick: async () => {
          await this.sendBack(
            this.reimbursement!,
            ReimbursementStatus['Sent Back - Pending Review']
          );
        },
      });
    }

    // if (
    //   this.reimbursement.status >=
    //     ReimbursementStatus['Approved - Pending Supervisor Review'] &&
    //   this.reimbursement.status <= ReimbursementStatus['Funding Approved'] &&
    //   (can_fund || can_mark_as_paid)
    // ) {
    //   actions.push({
    //     text: 'Send Back for Approval',
    //     onClick: async () => {
    //       await this.sendBack(
    //         this.reimbursement!,
    //         ReimbursementStatus['Sent Back - Pending Approval']
    //       );
    //     },
    //   });
    // }

    if (
      can_review &&
      (this.reimbursement.status ===
        ReimbursementStatus['Submitted - Pending Review'] ||
        this.reimbursement.status ===
          ReimbursementStatus['Sent Back - Pending Review'])
    ) {
      actions.push({
        text: 'Mark as Reviewed',
        onClick: () => this.markAsReviewed(this.reimbursement!),
      });
    }

    // if (
    //   (this.reimbursement.status ===
    //     ReimbursementStatus['Reviewed - Pending Approval'] ||
    //     this.reimbursement.status ===
    //       ReimbursementStatus['Sent Back - Pending Approval']) &&
    //   can_approve
    // ) {
    //   actions.push({
    //     text: 'Approve',
    //     onClick: async () => this.markAsApproved(this.reimbursement!),
    //   });
    // }

    if (
      this.reimbursement.status ===
        ReimbursementStatus['Reviewed - Pending Supervisor Review'] &&
      can_qa
    ) {
      actions.push({
        text: 'Supervisor Reviewed',
        onClick: async () => this.markAsQAQCed(this.reimbursement!),
      });
    }

    if (
      (this.reimbursement.status ===
        ReimbursementStatus['Advance Payment - Pending Funding Approval'] ||
        this.reimbursement.status ===
          ReimbursementStatus[
            'Supervisor Reviewed - Pending Funding Approval'
          ]) &&
      can_fund
    ) {
      actions.push({
        text: 'Approve Funding',
        onClick: async () => {
          console.log('HEERE');

          if (
            this.reimbursement?.purchaseOrderNumber == null ||
            this.reimbursement?.purchaseOrderNumber == ''
          ) {
            const confirm = await lastValueFrom(
              this.confirmationService.confirm(
                'Are you sure you want to approve funding with no PO number?',
                'This will approve the funding without a PO number.',
                'danger',
                'default'
              )
            );

            if (!confirm) {
              return;
            }
          }

          this.markAsFundingApproved(this.reimbursement!);
        },
      });
    }

    if (
      this.reimbursement.status === ReimbursementStatus['Funding Approved'] &&
      can_fund
    ) {
      actions.push({
        text: 'Generate Invoice',
        onClick: async () => this.generateInvoice(this.reimbursement!),
      });
    }

    if (
      this.reimbursement.status === ReimbursementStatus['Invoiced'] &&
      can_fund
    ) {
      this.loading = true;
      const test = await lastValueFrom(
        this.reimbursementService.getInvoiceStatus(this.reimbursement.id)
      );
      this.loading = false;
      if (test.signedStatus == 'COMPLETED') {
        actions.push({
          text: 'Download Signed Invoice',
          onClick: async () => {
            const file = await lastValueFrom(
              this.reimbursementService.downloadInvoice(this.reimbursement!.id)
            );

            this.reimbursementService.downLoadFile(file, 'application/pdf');

            console.log(file);
          },
        });
      } else {
        actions.push({
          text: 'Sign Invoice',
          onClick: async () => {
            window.location.href = test.signingURL;
          },
        });
      }
    }

    // if (
    //   this.reimbursement.status === ReimbursementStatus['Funding Approved'] &&
    //   this.hasFinanceRole
    // ) {
    //   actions.push({
    //     text: 'Issue Check',
    //     onClick: async () => {
    //       const confirm = await firstValueFrom(
    //         this.issueCheckPopupService.confirm('Issue Check', 'Issue a check')
    //       );

    //       if (confirm.confirm && confirm.reason && this.reimbursement) {
    //         try {
    //           this.reimbursement = await lastValueFrom(
    //             this.reimbursementService.issueCheck(
    //               this.reimbursement.id,
    //               confirm.reason
    //             )
    //           );

    //           await this.loadDropdownActions();
    //         } catch (error) {
    //           notifyWrapper('Error issuing check', 'error');
    //         }
    //       }
    //     },
    //   });
    // }

    this.dropDownButtonActions = actions;
  }

  onCheckNumberChange = async (e: any) => {
    console.log(this.reimbursement);
    console.log(e.value);
  };
}
