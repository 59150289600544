export function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj; // Return the object if it's not an object or null
  }

  const clone: any = Array.isArray(obj) ? [] : {}; // Determine if it's an array or object

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clone[key] = deepClone(obj[key]); // Recursively clone each property
    }
  }

  return clone as T;
}
