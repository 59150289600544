import notify from 'devextreme/ui/notify';

/**
 * Used to wrap around the dev extreme notify component and provide a consistent experience across our application.
 * If you need more configuration options use the native "notify" function from dev-ex
 */
export function notifyWrapper(
  message: string,
  type: 'info' | 'warning' | 'error' | 'success',
  displayTime?: number
) {
  return notify(
    {
      message: message,
      type: type,
      displayTime: displayTime ?? 5000,
      closeOnClick: true,
    },
    { position: 'top center', direction: 'down-push' }
  );
}
