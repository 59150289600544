import { Component, OnInit } from '@angular/core';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { AdminsService } from 'src/app/shared/services';
import { IUser } from 'src/app/shared/types/IUser';
import { generateDataGridColumns } from 'src/app/shared/utils/data-grid/data-grid.utils';
import { omitProperties } from 'src/app/shared/utils/object.utils';
import {
  IUserOptions,
  UserService,
} from '../../../../shared/services/user.service';
import { notifyWrapper } from '../../../../shared/globals/notify-wrapper';
import { ConfirmationPopupService } from '../../../../shared/components/confirmation-popup/confirmation-popup.service';

@Component({
  selector: 'app-admin-staff-data-grid',
  templateUrl: './admin-staff-data-grid.component.html',
  styleUrl: './admin-staff-data-grid.component.scss',
})
export class AdminStaffDataGridComponent implements OnInit {
  staffStore?: CustomStore<IUser>;

  staffDataSource!: DataSource<IUser>;

  dataGridColumns: DxDataGridTypes.Column[] = [];

  showInvitePopup: boolean = false;

  constructor(
    private readonly _userService: UserService,
    private readonly confirmService: ConfirmationPopupService
  ) {}
  options?: IUserOptions;
  async loadOptions() {
    try {
      this.options = await lastValueFrom(this._userService.getOptions());
    } catch (error) {
      notifyWrapper('Error loading options!', 'error');
    }
  }
  async ngOnInit() {
    await this.loadOptions();
    this.staffStore = new CustomStore<IUser>({
      key: 'id',
      load: async () => await lastValueFrom(this._userService.getStaff()),
      update: async (key, updatedGrant: Partial<IUser>) => {
        await lastValueFrom(this._userService.patchUser(key, updatedGrant));
      },
      remove: async (key) => {
        await lastValueFrom(this._userService.deleteUser(key));
      },
    });

    this.staffDataSource = new DataSource<IUser, number>({
      store: this.staffStore,
    });
  }

  onCellPrepared(e: any) {
    if (e.rowType === 'data' && e.column.type === 'buttons') {
      var $links = e.cellElement.find('.resendInviteBtn');
      console.log(!!e.row.data.isAccepted);
      if (e.row.data.isAccepted) $links.filter('.resendInviteBtn').remove();
    }
  }

  onResendInviteClick = async (e: any) => {
    const confirm = await lastValueFrom(
      this.confirmService.confirm(
        'Resend Invite',
        'Are you sure you want to send the invite again?',
        'danger',
        'default'
      )
    );
    if (confirm && e.row.data.id) {
      try {
        const res = await lastValueFrom(
          this._userService.reinviteUser(e.row.data.id)
        );
      } catch (error) {
        notifyWrapper('Error inviting user!', 'error');
      }
    }
  };
}
