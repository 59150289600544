 <div>
  <h4 style="margin: 0px;">Read History:</h4>
  <dx-data-grid [dataSource]="readDataSource"
    style="max-height: 300px;">
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-summary [calculateCustomSummary]="calculateLatest">
      <dxi-group-item
        column="created"
        summaryType="max"
        name="Latest"
        displayFormat="Latest Access: {0}">
      </dxi-group-item>
    </dxo-summary>
    <dxi-sort-by-group-summary-info
      summaryItem="Latest"
      sortOrder="desc">
    </dxi-sort-by-group-summary-info>
    <dxi-column dataField="user.firstName"
      caption="First Name"></dxi-column>
    <dxi-column dataField="user.lastName"
      caption="Last Name"></dxi-column>
    <dxi-column
      [groupIndex]="0"
      dataField="user.username"
      caption="User"></dxi-column>
    <dxi-column
      dataField="created"
      dataType="datetime"></dxi-column>
  </dx-data-grid>
</div>
