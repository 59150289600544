import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DxButtonModule,
  DxFormComponent,
  DxFormModule,
} from 'devextreme-angular';
import {
  AppReimbursementsService,
  ReimbursementInterface,
} from '../../../services/application/app-reimbursement.service';
import { CommonModule } from '@angular/common';
import { AdvPaymentReimbursementsPopupComponent } from '../adv-payment-popup/adv-payment-popup.component';
import { AppAwardInterface } from '../../../services/application/awards.service';

@Component({
  selector: 'app-reimbursement-form',
  standalone: true,
  imports: [
    CommonModule,
    DxFormModule,
    DxButtonModule,
    AdvPaymentReimbursementsPopupComponent,
  ],
  templateUrl: './reimbursement-form.component.html',
  styleUrl: './reimbursement-form.component.scss',
})
export class ReimbursementFormComponent implements OnInit {
  @Input()
  award?: AppAwardInterface;

  @ViewChild(DxFormComponent)
  form?: DxFormComponent;

  constructor(private riService: AppReimbursementsService) {}
  ngOnInit(): void {
    if (this.formdata && !this.formdata.advancedPayment) {
      this.formdata.advancedPayment = false;
    }
  }

  @Input()
  readonly: boolean = false;

  @Output()
  readonlyChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  isEditing: boolean = false;

  @Output()
  isEditingChange: EventEmitter<boolean> = new EventEmitter();

  @Input({ required: true })
  formdata: Partial<ReimbursementInterface> = {};

  previousFormData: Partial<ReimbursementInterface> = {};

  loading = false;

  @Input()
  advancedPaymentEligible?: boolean;

  get isAdvancedPaymentEnabled() {
    if (
      this.formdata.advancedPayment == true ||
      this.advancedPaymentEligible === true
    )
      return true;
    return false;
  }

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();

  @Output()
  onAdvSave: EventEmitter<any> = new EventEmitter();

  editForm = () => {
    this.isEditing = true;
    this.isEditingChange.emit(true);
    this.previousFormData = { ...this.formdata };
  };

  cancelEdit = () => {
    this.isEditing = false;
    this.isEditingChange.emit(false);
    this.formdata = { ...this.previousFormData };
  };

  _onSave(e: Event) {
    e.preventDefault();
    const valid = this.form?.instance.validate();
    if (valid?.isValid) {
      this.onSave.emit(this.formdata);
    }
  }

  _advPayment() {
    const valid = this.form?.instance.validate();
    if (valid?.isValid) {
      this.showAdvPaymentPopup = true;
    }
  }

  _onAdvPaymentSave(e: any) {
    this.showAdvPaymentPopup = false;
    this.onAdvSave.emit({ ...this.formdata, ...e });
  }

  showAdvPaymentPopup = false;
}
