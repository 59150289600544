import { Component, NgModule, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { lastValueFrom, Subscription } from 'rxjs';
import { AuthService } from '../../services';
import { DxScrollViewModule } from 'devextreme-angular';
import { IUser } from '../../types/IUser';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent implements OnInit, OnDestroy {
  @Input()
  menuItems: any;

  @Input()
  menuMode!: string;

  username?: string;

  user?: IUser;

  _authServiceSubscription?: Subscription;

  constructor(
    private _authService: AuthService,
    private _userService: UserService
  ) {}

  async ngOnInit() {
    await this.loadUser();
    this._authServiceSubscription =
      this._authService.accessTokenChanged.subscribe({
        next: async () => {
          await this.loadUser();
        },
      });
  }

  async loadUser() {
    const userId = this._authService.getUserId();
    if (userId) {
      this.user = await lastValueFrom(this._userService.getUserById(userId));
    }
  }

  ngOnDestroy(): void {
    this._authServiceSubscription?.unsubscribe();
  }
}

@NgModule({
  imports: [
    DxListModule,
    DxContextMenuModule,
    CommonModule,
    DxScrollViewModule,
  ],
  declarations: [UserPanelComponent],
  exports: [UserPanelComponent],
})
export class UserPanelModule {}
