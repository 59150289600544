import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApplicationsService } from './application.service';
import { catchError } from 'rxjs';
import { handleHttpError } from '../../http.error-handler';
import { IApplication } from '../../types/application.interface';

@Injectable({
  providedIn: 'root',
})
export class AppAwardsService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/awards';

  constructor(
    private _httpClient: HttpClient,
    private applicationService: ApplicationsService
  ) {}

  getBaseURL(applicationId: number) {
    return `${this.applicationService.getBaseURL()}/${applicationId}${
      this.path
    }`;
  }

  getAwards(appId: number) {
    return this._httpClient
      .get<AppAwardInterface>(`${this.getBaseURL(appId)}`)
      .pipe(catchError(handleHttpError<AppAwardInterface>));
  }
}

export interface AppAwardInterface {
  id: number;
  number: number;
  applicationId: number;
  application: IApplication;
  amount: number;
  conditionalMessage: string;
  signatureRequiredBy: Date;
  advancedPaymentEligible: boolean;
}
