import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ValidationCallbackData } from 'devextreme-angular/common';
import { DxFormComponent, DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxListModule, DxValidatorModule } from 'devextreme-angular';
import { SchoolSearchListModule } from './school-search-list/school-search-list.component';
import { ACCOUNT_TYPE } from '../../globals/enums/AccountType.enum';
import { notifyWrapper } from '../../globals/notify-wrapper';
import { UserService } from '../../services/user.service';
import { lastValueFrom } from 'rxjs';
import { deepClone } from '../../globals/functions/deepclone.function';
import {
  IContactInformation,
  IOrganization,
  ISchool,
} from '../../globals/interfaces';

export interface SignupForm {
  accountType: ACCOUNT_TYPE;
  school: ISchool;
  organization: IOrganization;
  password: string;
  confirmedPassword: string;
  contactInfo: IContactInformation;
  username: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss'],
})
export class CreateAccountFormComponent {
  @ViewChild(DxFormComponent)
  form?: DxFormComponent;

  ACCOUNT_TYPE = ACCOUNT_TYPE;
  accountTypeOptions = [
    { name: 'School', value: ACCOUNT_TYPE.SCHOOL },
    { name: 'District', value: ACCOUNT_TYPE.DISTRICT },
    { name: 'Non-Profit', value: ACCOUNT_TYPE.NON_PROFIT },
  ];

  loading = false;
  formData: Partial<SignupForm> = {};
  searchValue?: string;

  constructor(private userService: UserService, private router: Router) {}

  customizeItem = (item: any) => {
    if (!item.editorOptions) item.editorOptions = {};
    item.editorOptions.stylingMode = 'filled';

    if (!item.label) item.label = {};
    return item;
  };

  schoolChange = (e: any) => {
    this.formData.school = deepClone<ISchool>(e);
    const schoolType = this.form?.instance.getEditor('schoolType');
  };

  accountTypeChange = (e: any) => {
    delete this.formData.school;
  };

  async onSubmit(e: Event) {
    e.preventDefault();

    if (!this.formData.school?.uniqueId) {
      if (this.formData.accountType == ACCOUNT_TYPE.SCHOOL) {
        notifyWrapper('No school selected!', 'error');
        return;
      }
      if (this.formData.accountType == ACCOUNT_TYPE.DISTRICT) {
        notifyWrapper('No district selected!', 'error');
        return;
      }
    }

    this.loading = true;

    try {
      await lastValueFrom(this.userService.createAccount(this.formData as any));
      notifyWrapper('Success!', 'success');

      this.router.navigate(['auth/login']);
    } catch (e: any) {
      if (e.error.message) {
        if (Array.isArray(e.error.message)) {
          notifyWrapper(
            e.error.message ? `${e.error.message}` : 'Error!',
            'error'
          );
        }
      }
    } finally {
      this.loading = false;
    }
  }

  confirmPassword = (e: ValidationCallbackData) => {
    return e.value === this.formData.password;
  };

  isSpace(e: any) {
    return e.value ? !e.value.includes(' ') : false;
  }

  usernameAlreadyExists = async (e: any) => {
    try {
      const res = await lastValueFrom(this.userService.doesUserExist(e.value));
      return !res.exists;
    } catch (error) {
      console.log(error);
      return true;
    }
  };
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxListModule,
    SchoolSearchListModule,
    DxValidatorModule,
  ],
  declarations: [CreateAccountFormComponent],
  exports: [CreateAccountFormComponent],
})
export class CreateAccountFormModule {}
