import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

type BtnType = 'default' | 'primary' | 'success' | 'danger';

export interface PopupState {
  title?: string;
  message?: string;
  confirmType?: BtnType;
  cancelType?: BtnType;
  isVisible: boolean;
}

@Injectable({ providedIn: 'root' })
export class ConfirmationPopupService {
  private $popupState: BehaviorSubject<PopupState> =
    new BehaviorSubject<PopupState>({
      isVisible: false,
    });
  private $confirm?: Subject<boolean>;

  confirm(
    title: string,
    message: string,
    confirmType?: BtnType,
    cancelType?: BtnType
  ) {
    this.$popupState.next({
      title: title,
      message,
      confirmType,
      cancelType,
      isVisible: true,
    });

    this.$confirm = new Subject();
    return this.$confirm.asObservable();
  }

  get popupState() {
    return this.$popupState.asObservable();
  }

  /**
   * Whether the user elected to yes or no (to continue the action)
   * @param continueAction
   */
  closePopup(continueAction: boolean) {
    this.$popupState.next({ isVisible: false });
    this.$confirm?.next(continueAction);
    this.$confirm?.complete();
  }
}
