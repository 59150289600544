import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { GrantsService } from '../grants.service';
import { handleHttpError } from '../../http.error-handler';
import { environment } from '../../../../environments/environment';
import { IGrantScoringCriteria } from '../../types/IGrantScoringCriteria.interface';
import { IApplicationScored } from '../../types/IApplicationScored.interface';
import { IApplicationCriteria } from '../../types/IApplicationWithCriteria';
import { IGrantGroup } from '../../types/IGrantGroup.interface';
import { IUser } from '../../types/IUser';

@Injectable({
  providedIn: 'root',
})
export class GrantGroupService {
  baseUrl: string = environment.baseApiUrl;
  path: string = '/eval/groups';

  getBaseURL(grantId: number = 0) {
    return this._grantService.getBaseURL() + `/${grantId}${this.path}`;
  }

  constructor(
    private _httpClient: HttpClient,
    private readonly _grantService: GrantsService
  ) {}

  getGroups(grantId: number) {
    return this._httpClient
      .get<IGrantGroup[]>(`${this.getBaseURL(grantId)}/`)
      .pipe(catchError(handleHttpError<IGrantGroup[]>));
  }

  createGroup(grantId: number, obj: IGrantGroup) {
    return this._httpClient
      .post<IGrantGroup>(`${this.getBaseURL(grantId)}`, obj)
      .pipe(catchError(handleHttpError<IGrantGroup>));
  }

  deleteGroup(grantId: number, groupId: number) {
    return this._httpClient
      .delete<IGrantGroup>(`${this.getBaseURL(grantId)}/${groupId}`)
      .pipe(catchError(handleHttpError<IGrantGroup>));
  }

  updateGroup(grantId: number, groupId: number, obj: Partial<IGrantGroup>) {
    return this._httpClient
      .patch<IGrantGroup>(`${this.getBaseURL(grantId)}/${groupId}`, obj)
      .pipe(catchError(handleHttpError<IGrantGroup>));
  }

  getAssignableEvaluators(grantId: number) {
    return this._httpClient
      .get<IUser[]>(`${this.getBaseURL(grantId)}/assignableEvaluators`)
      .pipe(catchError(handleHttpError<IUser[]>));
  }

  getAssignedEvaluators(groupId: number) {
    return this._httpClient
      .get<IUser[]>(`${this.getBaseURL()}/${groupId}/assignedEvaluators`)
      .pipe(catchError(handleHttpError<IUser[]>));
  }

  assignUserToGroup(groupId: number, evaluatorId: number) {
    return this._httpClient
      .post<IGrantGroup>(
        `${this.getBaseURL()}/${groupId}/assignUser/${evaluatorId}`,
        {}
      )
      .pipe(catchError(handleHttpError<IGrantGroup>));
  }
  removeUserFromGroup(groupId: number, evaluatorId: number) {
    return this._httpClient
      .delete<IGrantGroup>(
        `${this.getBaseURL()}/${groupId}/removeUser/${evaluatorId}`
      )
      .pipe(catchError(handleHttpError<IGrantGroup>));
  }
}
