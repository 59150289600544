<dx-data-grid [dataSource]="awardStore">
  <dxi-column
    alignment="center"
    dataField="awardNumber">
  </dxi-column>
  <dxi-column
    alignment="center"
    dataField="amount"
    [format]="{
    type: 'currency',
    precision: 2
    }">
  </dxi-column>
  <dxi-column caption="Signed" [calculateCellValue]="calculateSignedStatus">
  </dxi-column>
  <dxo-master-detail [enabled]="true" template="detail"
    [autoExpandAll]="true"></dxo-master-detail>
  <div *dxTemplate="let row of 'detail'">
    <app-reimbursements
      [award]="row.data"
      [awardId]="row.data.id"
      [application]="this.application"></app-reimbursements>
  </div>
</dx-data-grid>
