 <div class="admin-data-grid-container">
  <dx-data-grid
    id="data-grid"
    [dataSource]="applicationsDataSource"
    [showBorders]="true"
    [focusedRowEnabled]="false"
    [columnAutoWidth]="true"
    (onCellPrepared)="onCellPrepared($event)">
    <dxo-toolbar>
      <dxi-item location="before">
        <div class="data-grid-header">Assigned Evaluations</div>
      </dxi-item>
    </dxo-toolbar>
    <dxo-paging [pageSize]="50"> </dxo-paging>
    <dxo-pager
      [visible]="true"
      [allowedPageSizes]="[50, 100, 250, 500]"
      displayMode="full"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [showNavigationButtons]="true">
    </dxo-pager>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxi-column alignment="center" dataField="grant.name" caption="Grant Name">
    </dxi-column>
    <dxi-column alignment="center" dataField="formData.S1_Facility_Name"
      caption="Facility Name">
    </dxi-column>
    <dxi-column
      type="buttons">
      <dxi-button
        text="Evaluate"
        type="normal"
        cssClass="reviewBtn"
        [onClick]="onViewApplicationResults"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
