<dx-data-grid
  [columnAutoWidth]="true"
  [dataSource]="riStore">
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <span style="font-size: 20px;">Reimbursements</span>
      </div>
    </dxi-item>
    <dxi-item name="saveButton"></dxi-item>
    <dxi-item><div *dxTemplate>
        <dx-button icon="plus" text="Create Reimbursement"
          (onClick)="this.createReimbursement()"></dx-button>
      </div></dxi-item>
  </dxo-toolbar>

  <dxi-column
    alignment="center"
    dataField="agencyName"></dxi-column>
  <dxi-column
    alignment="center"
    dataField="vendorNumber"></dxi-column>
  <dxi-column
    [visible]="false"
    alignment="center"
    dataField="applicantFirstName"
    caption="First Name"></dxi-column>
  <dxi-column
    [visible]="false"
    alignment="center"
    dataField="applicantLastName"
    caption="Last Name"></dxi-column>
  <!-- <dxi-column
    alignment="center"
    dataField="advancedPayment"
    caption="Adv Pay?"></dxi-column> -->
  <dxi-column
    alignment="center"
    format="currency"
    [editorOptions]="{ format: '$ #,##0.##' }"
    dataField="totalRequested"
    caption="Requested"></dxi-column>
  <dxi-column
    alignment="center"
    dataField="totalApproved"
    format="currency"
    [editorOptions]="{ format: '$ #,##0.##' }"
    caption="Approved"></dxi-column>
  <dxi-column
    width="auto"
    [allowEditing]="false"
    alignment="center"
    dataField="statusString"
    caption="Status"></dxi-column>

  <dxi-column type="buttons">
    <dxi-button
      icon="eyeopen"
      text="View"
      [onClick]="onReimbursementViewClick">
    </dxi-button>
  </dxi-column>
</dx-data-grid>

<app-reimbursements-popup
  *ngIf="popupVisible"
  [reimbursementId]="selectedReimbursementId"
  [award]="award"
  [application]="this.application"
  [visible]="popupVisible"
  (visibleChange)="hidePopup()"></app-reimbursements-popup>
