import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DxButtonModule, DxFormModule } from 'devextreme-angular';
import {
  AppReimbursementsService,
  ReimbursementInterface,
} from '../../../services/application/app-reimbursement.service';
import { CommonModule } from '@angular/common';
import { ReimbursementStatus } from '../../../globals/enums/ReimbursementStatus.enum';

@Component({
  selector: 'app-reimbursement-form',
  standalone: true,
  imports: [CommonModule, DxFormModule, DxButtonModule],
  templateUrl: './reimbursement-form.component.html',
  styleUrl: './reimbursement-form.component.scss',
})
export class ReimbursementFormComponent implements OnInit {
  constructor(private riService: AppReimbursementsService) {}
  ngOnInit(): void {
    if (this.formdata && !this.formdata.advancedPayment) {
      this.formdata.advancedPayment = false;
    }
  }

  @Input()
  readonly: boolean = false;

  @Output()
  readonlyChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  isEditing: boolean = false;

  @Output()
  isEditingChange: EventEmitter<boolean> = new EventEmitter();

  @Input({ required: true })
  formdata: Partial<ReimbursementInterface> = {};

  previousFormData: Partial<ReimbursementInterface> = {};

  loading = false;

  @Input()
  advancedPaymentEligible?: boolean;

  get isAdvancedPaymentEnabled() {
    if (
      this.formdata.advancedPayment == true ||
      this.advancedPaymentEligible === true
    )
      return true;
    return false;
  }

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();

  editForm = () => {
    this.isEditing = true;
    this.isEditingChange.emit(true);
    this.previousFormData = { ...this.formdata };
  };

  cancelEdit = () => {
    this.isEditing = false;
    this.isEditingChange.emit(false);
    this.formdata = { ...this.previousFormData };
  };

  _onSave(e: Event) {
    e.preventDefault();
    this.onSave.emit(this.formdata);
  }
}
