import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { notifyWrapper } from '../../../../shared/globals/notify-wrapper';
import { ApplicationsService } from '../../../../shared/services';
import { ConfirmationPopupService } from '../../../../shared/components/confirmation-popup/confirmation-popup.service';
import { IApplicationCriteria } from '../../../../shared/types/IApplicationWithCriteria';
import { GrantApplicationScoringService } from '../../../../shared/services/eval/scoring.service';

@Component({
  selector: 'app-app-eval',
  templateUrl: './app-eval.component.html',
  styleUrls: ['./app-eval.component.scss'],
})
export class ApplicationEvaluationComponent {
  @Input({ required: true })
  applicationCriteria!: IApplicationCriteria[];

  @Output()
  onSoreValueChange: EventEmitter<{
    scoreCriteriaId: number;
    property: string;
    value: string;
  }> = new EventEmitter();

  @Output()
  onEvalSubmit: EventEmitter<void> = new EventEmitter();

  constructor(
    private readonly _confirmationService: ConfirmationPopupService,
    private readonly _grantScoringService: GrantApplicationScoringService
  ) {}

  async setScoredValue(
    scoreCriteriaId: number,
    property: string,
    value: string
  ) {
    this.onSoreValueChange.emit({ scoreCriteriaId, property, value });
  }

  async onSubmit(e: any) {
    const confirm = await lastValueFrom(
      this._confirmationService.confirm(
        'Submit Evaluation',
        'Are you sure you want to submit your evaluation?',
        'success',
        'default'
      )
    );
    if (!confirm) return;

    this.onEvalSubmit.emit();
  }

  get totalScore() {
    let total = 0;
    for (const x of this.applicationCriteria) {
      if (x.score) total += x.score;
    }

    return total;
  }

  get maxScore() {
    let total = 0;
    for (const x of this.applicationCriteria) {
      if (x.maxScore) total += x.maxScore;
    }
    return total;
  }
}
