<span class="app-eval">
  <div *ngFor="let criteria of this.applicationCriteria">
    <div style="font-weight: 500; font-size: 15px;">{{criteria.name}}</div>
    <div style="display: flex; gap: 5px">
      <dx-number-box
        (onValueChanged)="setScoredValue(criteria.id, 'score', $event.value)"
        [(value)]="criteria.score"
        width="50px"
        label="Score"
        [min]="0"
        [max]="criteria.maxScore"></dx-number-box>
      <dx-number-box
        [stylingMode]="'underlined'"
        width="50px"
        label="Max"
        [value]="criteria.maxScore"
        [disabled]="true"></dx-number-box>
    </div>
    <div><dx-text-area
        [(value)]="criteria.comment"
        (onValueChanged)="setScoredValue(criteria.id, 'comment', $event.value)"
        label="Comment"
        [autoResizeEnabled]="true"></dx-text-area></div>
    <dx-accordion [collapsible]="true" [selectedIndex]="-1">
      <dxi-item title="Rubric (Click to expand)">
        <div [innerHTML]="criteria.description"
          class="critieraDescription">
        </div>
      </dxi-item>
    </dx-accordion>
    <br />
  </div>
  <div><b>Total Score:</b> {{totalScore}}/{{maxScore}}</div>
  <dx-button
    (onClick)="onSubmit($event)"
    [disabled]="false" text="Submit Evaluation"
    type="default"></dx-button>
</span>
